import { COUNSELOR_STUDENTS_GET } from '../../constants/action-types';

const initialState = {
  loading: false,
  count: undefined,
  limit: 20,
  sortBy: 'firstName',
  sort: 'desc',
  students: [],
};

function counselorStudents(state = initialState, action) {
  switch (action.type) {
    case COUNSELOR_STUDENTS_GET:
      return { ...state, ...action.payload };
    default:
      break;
  }
  return state;
}

export default counselorStudents;
