import styled from "styled-components";

export const SPACING = '25px';

export const INNER_MARGIN = `margin: 0px ${SPACING}`;

export const INNER_PADDING = `padding: 0px ${SPACING}`;

export const Row = styled.div`
    display: flex;
    align-items: center;
`;

export const Column = styled.div`
    display: flex;
    flex-direction: column;
`