import { API } from '../settings';

class AuthService {
  login(email, password) {
    return API.post(`/auth/login`, {
      email,
      password,
    }).then((response) => {
      console.log('response');
      if (response.data.accessToken) {
        localStorage.setItem('_session', JSON.stringify(response.data));
      }
      return response.data;
    });
  }

  logout() {
    localStorage.removeItem('user');
  }

  register(username, email, password) {
    return API.post(`/auth/signup`, {
      username,
      email,
      password,
    });
  }

  activateUser(activationToken, password, confirmPassword) {
    API.post(`/users/activate`, {
      activationToken,
      password,
      confirmPassword,
    }).then((response) => {
      console.log('activateUser Response :>> ', response);

      if (response.data.id) {
        localStorage.setItem('userId', JSON.stringify(response.data.id));
      }

      return response.data;
    });
  }

  activateParent(activationToken, password) {
    return API.post(`/auth/activate-parent`, {
      activationToken,
      password,
    }).then((response) => {
      console.log('response', response);
      if (response.data.id) {
        localStorage.setItem('parentId', JSON.stringify(response.data.id));
      }

      return response.data;
    });
  }

  activateStudent(activationToken, password) {
    return API.post(`/auth/activate-student`, {
      activationToken,
      password,
    }).then((response) => {
      if (response.data.id) {
        localStorage.setItem('studentId', JSON.stringify(response.data.id));
      }

      return response.data;
    });
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));
  }

  isUserActive(activationToken) {
    return API.get(`/users/${activationToken}/activation-status`)
      .then((response) => {
        if (response && response.data) {
          const { message } = response.data;

          if (message && message === 'Already activated.') {
            console.log('returning true');
            return true;
          }

          return false;
        }
      })
      .catch((error) => {
        console.log(error);
        return false;
      });
  }
}

export default new AuthService();
