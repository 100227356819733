import React from 'react';
import styled from 'styled-components';
import { ReactComponent as HandShake } from '../images/cluster-icons/001-hand-shake.svg';
import { ReactComponent as CityHall } from '../images/cluster-icons/002-city-hall.svg';
import { ReactComponent as Stethoscope } from '../images/cluster-icons/003-stethoscope.svg';
import { ReactComponent as Money } from '../images/cluster-icons/004-money.svg';
import { ReactComponent as Farm } from '../images/cluster-icons/005-farm.svg';
import { ReactComponent as Home } from '../images/cluster-icons/006-home.svg';
import { ReactComponent as SocialCare } from '../images/cluster-icons/007-social-care.svg';
import { ReactComponent as Transportation } from '../images/cluster-icons/008-transportation.svg';
import { ReactComponent as Creativity } from '../images/cluster-icons/009-creativity.svg';
import { ReactComponent as SocialMedia } from '../images/cluster-icons/010-social-media.svg';
import { ReactComponent as Computer } from '../images/cluster-icons/011-computer.svg';
import { ReactComponent as PowerPlant } from '../images/cluster-icons/012-power-plant.svg';
import { ReactComponent as PoliceBadge } from '../images/cluster-icons/013-police-badge.svg';
import { ReactComponent as Travel } from '../images/cluster-icons/014-travel.svg';
import { ReactComponent as Math } from '../images/cluster-icons/015-math.svg';
import { ReactComponent as GlobalEducation } from '../images/cluster-icons/016-global-education.svg';

const IconWrapper = styled.div`
  text-align: left;
  height: 100%;
  svg {
    height: 100%;
    width: 100%;
  }
`;

const displayIcon = (clusterKey) => {
  let icon;
  switch (clusterKey) {
    case 'AGRICULTURE_FOOD_NATURAL_RESOURCES':
      icon = <Farm fill="white" />;
      break;
    case 'ARCHITECTURE_CONSTRUCTION':
      icon = <Home fill="white" />;
      break;
    case 'ARTS_AV_TECHNOLOGY_COMMUNICATIONS':
      icon = <Creativity fill="white" />;
      break;
    case 'BUSINESS_MANAGEMENT_ADMINISTRATION':
      icon = <HandShake fill="white" />;
      break;
    case 'EDUCATION_TRAINING':
      icon = <GlobalEducation fill="white" />;
      break;
    case 'FINANCE':
      icon = <Money fill="white" />;
      break;
    case 'GOVERNMENT_PUBLIC_ADMINISTRATION':
      icon = <CityHall fill="white" />;
      break;
    case 'HEALTH_SCIENCE':
      icon = <Stethoscope fill="white" />;
      break;
    case 'HOSPITALITY_TOURISM':
      icon = <Travel fill="white" />;
      break;
    case 'HUMAN_SERVICES':
      icon = <SocialCare fill="white" />;
      break;
    case 'INFORMATION_TECHNOLOGY':
      icon = <Computer fill="white" />;
      break;
    case 'LAW_PUBLIC_SAFETY_CORRECTIONS_SECURITY':
      icon = <PoliceBadge fill="white" />;
      break;
    case 'MANUFACTURING':
      icon = <PowerPlant fill="white" />;
      break;
    case 'MARKETING':
      icon = <SocialMedia fill="white" />;
      break;
    case 'SCIENCE_TECHNOLOGY_ENGINEERING_MATH':
      icon = <Math fill="white" />;
      break;
    case 'TRANSPORTATION_DISTRIBUTION_LOGISTICS':
      icon = <Transportation fill="white" />;
      break;
    default:
      icon = <HandShake fill="white" />;
  }

  return icon;
};

const ClusterIcon = (props) => {
  const { clusterKey } = props;
  return <IconWrapper>{displayIcon(clusterKey)}</IconWrapper>;
};

export default ClusterIcon;
