import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const SkillsWrapper = styled.div``;

const SkillsCloudWrapper = styled.div`
  max-height: 100%;
  overflow-y: scroll;
  height: 290px;
  padding: 0.5rem 0;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Title = styled.h6`
  font-size: 1rem;
`;

const CategoryWrapper = styled.div`
  padding-bottom: 1.25rem;
  &:first-child {
    margin-top: 0.75rem;
  }
`;

const CategoryName = styled.div`
  font-size: 0.875rem;
  font-size: 1rem;
  ${'' /* line-height: 0.875rem; */}
`;

const StudentTopClusters = (props) => {
  const { rankedClusterNames } = props;

  
  const displayCategoryNames = (rankedClusterNames) => {
    if (rankedClusterNames.length > 0) {
      return rankedClusterNames.map((clusterName, index) => {
        console.log(clusterName);
        return (
          <CategoryWrapper key={index}>
            <CategoryName>
              {index + 1}. {clusterName?.name ? clusterName.name : clusterName}
            </CategoryName>
          </CategoryWrapper>
        );
      });
    }

    return (
      <p>
        <em>no results yet</em>
      </p>
    );
  };

  return (
    <SkillsWrapper>
      <Title>Top Categories</Title>
      <SkillsCloudWrapper>
        {displayCategoryNames(rankedClusterNames)}
      </SkillsCloudWrapper>
    </SkillsWrapper>
  );
};

export default StudentTopClusters;

StudentTopClusters.propTypes = {
  rankedClusterNames: PropTypes.array.isRequired,
};
