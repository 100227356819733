import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';

export default function DropdownButtonLn({
  children,
  renderBtn,
  position,
  options,
  onSelect,
}) {
  const wrapperRef = useRef(null);
  const [show, setState] = useState(false);
  const btn = React.cloneElement(renderBtn, {
    onClick: () => setState(!show),
  });
  useOutsideAlerter(wrapperRef, () => setState(false));
  return (
    <div
      ref={wrapperRef}
      className={`ln-dropdown-wrapper ${show ? 'show' : 'hidden'}`}
    >
      {btn}
      <div
        className={`options-wrapper ${show ? 'show' : 'hidden'} ${position}`}
      >
        <div className="options">
          {options.map((option, i) => (
            <div
              onClick={() => {
                onSelect(option.action_type);
              }}
            >
              {option.value}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideAlerter(ref, callback) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
}
