import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Panel = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  width: 100%;
  border-radius: 0.625rem;
  background: #fbfbfb;
  align-items: stretch;
  cursor: pointer;
  margin-bottom: 1.25rem;
`;

const Title = styled.div`
  font-size: 0.875rem;
  font-weight: bold;
`;

const Location = styled.div`
  font-size: 0.875rem;
  font-weight: bold;
  color: #858585;
`;

const Employer = styled.div`
  font-size: 0.875rem;
  font-weight: bold;
  color: #858585;
`;

const StudentOpportunity = (props) => {
  const { id, title, performedAt, location, stateName, toggleModal } = props;

  // TODO: move to a helper util
  const displayLocation = () => {
    if (performedAt === 'REMOTE') {
      return `Remote`;
    } else {
      return `${location}, ${stateName}`;
    }
  };

  return (
    <Panel
      onClick={() => {
        toggleModal(id);
      }}
    >
      <Title>{title}</Title>
      <Employer>Employer Name</Employer>
      <Location>{displayLocation()}</Location>
    </Panel>
  );
};

export default StudentOpportunity;

StudentOpportunity.propTypes = {
  title: PropTypes.string.isRequired,
  performedAt: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
};
