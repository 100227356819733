import React from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';

import SettingsNavBar from '../Settings/SettingsNavBar';
import SettingsProfileHeader from '../Settings/SettingsProfileHeader';

const PageHeader = styled.h3`
  margin-bottom: 0;
`;

const FluidContent = styled.div`
  flex: 1 1 auto;
  overflow: auto;
  padding: 0 20px;
`;

const SettingsWrapper = styled(Container)`
  background: #ffffff;
  padding: 1rem;
  border-radius: 1.25rem;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.02);
  table,
  th,
  td {
    border: none;
  }
  thead {
    th {
      border: none;
    }
  }
  tbody:before {
    content: '@';
    display: block;
    line-height: 0.5rem;
    text-indent: -99999px;
  }
  td {
    font-size: 0.875rem;
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
  }
  td.firstName,
  td.lastName {
    font-size: 1rem;
    font-weight: bold;
    background: none;
  }
  td.grade,
  td.percentDone {
    span {
      background: #fbfbfb;
      display: inline-block;
      padding: 0.2rem 1rem;
      border-radius: 8px;
    }
  }
`;

const EmployerSettings = (props) => {
  const { children } = props;

  return (
    <>
      <div
        className="horizontal-flex align-center space-between children-mg-r mg-b"
        style={{ height: '40px' }}
      >
        <PageHeader>Settings</PageHeader>
      </div>
      <SettingsWrapper fluid>
        <Row>
          <Col xs={12}>
            <SettingsProfileHeader {...props} />
          </Col>
        </Row>
        <Row className="h-100">
          <Col xs={2}>
            <SettingsNavBar />
          </Col>
          <Col xs={5}>
            <FluidContent>{children}</FluidContent>
          </Col>
        </Row>
      </SettingsWrapper>
    </>
  );
};

const mapStateToProps = (state) => ({ user: state.user });
export default connect(mapStateToProps)(EmployerSettings);
