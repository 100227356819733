import React, { useEffect, useState } from 'react';
import { Row, Col, Spinner } from 'react-bootstrap';
import styled from 'styled-components';

import { API } from '../../settings';
import { useParams } from 'react-router-dom';
import UploadDocumentsModal from './UploadDocumentsModal';
import deleteIcon from '../../images/cluster-icons/018-delete.svg';

const Panel = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100%;
  border-radius: 20px;
  background: #ffffff;
  align-items: stretch;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0,0,0,0.05);
  padding-bottom: 10px;
`

const LoadingSpinner = styled(Spinner)`
  height: 30px;
  width: 30px;
  margin-top: 50px;
`;

const SmallSpinner = styled(Spinner)`
  height: 15px;
  width: 15px;
`;


const DocListWrapper = styled.div`
 flex: 1;
 margin-top: 10px;
 padding-bottom: 10px;
`;


const FileItem = styled.div`
  background: #f7f7f7;
  display: flex;
  align-items: center;
  padding: 5px 10px;
  margin-bottom: 10px;
  border-radius: 7px;
  width: 100%;
  font-size: 12px;

  .file-icon {
    height: 20px;
  }

  & a {
    margin-right: 10px;
    margin-left: 10px
  }

`;

const SpinnerWrapper = styled.div`
  flex: 1;
  margin: 0 auto;
`;

const Delete = styled.img`
  height: 15px;
  width: 15px;
  cursor: pointer;
  opacity: 0.9
`;

const Document = ({document, setFiles}) => {
  
  const [loading, setLoading] = useState(false);
  const { uuid } = useParams()
  const studentId = uuid;

  const deleteFile = async () => {
    setLoading(true);
    await API.delete(`/students/${studentId}/uploads/${document.uuid}`);
    const result = await API.get(`/students/${studentId}/uploads/`);
    setFiles(result.data);
    setLoading(false);
  }
  
  return <Col
    xs={12}
    style={{ padding: 0 }}
    className="horizontal-flex"
    key={document.id}
  >
    <FileItem>
      <img
        src="/file_type_icons/pdf.svg"
        alt=""
        className="file-icon"
      />
      <a
        style={{
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          width: '300px',
          overflow: 'hidden',
        }}
        href={document.url}
        target="_blank"
        rel="noreferrer noopener"
        title={document.filename}
      >
        {document.filename}
      </a>
      {loading ? 
      <div>
        <SmallSpinner animation="border" variant="secondary"/>
      </div> :
      <Delete src={deleteIcon} onClick={deleteFile} />}
    </FileItem>
  </Col>

}

function StudentDocuments() {

  const { uuid } = useParams()
  const studentId = uuid;
  
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);

  useEffect(() => {
    getUploadedFiles()
  }, [])

  const getUploadedFiles = async () => {
    setLoading(true);
    const result = await API.get(`/students/${studentId}/uploads/`);
    setFiles(result.data);
    setLoading(false);
  };

  const DocumentList = () => <DocListWrapper>
    {files?.map((document, k) => <Document setFiles={setFiles} key={k} document={document} />)}
  </DocListWrapper>

  return <Panel>
    <Header>
      <h6 style={{ margin: 0 }}>Documents</h6>
      <UploadDocumentsModal onFinish={getUploadedFiles} />
    </Header>
    {loading ? 
    <SpinnerWrapper>  
      <LoadingSpinner animation="border" variant="secondary"/>
    </SpinnerWrapper>:
    <DocumentList />}

  </Panel>

}


export default StudentDocuments;
