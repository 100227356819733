// Assumes an array of objects with a 'lastName' key
export const compareLastNames = (a, b) => {
  if (a.lastName < b.lastName) {
    return -1;
  }
  if (a.lastName > b.lastName) {
    return 1;
  }
  return 0;
};

export const compareSchoolNames = (a, b) => {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
};

export const compareEmployerNames = (a, b) => {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
};

export const uniqueStudentsByEmail = (students) => {
  return Array.from(new Set(students.map((a) => a.email))).map((email) => {
    return students.find((a) => a.email === email);
  });
};
