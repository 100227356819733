import React from 'react';
import { Container, Row, Col, Spinner } from 'react-bootstrap';
import Dropzone from 'react-dropzone';
import XLSX from 'xlsx';
import styled from 'styled-components';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API } from '../../settings';

const PageHeader = styled.h3`
  margin-top: 6px;
`;

const PageBlock = styled.div`
  margin-top: 50px;
  padding: 50px 46px;
  border-radius: 30px;
  box-shadow: 0 8px 25px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
`;

const LoadingSpinner = styled(Spinner)`
  height: 50px;
  width: 50px;
`;

export default class ImportEmployers extends React.Component {
  constructor(props) {
    super(props);

    this.state = { data: null, cols: null, loading: false };

    this.importXlsx = this.importXlsx.bind(this);
    this.makeCols = this.makeCols.bind(this);
  }

  makeCols = (refstr) => {
    let o = [],
      C = XLSX.utils.decode_range(refstr).e.c + 1;
    for (var i = 0; i < C; ++i)
      o[i] = { name: XLSX.utils.encode_col(i), key: i };
    return o;
  };

  importXlsx(acceptedFiles) {
    const file = acceptedFiles[0];

    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;

    reader.onload = (e) => {
      this.setState({ loading: true });

      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array' });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws, { header: 1, raw: false });

      API.post(`/temp/import-employers`, {
        employers: data,
      })
        .then((response) => {
          /* Update state */
          this.setState({
            data: data,
            cols: this.makeCols(ws['!ref']),
            loading: false,
          });
          return response.data;
        })
        .catch((error) => {
          console.log(error);
          const errorMessage = 'There was an error';

          this.setState({
            loading: false,
          });

          toast.error(errorMessage, {
            position: 'top-center',
            autoClose: 30000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    };

    if (rABS) reader.readAsBinaryString(file);
    else reader.readAsArrayBuffer(file);
  }

  render() {
    const { loading } = this.state;
    const tempRenderUploaded = () => {
      if (loading) {
        return (
          <span>
            <LoadingSpinner animation="border" variant="secondary" />
          </span>
        );
      } else if (this.state.data) {
        return (
          <span>
            Data uploaded. All set.{' '}
            <span aria-label="thumbs up" role="img">
              👍
            </span>
          </span>
        );
      } else {
        return (
          <span>
            Drag 'n drop an Excel file here, or click to select from your
            computer.
          </span>
        );
      }
    };

    const sectionStyle = {
      width: '100%',
      minHeight: '257px',
      textAlign: 'center',
      padding: '25px 0 0 0',
      margin: 0,
      borderRadius: '15px',
      border: 'solid 1px #c6c6c6',
    };

    return (
      <Container className="p-0 h-100 ">
        <Row>
          <Col lg={12}>
            <PageHeader>Import Employers</PageHeader>
            <PageBlock>
              <Dropzone
                onDrop={(acceptedFiles) => this.importXlsx(acceptedFiles)}
              >
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <p style={sectionStyle}>{tempRenderUploaded()}</p>
                    </div>
                  </section>
                )}
              </Dropzone>
            </PageBlock>
          </Col>
        </Row>
      </Container>
    );
  }
}
