import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Row, SPACING } from './default-styles';
import back from '../../../images/back.svg';

const Header = styled(Row)`
    justify-content: space-between;
    padding: 20px ${SPACING};
`;

const Title = styled.p`
    margin: 0px;
`

const Back = styled.img`
    height: 15px;
    cursor: pointer;
`

export default function MainHeader( { title, detail = ''  }) {
    const history = useHistory();

    return <Header>
        <Back src={back} alt={'go back'} onClick={() => history.goBack()} />
        <Title>{title}</Title>
        <Title>{detail}</Title>
    </Header>
};

