import React from 'react';
import styled from 'styled-components';
import { Link, NavLink } from 'react-router-dom';

const Wrapper = styled.div`
  display: flex;
  height: 100vh;
`;

const LeftNav = styled.div`
  flex: 0 0 220px;
  height: 100%;
  background-color: #333333;
`;

const FluidContent = styled.div`
  flex: 1 1 auto;
  overflow: auto;
  background: #fbfbfb;
  padding: 20px;
`;

const NavHeading = styled.h2`
  color: #fff;
  font-size: 22px;
  line-height: 22px;
  margin: 31px 0 0 18px;
`;

const HighlightedText = styled.span`
  background: linear-gradient(to top, #ffc34e 50%, transparent 50%);
  display: inline-block;
`;

const NavSectionHeader = styled.h6`
  font-size: 12px;
  font-family: Nunito;
  margin: 42px 0 15px 20px;
  color: #858585;
`;

const NavList = styled.ul`
  list-style-type: none;
  display: block;
  margin: 0;
  padding: 0;
`;

const NavListItem = styled.li`
  display: block;
  width: 100%:
  margin: 0;
  padding: 0;
`;

const NavListItemLink = styled(NavLink)`
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  color: #858585;
  padding: 18px 0 17px 20px;
  width: 100%;
  &.selected {
    color: #ffffff;
    background-color: #292929;
  }
  &:hover {
    text-decoration: none;
    color: #ffffff;
    background-color: #292929;
  }
`;

const SignOutLink = styled.a`
  position: absolute;
  width: 220px;
  bottom: 0;
  left: 0;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  color: #858585;
  padding: 18px 0 17px 20px;
  &.selected {
    color: #ffffff;
    background-color: #292929;
  }
  &:hover {
    text-decoration: none;
    color: #ffffff;
    background-color: #292929;
  }
`;

const SuperAdminLoggedInLayout = ({ children }) => {
  return (
    <Wrapper>
      <LeftNav>
        <Link to="/admin/dashboard">
          <NavHeading>
            Launch <HighlightedText>Now</HighlightedText>
          </NavHeading>
        </Link>
        <NavSectionHeader>MAIN</NavSectionHeader>
        <NavList>
          <NavListItem>
            <NavListItemLink
              to="/super-admin/dashboard"
              activeClassName="selected"
            >
              Dashboard
            </NavListItemLink>
          </NavListItem>
          <NavListItem>
            <NavListItemLink
              to="/super-admin/schools"
              activeClassName="selected"
            >
              Schools
            </NavListItemLink>
          </NavListItem>
          <NavListItem>
            <NavListItemLink
              to="/super-admin/counselors"
              activeClassName="selected"
            >
              Counselors
            </NavListItemLink>
          </NavListItem>
          <NavListItem>
            <NavListItemLink
              to="/super-admin/employers"
              activeClassName="selected"
            >
              Employers
            </NavListItemLink>
          </NavListItem>
          {/* <NavListItem>
            <NavListItemLink
              to="/super-admin/import-schools"
              activeClassName="selected"
            >
              Import Schools
            </NavListItemLink>
          </NavListItem> */}
          <NavListItem>
            <NavListItemLink
              to="/super-admin/import-employers"
              activeClassName="selected"
            >
              Import Employers
            </NavListItemLink>
          </NavListItem>
          <NavListItem>
            <NavListItemLink
              to="/super-admin/assessment"
              activeClassName="selected"
            >
              Assessment
            </NavListItemLink>
          </NavListItem>
          <NavListItem>
            <NavListItemLink
              to="/super-admin/cluster-questions"
              activeClassName="selected"
            >
              Questions per Cluster
            </NavListItemLink>
          </NavListItem>
          <NavListItem>
            <NavListItemLink
              to="/super-admin/clusters"
              activeClassName="selected"
            >
              Clusters Data
            </NavListItemLink>
          </NavListItem>
          <NavListItem>
            <NavListItemLink
              to="/super-admin/import-clusters-data"
              activeClassName="selected"
            >
              Import Clusters Data
            </NavListItemLink>
          </NavListItem>
        </NavList>
        <SignOutLink href="/logout">Sign Out</SignOutLink>
      </LeftNav>
      <FluidContent>{children}</FluidContent>
    </Wrapper>
  );
};

export default SuperAdminLoggedInLayout;
