import React from 'react';
import { Container, Row, Col, Spinner } from 'react-bootstrap';
import styled from 'styled-components';
import StudentSidebar from './StudentSidebar';
import StudentAssessmentPanel from './StudentAssessmentPanel';
import StudentOpportunitiesList from './StudentOpportunitiesList';
import StudentOpportunityModal from './StudentOpportunityModal';
import BackButton from '../shared/buttons/BackButton';
import { API } from '../../settings';
import { withRouter } from 'react-router-dom';
import DeleteButton from '../DeleteButton';
import { track, VIEW_STUDENT } from '../../utils/mixpanel';
import { StudentActivity } from '../student-activity';
import TabBar from '../student-activity/shared/tab-bar';
import StudentDocuments from './StudentDocuments';

const LoadingContainer = styled.div`
  height: 800px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const HeaderRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const LoadingSpinner = styled(Spinner)`
  height: 50px;
  width: 50px;
`;

const ContentWrapper = styled.div`
  margin-top: 1.5rem;
  width: 100%;
  display: flex;
  flex-direction: row;
`;

const RightCol = styled.div`
  padding-left: 30px;
  flex: 1;
`;

const OpportunitiesColumn = styled(Col)`
  display: flex;
  margin-top: 30px;
  height: 425px;
`;

const Wrapper = styled.div`
  background: white;
  border-radius: 15px;
  padding: 50px 20px;
`

class Student extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tab: 'Summary',
      firstName: '',
      lastName: '',
      email: '',
      interestedOpportunities: [],
      grade: null,
      percentDone: 0,
      noAnswers: [],
      yesAnswers: [],
      parentFirstName: '',
      parentLastName: '',
      parentEmail: '',
      dob: null,
      loading: true,
      skills: [],
      rankedClusterNames: [],
      savedOpportunities: [],
      modalShow: false,
      modalData: null,
      modalDataLoading: false,
      originalId: '',
      isEmployer: props.isEmployer,
    };

    this.toggleModal = this.toggleModal.bind(this);
  }

  fetchData = () => {
    const { uuid } = this.props.match.params;
    API.get(`/students/${uuid}`).then((response) => {
      this.setState({
        id: uuid,
        originalId: response.data.id,
        firstName: response.data.firstName,
        lastName: response.data.lastName,
        email: response.data.email,
        noAnswers: response.data.noAnswers,
        yesAnswers: response.data.yesAnswers,
        percentDone: response.data.percentDone,
        rankedClusterNames: response.data.rankedClusterNames,
        skills: response.data.skills,
        grade: response.data.grade,
        parentFirstName: response.data.parentFirstName,
        parentLastName: response.data.parentLastName,
        parentEmail: response.data.parentEmail,
        dob: response.data.dob,
        loading: false,
        interestedOpportunities: response.data.interestedOpportunities,
        savedOpportunities: response.data.savedOpportunities,
      });
      return response.data;
    });
  };

  componentDidMount() {
    this.fetchData();
  }

  toggleModal = (opportunityId = null) => {
    const { interestedOpportunities, savedOpportunities } = this.state;
    let modalData;

    // TODO: move to a helper util
    if (opportunityId && Number.isInteger(opportunityId)) {
      for (let i = 0; i < interestedOpportunities.length; i++) {
        if (interestedOpportunities[i].id === opportunityId) {
          modalData = interestedOpportunities[i];
          break;
        }
      }

      if (!modalData) {
        for (let c = 0; c < savedOpportunities.length; c++) {
          if (savedOpportunities[c].id === opportunityId) {
            modalData = savedOpportunities[c];
            break;
          }
        }
      }

      this.setState((state) => ({
        modalShow: !state.modalShow,
        modalData,
      }));
    } else {
      this.setState((state) => ({
        modalShow: !state.modalShow,
        modalData: null,
      }));
    }
  };
  



  render() {
    const {
      firstName,
      lastName,
      email,
      grade,
      loading,
      percentDone,
      parentFirstName,
      parentLastName,
      skills,
      interestedOpportunities,
      savedOpportunities,
      modalData,
      modalShow,
      isEmployer
    } = this.state;

    if (email) {
      track(VIEW_STUDENT, { studentEmail: email, studentId: this.props.match.params.uuid });
    }

    
    const RightColumn = () => <>
      <StudentAssessmentPanel
        percentDone={percentDone}
        skills={skills}
        rankedClusterNames={this.state.rankedClusterNames}
        />
      <Row>
      <OpportunitiesColumn lg={4}>
        <StudentOpportunitiesList
          name="Saved Options"
          opportunities={savedOpportunities}
          toggleModal={this.toggleModal}
        />
        </OpportunitiesColumn>
      <OpportunitiesColumn lg={4}>
        <StudentOpportunitiesList
              name="Interested In"
              opportunities={interestedOpportunities}
              toggleModal={this.toggleModal}
        />
      </OpportunitiesColumn>
      <OpportunitiesColumn lg={4}>
        <StudentDocuments />
      </OpportunitiesColumn>
        
      </Row>
    </>

    const renderStudent = () => {
      if (loading) {
        return (
          <LoadingContainer>
            <LoadingSpinner animation="border" variant="secondary" />
          </LoadingContainer>
        );
      } else {
        return (
          <>
            <StudentSidebar
              firstName={firstName}
              lastName={lastName}
              email={email}
              grade={grade}
              parentFirstName={parentFirstName}
              parentLastName={parentLastName}
            />
            <RightCol>
              {!isEmployer && <TabBar 
                style={{ margin: 0, marginBottom: 20 }} 
                optionA='Summary' 
                optionB='Student Activity' 
                tab={this.state.tab} 
                setTab={(value) => this.setState({ tab: value })} />}
              {this.state.tab === 'Student Activity' ? <Wrapper>
                <StudentActivity name={firstName} id={this.state.originalId} />
              </Wrapper> : <RightColumn />}
            </RightCol>
          </>
        );
      }
    };

    return (
      <Container className="p-0 h-100 ">
        <Row className="h-100">
          <Col lg={12}>
            <HeaderRow>
              <BackButton onClick={() => this.props.history.goBack()} />
              {this.props.allowDelete && (
                <DeleteButton
                  url={`/students/${this.props.match.params.uuid}`}
                  schemaName={'student'}
                />
              )}
            </HeaderRow>
            <ContentWrapper>
              <StudentOpportunityModal
                modalShow={modalShow}
                modalData={modalData}
                toggleModal={this.toggleModal}
              />
              {renderStudent()}
            </ContentWrapper>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withRouter(Student);
