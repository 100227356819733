import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { clearToast } from '../features/users/userSlice';

const PageHeader = styled.h3`
  margin-top: 26px;
`;

const SuperAdminDashboard = (props) => {
  const { clearToast, user } = props;
  const { toastMessage, toastStatus } = user;

  // Todo: abstract this to a commponent
  useEffect(() => {
    if (toastMessage && toastStatus) {
      if (toastStatus === 'success') {
        toast.success(toastMessage);
      } else if (toastStatus === 'info') {
        toast.info(toastMessage);
      }

      clearToast();
    }
  });

  return (
    <Container className="p-0 h-100 ">
      <Row className="h-100">
        <Col lg={12}>
          <PageHeader>Dashboard</PageHeader>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state) => ({ user: state.user });
const mapDispatchToProps = { clearToast };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SuperAdminDashboard);
