import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

const Body = styled.div`
    max-width: 600px;
    min-height: 100vh;
    margin: auto;
    font-family: nunito;
    display: flex;
    flex-direction: column;
    background-color: #f9f9f9;
    
    @media (min-width: 600px) {
        box-shadow: 1px 1px 10px rgba(0,0,0,0.5);
    }
`

const Contents = styled.div`
    padding: 40px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const Title = styled.h6`
    align-self: center;
    margin-bottom: 30px;
`;

const Button = styled.a`
    background: orange;
    padding: 10px;
    border-radius: 5px;
    font-weight: 800;
    max-width: 400px;
    min-width: 200px;
    margin-top: 20px;
    color: white;
    text-align: center;
    align-self: center;
    cursor: pointer;
    
    &:hover {
        color: white;
        text-decoration: none;
    }
`;  

function AcceptTermsPrivacy ({ user }) {

    const acceptedStyle = {
        backgroundColor: 'green',
        opacity: 0.2,
        fontWeight: 400,
        cursor: 'initial'
    };

    const history = useHistory();

    useEffect(() => {
        const allAccepted = user.accepted_privacy && user.accepted_terms;
        if (!allAccepted) return;
        if (user.userType === 'COUNSELOR') return history.replace('/counselor/dashboard');
        if (user.userType === 'EMPLOYER') return history.replace('/employer/dashboard');
        if (user.userType === 'PARENT') return history.replace('/parent/instructions/assessment');
        if (user.userType === 'STUDENT') return history.replace('/dashboard');
    }, [user])

    return <Body>
        <Contents>
            <Title>Before proceeding, please read and accept the following terms:</Title>
            <Button 
            href='/terms' 
            style={user.accepted_terms ? acceptedStyle : {}}>
                Terms of Use
            </Button>
            <Button 
            href='/privacy' 
            style={user.accepted_privacy ? acceptedStyle : {}}>
                Privacy Policy
            </Button>
        </Contents>
    </Body>

};

const mapStateToProps = (state) => ({ user: state.user });

export default connect(mapStateToProps)(AcceptTermsPrivacy);
