import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import {
  Home24Regular as HomeRegular,
  Home24Filled as HomeFilled,
  Bookmark24Regular as BookmarkRegular,
  Bookmark24Filled as BookmarkFilled,
  CheckmarkStarburst16Regular,
  CheckmarkStarburst16Filled,
  Person24Regular as PersonRegular,
  Person24Filled as PersonFilled,
} from '@fluentui/react-icons';
import styled from 'styled-components';

const CheckMarkStarburstRegular = styled(CheckmarkStarburst16Regular)`
  transform: scale(1.5);
`;

const CheckMarkStarburstFilled = styled(CheckmarkStarburst16Filled)`
  transform: scale(1.5);
`;

const NavLinkStyled = styled(NavLink)`
  color: #fff;
  width: 100%;
  height: 100%;
  display: block;
  text-align: center;
  padding: 1rem;

  &:active {
    color: rgba(255, 255, 255);
  }

  &:hover {
    color: rgba(255, 255, 255);
  }

  &.selected {
    color: rgba(255, 255, 255);
  }
`;

class StudentNavItem extends React.Component {
  render() {
    const {
      to,
      match: { url },
    } = this.props;
    const isActive = url === to;
    let icon;
    switch (this.props.to) {
      case '/dashboard':
        icon = isActive ? <HomeFilled /> : <HomeRegular />;
        break;
      case '/saved':
        icon = isActive ? <BookmarkFilled /> : <BookmarkRegular />;
        break;
      case '/interestedIn':
        icon = isActive ? (
          <CheckMarkStarburstFilled />
        ) : (
          <CheckMarkStarburstRegular />
        );
        break;
      case '/profile':
        icon = isActive ? <PersonFilled /> : <PersonRegular />;
        break;
      default:
        break;
    }
    return (
      <NavLinkStyled activeClassName="selected" {...this.props}>
        {icon}
      </NavLinkStyled>
    );
  }
}

export default withRouter(StudentNavItem);
