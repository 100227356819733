import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';
import dotenv from 'dotenv';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Link } from 'react-router-dom';
import { API } from '../settings';
import ConfirmLn from './common/ConfirmLn';

dotenv.config();

const StudentsWrapper = styled.div`
  background: #ffffff;
  border-radius: 1.25rem;

  table,
  th,
  td {
    border: none;
  }

  thead {
    th {
      border: none;
    }
  }

  tbody:before {
    content: '@';
    display: block;
    line-height: 0.5rem;
    text-indent: -99999px;
  }

  td {
    font-size: 0.875rem;
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
  }

  td.firstName,
  td.lastName {
    font-size: 1rem;
    font-weight: bold;
    background: none;
  }

  td.grade,
  td.percentDone {
    span {
      background: #fbfbfb;
      display: inline-block;
      padding: 0.2rem 1rem;
      border-radius: 8px;
    }
  }
`;

const StatusButtonWrapper = styled.button`
  font-weight: 800;
  padding: 5px 10px;
  font-size: 12px;
  color: white;
  background: gray;
  border-radius: 5px;
  border: none;
  outline: none;
  width: 100%;

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`;

const StatusButton = (props) => {
  return <StatusButtonWrapper {...props} />
}

const CancelApproved = styled(StatusButton)`
  width: 40px;
  background: lightgray;
  margin-right: 10px;
`;

const FinishButton = styled(StatusButton)`
  background-color: lightgreen;
  color: green;
`;

const Finished = styled(StatusButton)`
  background-color: lightgreen;
  color: green;
  opacity: 0.8;
  pointer-events: none;
`;

const Closed = styled(StatusButton)`
  background-color: gray;
  opacity: 0.5;
  pointer-events: none;
`;

const FlexRow = styled.div`
  display: flex;
`;

const BootstrapTableWrapper = styled.div`
  .table td {
    overflow-wrap: break-word;
  }

  .table th {
    background: #fbfbfb;
    color: #858585;
    font-size: 0.6875rem;

    &:first-child {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    &:last-child {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }

  .react-bootstrap-table-pagination {
    margin-top: 2rem;
    justify-content: center;

    div {
      display: none;
    }

    div.react-bootstrap-table-pagination-list {
      display: flex;
      justify-content: center;

      ul {
        float: none;
      }
    }

    .page-link {
      border-color: #797878;
      color: #797878;
    }

    .page-item.active .page-link {
      color: #ffffff;
      background-color: #000000;
      border-color: #000000;
    }
  }
`;

function EmployerStudentList(props) {
  
  const [loading, setLoading] = useState([]);


  const addToLoading = (id) => setLoading((l) => [...l, id]);
  const removeFromLoading = (id) => setLoading((l) => l.filter((i) => i != id));

  const approveToOpportunity = async (studentId, opportunityId) => {
    if (loading.includes(studentId)) return;
    addToLoading(studentId)
    await API.patch(`/employers/approve/${opportunityId}`, { studentId });
    await props.onUpdate()
    removeFromLoading(studentId)
  }

  const finishOpportunity = async (studentId, opportunityId) => {
    if (loading.includes(studentId)) return;
    addToLoading(studentId)
    await API.patch(`/employers/finish/${opportunityId}`, { studentId });
    await props.onUpdate()
    removeFromLoading(studentId)
  }

  const onClickButton = (row) => () => {
    if (props.positionsAvailable === 0) return;
    if (row.finished) return;
    if (row.approvedByEmployer) return finishOpportunity(row.studentId, props.opportunityId);
    return approveToOpportunity(row.studentId, props.opportunityId);
  }

  const cancel = (row) => () => {
    return approveToOpportunity(row.studentId, props.opportunityId);
  }

  const CantUndo = ({children, row}) => {
    return <ConfirmLn
      renderBtn={children}
      title={`This action can't be undone. Proceed?`}
      onOk={onClickButton(row)}
      position={'left'}
      okText="Yes"
      noText="No, Cancel"
    />
  }

  const StatusBtn = ({row}) => {

    const p = { 
      onClick : onClickButton(row),
      disabled: row.disabled
    };

    if (row.finished) return <Finished {...p}>Finished Opportunity</Finished>
    if (row.approvedByEmployer) return <FlexRow>
      <CancelApproved disabled={row.disabled} onClick={cancel(row)}>x</CancelApproved>
      <CantUndo row={row}><FinishButton {...p}>Finish</FinishButton></CantUndo>
    </FlexRow>
    if (!props.isActive) return <Closed {...p}>Match with student</Closed>
    if (props.availablePositions == 0 || !props.isActive)  return <Closed {...p}>Match with student</Closed>
    return <StatusButton {...p}>Match with student</StatusButton>;
  }

  const statusButton = (loading) => (cell, row) => (<StatusBtn row={row} loading={loading} />)

  const gradeFormatter = (cell, row) => {
    const student = row.Student
    if (cell == 13) cell = '12+';
    return <span>{student.grade}th</span>;
  };

  const nameFormatter = (cell, row) => {
    const student = row.Student
    const user = student.User
    return <span>{user.firstName} {user.lastName}</span>;
  };

  const schoolFormatter = (cell, row) => {
    const student = row.Student
    const school = student.School
    return <span>{school.name}</span>;
  };


  let columns = [
    {
      text: 'Name',
      formatter: nameFormatter,
      classes: 'name',
      dataField: 'name'
    },
    {
      text: 'School',
      dataField: 'school',
      formatter: schoolFormatter,
      classes: 'school',
    },
    {
      text: 'Grade',
      dataField: 'grade',
      formatter: gradeFormatter,
      classes: 'grade',
    },
    {
      text: 'Status',
      dataField: 'loading',
      formatter: statusButton(props.loading),
    },
  ]  

  const paginationConfig = {
    sizePerPage: 100,
    hideSizePerPage: true,
  };

  const students = props.students.map((s) => ({
    ...s,
    disabled: loading.includes(s.studentId)
  }))

  return (
    <Container className="p-0 h-100 ">
      <Row>
        <Col>
          <StudentsWrapper>
            <BootstrapTableWrapper>
              <BootstrapTable
                columns={columns}
                data={students}
                keyField="name"
                pagination={paginationFactory(paginationConfig)}
              />
            </BootstrapTableWrapper>
          </StudentsWrapper>
        </Col>
      </Row>
    </Container>
  );

}

export default EmployerStudentList;
