import { connect } from 'react-redux';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { getChildren } from '../../redux/actions/parent';
import AssessmentStatus from './shared/assessment-status';
import { SPACING } from './shared/default-styles';
import UIWrapper from './shared/ui-wrapper';
import SignOut from './sign-out';



const HeaderText = styled.h2`
    font-weight: 800;
    margin-bottom: 10px;
`;

const P = styled.p` 
    max-width: 70%;
    flex: 1;
`;

const TopContents = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
`;

const ChildCard = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    background-color: white;
    padding: 20px;
    margin-bottom: 10px;
    min-height: 40px;
    box-shadow: 2px 2px 7px rgb(0 0 0 / 10%);
    cursor: pointer;
`

const List = styled.div`
    display: flex;
    flex-direction: column;
`;

const ChildName = styled.h3`
    font-weight: 800;
`;

const Field = styled.div`
    color: gray;
    font-size: 12px;
`;

const Tag = styled.div`
    font-size: 12px;
    padding: 5px 10px;
    border-radius: 20px;
    background-color: #f9f9f9
`;

const Row = styled.div`
    display: flex;
    align-items: center;
`;


function Child({ id, percentDone, grade, ...props}) {

    const history = useHistory();

    const onClickHandler = () => {
        history.push(`/parent/child/${id}`);
    }

    return <ChildCard onClick={onClickHandler}>
        <ChildName>{props['User.firstName']} {props['User.lastName']}</ChildName>
        <Field>{props['User.email']}</Field>
        <Row style={{marginBottom: 20, marginTop: 10}}>
            <Tag>{grade}th grade</Tag>
        </Row>
        <AssessmentStatus percent={percentDone} />
    </ChildCard>

};

function ChildrenList(props) {

    const history = useHistory()

    useEffect(() => {
        props.getChildren();
    }, [history]);

    useEffect(() => {
        if (!!props.children?.find((s) => !s.approvedByParent)) {
            history.replace('/activate-students')
        }
    }, [props.children])

    return <UIWrapper loading={props.loading} style={{ flex: 1, padding: `0px ${SPACING}`, paddingTop: 30, }}>
        <TopContents>
            <HeaderText>Hi, Parent</HeaderText>
            <P>Here is your child's status.</P>
            <SignOut />
        </TopContents>
        <List>
            {props?.children?.map((child, i) => <Child {...child} key={i} />)}
        </List>
    </UIWrapper>

}

const mapStateToProps = (state) => ({
    ...state.parent
});

const mapDispatchToProps = { getChildren }

export default connect(
    mapStateToProps, 
    mapDispatchToProps
)(ChildrenList);