import React, { Component } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div``;

const Option = styled.div`
  display: inline-block;
  padding: 5px 10px;
  cursor: pointer;
  transition: ease 0.2s;
  border-radius: 8px;
  margin: 3px;
  font-size: 12px;
  background: rgba(0,0,0,0.03);
  &.selected {
    background: #333333;
    color: #fff;
  }
`;

export default class TagSelect extends Component {
  state = {
    selectedValues: [],
    selected: null,
  };

  onSelect = (option) => {
    if (this.props.multiple) {
      let { selectedValues } = this.state;
      const indexOfValue = selectedValues.indexOf(option.value);
      if (indexOfValue > -1) {
        selectedValues.splice(indexOfValue, 1);
      } else {
        selectedValues.push(option.value);
      }
      this.setState({ selectedValues });
      if (this.props.onSelect) {
        this.props.onSelect(selectedValues);
      }
    } else {
      this.setState({ selected: option.value });
      if (this.props.onSelect) {
        this.props.onSelect(option.value);
      }
    }
  };

  componentDidUpdate() {
    console.log(this.state);
  }

  render() {
    const { selected, selectedValues } = this.state;
    let { options, multiple, value } = this.props;
    let innerValue = value ? value : multiple ? selectedValues : selected;
    return (
      <Wrapper>
        {Array.isArray(options)
          ? options.map((option, i) => {
              let className = '';
              if (
                multiple &&
                Array.isArray(innerValue) &&
                innerValue.indexOf(option.value) > -1
              ) {
                className += 'selected';
              } else if (option.value === innerValue) {
                className += 'selected';
              }
              return (
                <Option
                  key={i}
                  className={`${className}`}
                  value={option.value}
                  onClick={() => this.onSelect(option)}
                >
                  {option.text}
                </Option>
              );
            })
          : null}
      </Wrapper>
    );
  }
}
