import React from 'react';
import { Spinner } from 'react-bootstrap';

export default function ButtonLn(props) {
  let { content, loading, disabled } = props;
  return (
    <button {...props} disabled={disabled || loading}>
      {loading ? <Spinner size="sm" animation="border" /> : null}
      <span>{content}</span>
    </button>
  );
}
