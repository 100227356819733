import React from 'react';
import styled from 'styled-components';
import { Document24Regular, Dismiss12Regular } from '@fluentui/react-icons';

const FileItemBlock = styled.div`
  background-color: #f7f7f7;
  border-radius: 10px;
  padding: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  margin: 10px 0;
  .file-name {
    font-size: 12px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    padding-left: 10px;
  }
`;

export default function FileItem(props) {
  console.log(props);
  const { onRemove, name } = props;
  return (
    <FileItemBlock>
      <Document24Regular />
      <p className="file-name">{name}</p>
      <button
        style={{ height: '20px', width: '20px', justifySelf: 'flex-end' }}
        onClick={onRemove}
        className="ln-btn ln-icon-btn default"
      >
        <Dismiss12Regular className="ln-icon" />
      </button>
    </FileItemBlock>
  );
}
