export default function getRouteLevels(url, numberOfLevels) {
  const urlLevels = url.split('/');
  let urlWithLevels = '';
  for (let i = 0; i < numberOfLevels + 1; i++) {
    if (urlLevels[i]) {
      urlWithLevels = urlWithLevels + '/' + urlLevels[i];
    }
  }
  return urlWithLevels;
}
