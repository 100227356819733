import React from 'react';
import { Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { getOpportunityCategories } from '../../../redux/actions/options';

function OpportunitySubCategory(props) {
  const { categoryId } = props;
  let subCategories = [];
  if (categoryId) {
    for (let i = 0; i < props.opportunityCategories.length; i++) {
      if (props.opportunityCategories[i].id == categoryId) {
        subCategories = props.opportunityCategories[i].subcategories;
        break;
      }
    }
  } else {
    subCategories = [];
  }
  console.log(props);
  return (
    <Form.Control {...props} as="select">
      <option disabled value={-1}>
        Select subcategory
      </option>
      {subCategories.map((subcategory, i) => (
        <option value={`${subcategory.id}`}>{subcategory.name}</option>
      ))}
    </Form.Control>
  );
}

function mapStateToProps(state) {
  return {
    opportunityCategories: state.options.opportunityCategories.APIdata,
  };
}

export default connect(mapStateToProps, null)(OpportunitySubCategory);
