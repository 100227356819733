import React from 'react';
import styled from 'styled-components';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en'
import { INNER_MARGIN } from '../shared/default-styles';
import { ACTIVITY_TYPES } from '../shared/constants';
import AssessmentComplete from './assessment-complete';
import OpportunityList from './opportunity-list';
import InviteAccepted from './invite-accepted';
import AssessmentStart from './assessment-start';


TimeAgo.addDefaultLocale(en);
const timeAgo = new TimeAgo('en-US');

const Tag = styled.div`
    font-size: 12px;
    padding: 5px 10px;
    border-radius: 20px;
    background-color: #f2f3f5;
    align-self: start;
    ${INNER_MARGIN};
    margin-bottom: 10px;
`;

const ActivityWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid rgba(0,0,0,0.1);
`;

export default function Activity({ date, type, payload, name, percentDone, disableSeeAll }) {

    const Component = type === 
        ACTIVITY_TYPES.ASSESSMENT_COMPLETE 
        ? <AssessmentComplete  {...payload} name={name}/>
        : type === ACTIVITY_TYPES.SAVED_OPTIONS
        ? <OpportunityList  type={type} name={name} {...payload} disableSeeAll={disableSeeAll} />
        : type === ACTIVITY_TYPES.INTERESTED
        ? <OpportunityList   type={type}  name={name} {...payload} disableSeeAll={disableSeeAll} />
        : type === ACTIVITY_TYPES.FINISHED 
        ? <OpportunityList type={type} name={name} {...payload} disableSeeAll={disableSeeAll} />
        : type === ACTIVITY_TYPES.INVITE_ACCEPTED
        ? <InviteAccepted  name={name} />
        : <AssessmentStart name={name} percent={percentDone} {...payload} />
    
    date = typeof date === 'string' ? new Date(date) : date;

    return <ActivityWrapper>
        <Tag>{timeAgo.format(date)}</Tag>
        {Component}
    </ActivityWrapper>
};
