import React from 'react';
import { useFormikContext } from 'formik';
import styled from 'styled-components';
import ButtonLn from '../../../common/Button';

const Button = styled.button`
  width: 100%;
  height: 55px;
  border-radius: 12px;
  color: #ffffff;
  background-color: #1a1a1a;
  border: none;
  margin-top: auto;
`;

const CounselorProfileSubmitButton = ({ loading }) => {
  const { submitForm, isValid, isSubmitting } = useFormikContext();
  console.log(isSubmitting);
  return (
    <ButtonLn
      style={{ float: 'right' }}
      loading={isSubmitting}
      content={'Save changes'}
      disabled={!isValid}
      onClick={submitForm}
      type="submit"
      className="ln-btn primary"
    />
  );
};

export default CounselorProfileSubmitButton;
