import React from 'react';
import { Form } from 'react-bootstrap';
import styled from 'styled-components';
import { Formik } from 'formik';
import * as yup from 'yup';
import FormTextInput from '../components/shared/forms/FormTextInput';

const Button = styled.button`
  width: 100%;
  height: 55px;
  border-radius: 12px;
  color: #ffffff;
  background-color: #1a1a1a;
  border: none;
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const validationSchema = yup.object({
  email: yup.string().email('Invalid email address').required('Required'),
});

const ForgotPasswordEmailForm = (props) => {
  const { handleSubmit } = props;

  return (
    <Formik
      validationSchema={validationSchema}
      onSubmit={(values) => {
        const { email } = values;
        handleSubmit(email);
      }}
      initialValues={{
        email: '',
      }}
    >
      {({
        handleSubmit,
        handleChange,
        values,
        errors,
        isValid,
        isSubmitting,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <FormTextInput
            controlId="validationFormik01"
            label="email"
            type="email"
            name="email"
          />
          <Button
            disabled={!isValid}
            variant="success"
            as="input"
            size="lg"
            type="submit"
            value="Submit"
          />
        </Form>
      )}
    </Formik>
  );
};

export default ForgotPasswordEmailForm;
