import React from 'react';
import styled from 'styled-components';
import { ChevronRight28Regular as ChevronRightIcon } from '@fluentui/react-icons';
import SaveSubcategoryButton from './SaveSubcategoryButton';
import Tabs, { Tab } from '../../common/Tabs';
import StudentSubcategoryOpportunities from './StudentSubcategoryOpportunities';
import MobileSlidePopUpNoFooter from '../../../components/common/MobileSlidePopUpNoFooter';
import { track, VIEW_SUBCATEGORY } from '../../../utils/mixpanel';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-radius: 10px;
  margin: 0 0 20px 0;
  box-shadow: 0 6px 10px 0 rgb(0 0 0 / 10%);
  cursor: pointer;
`;
const ItemTitle = styled.h5`
  font-size: 16px;
  font-weight: 500;
`;
const MetaData = styled.p`
  margin: 0;
  font-size: 12px;
  color: #afafaf;
`;
const ChevronRight = styled(ChevronRightIcon)`
  color: #d4d4d4;
`;

export default function SubcategoryItem(props) {
  const { name, meta } = props;

  return (
    <MobileSlidePopUpNoFooter
      initiator={
        <Wrapper>
          <div>
            <ItemTitle>{name}</ItemTitle>
            <MetaData>{meta}</MetaData>
          </div>
          <ChevronRight />
        </Wrapper>
      }
      title={name}
      footer={true}
      onOK={() => console.log('okClicked')}
      footerOKBtn={null}
      style={{ minHeight: '80vh' }}
    >
      <SubcategoryDetails {...props} />
    </MobileSlidePopUpNoFooter>
  );
}

const Description = styled.div`
  ${'' /* height: 80vh; */}
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  overflow-y: auto;
  .title {
    font-size: 16px;
    margin-bottom: 1rem;
  }

  p {
    font-size: 14px;
  }
`;

const DescriptionSection = styled.div``;

const DescriptionSectionLast = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-grow: 1;
`;

const Skill = styled.div`
  background: #f2f3f5;
  padding: 10px;
  display: inline-block;
  border-radius: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  font-weight: 500;
`;

export const SubcategoryDetails = (props) => {
  const { description, cluster, opportunitiesCount, subcategoryId } = props;
    
  track(VIEW_SUBCATEGORY, { name: props?.name, key: props?.cluster?.key })

  return (
    <Tabs
      items={[
        <Tab title="Description">
          <Description>
            <DescriptionSection className="mg-b">
              <h6 className="title">What it means</h6>
              <p>{description}</p>
            </DescriptionSection>
            <DescriptionSection>
              <h6 className="title">Skills</h6>
              {cluster && cluster.skills && Array.isArray(cluster.skills)
                ? cluster.skills.map((skill, i) => <Skill>{skill.name}</Skill>)
                : null}
            </DescriptionSection>
            <DescriptionSectionLast>
              <SaveSubcategoryButton {...props} />
            </DescriptionSectionLast>
          </Description>
        </Tab>,
        <Tab
          title={`${opportunitiesCount ? opportunitiesCount : 0} ${
            opportunitiesCount == 1 ? 'Opportunity' : 'Opportunities'
          }`}
        >
          <Description>
            {opportunitiesCount ? (
              <StudentSubcategoryOpportunities subcategoryId={subcategoryId} />
            ) : null}
          </Description>
        </Tab>,
      ]}
    />
  );
};
