import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import { Row, Col, Modal, Form as BForm } from 'react-bootstrap';
import FormTextInput from '../shared/forms/FormTextInput';
import FormSelectInput from '../shared/forms/FormSelectInput';

const validationSchema = Yup.object({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  email: Yup.string().required('Email is required'),
  schoolId: Yup.string().required('School is required'),
});

const Button = styled.button`
  width: 100%;
  height: 55px;
  border-radius: 12px;
  color: #ffffff;
  background-color: #1a1a1a;
  border: none;
  margin-top: 1.25rem;
  font-weight: bold;
`;

const AddCounselor = (props) => {
  const {
    handleCloseAddCounselor,
    showAddCounselor,
    schools,
    handleAddCounselor,
  } = props;

  return (
    <Modal show={showAddCounselor} onHide={handleCloseAddCounselor}>
      <Modal.Header closeButton>
        <Modal.Title>Add Counselor</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          validationSchema={validationSchema}
          onSubmit={(values) => {
            handleAddCounselor(values);
          }}
          initialValues={{
            firstName: '',
            lastName: '',
            email: '',
          }}
        >
          {({
            values,
            errors,
            dirty,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
          }) => (
            <Form noValidate id="AddCounselorForm">
              <Row className="mb-1">
                <BForm.Group as={Col} controlId="formGridName">
                  <FormTextInput
                    controlId="validationFormik00"
                    label="First Name"
                    type="text"
                    name="firstName"
                  />
                </BForm.Group>
              </Row>
              <Row className="mb-1">
                <BForm.Group as={Col} controlId="formGridAddress">
                  <FormTextInput
                    controlId="validationFormik01"
                    label="Last Name"
                    type="text"
                    name="lastName"
                  />
                </BForm.Group>
              </Row>
              <Row className="mb-1">
                <BForm.Group as={Col} controlId="formGridAddress">
                  <FormTextInput
                    controlId="validationFormik02"
                    label="Email"
                    type="text"
                    name="email"
                  />
                </BForm.Group>
              </Row>
              <Row className="mb-1">
                <BForm.Group as={Col} controlId="formGridState">
                  <FormSelectInput
                    // as={Col}
                    // sm="4"
                    controlId="validationFormik04"
                    label="School"
                    type="text"
                    name="schoolId"
                  >
                    <option value="" label="Select a school">
                      Select a school{' '}
                    </option>
                    {schools.map((school) => (
                      <option
                        value={school.id}
                        label={school.name}
                        key={`${school.id} - ${school.name}`}
                      >
                        {school.name}
                      </option>
                    ))}
                  </FormSelectInput>
                </BForm.Group>
              </Row>
              <Button
                // disabled={!isValid}
                variant="success"
                as="input"
                size="lg"
                type="submit"
                value="Submit"
                form="AddCounselorForm"
              />
            </Form>
          )}
        </Formik>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

export default AddCounselor;
