import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';
import dotenv from 'dotenv';
import JobList from '../JobList';

dotenv.config();

const PageHeader = styled.h3`
  margin-top: 6px;
  margin-bottom: 15px;
`;

class JobPositions extends React.Component {
  render() {
    return (
      <Container className="p-0 h-100 ">
        <Row>
          <Col xs={6}>
            <PageHeader>Opportunities</PageHeader>
          </Col>
        </Row>
        <JobList/>
      </Container>
    );
  }
}


export default JobPositions;
