import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';

import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import ResetPasswordForm from './ResetPasswordForm';
import LaunchNowHeader from '../components/shared/LaunchNowHeader';
import { Redirect } from 'react-router-dom';
import { API } from '../settings';
import { setToast } from '../features/users/userSlice';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const Box = styled.div`
  margin-top: 65px;
  width: 330px;
`;

const ResetCopy = styled.div`
  padding: 2rem 0;
`;

class ActivateAccount extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formIsSubmitting: false,
      loading: false,
      passwordResetToken: '',
      passwordUpdated: false,
    };

    this.handlePasswordSubmit = this.handlePasswordSubmit.bind(this);
  }

  componentDidMount() {
    const { passwordResetToken } = this.props.match.params;
    this.setState({ passwordResetToken });
  }

  handlePasswordSubmit(password, confirmPassword) {
    const { passwordResetToken } = this.state;
    const { setToast } = this.props;

    API.post(`/users/${passwordResetToken}/reset-password`, {
      password,
      confirmPassword,
    })
      .then((response) => {
        let responseData = response.data;
        const message = responseData.message;

        if (message) {
          if (message === 'Password updated.') {
            setToast({
              toastMessage: 'Password updated! Please log in.',
              toastStatus: 'success',
            });

            this.setState({ passwordUpdated: true });
          }
        }
      })
      .catch((error) => {
        let errorMessage = 'Something went wrong.';

        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          errorMessage = error.response.data.message;
        }

        toast.error(errorMessage);
      });

    this.setState({ formIsSubmitting: false });
  }

  render() {
    const { formIsSubmitting, passwordUpdated } = this.state;

    if (passwordUpdated) {
      return (
        <Redirect
          to={{
            pathname: '/sign-in',
          }}
        />
      );
    } else {
      return (
        <Container fluid>
          <Row>
            <Col>
              <LaunchNowHeader />
              <Wrapper>
                <Box>
                  <Link to="/sign-in">&lt; Back</Link>
                  <ResetCopy>Create your new password below...</ResetCopy>
                  <ResetPasswordForm
                    handlePasswordSubmit={this.handlePasswordSubmit}
                    formIsSubmitting={formIsSubmitting}
                  />
                </Box>
              </Wrapper>
            </Col>
          </Row>
        </Container>
      );
    }
  }
}

const mapStateToProps = (state) => ({ user: state.user });
const mapDispatchToProps = { setToast };

export default connect(mapStateToProps, mapDispatchToProps)(ActivateAccount);
