import React from 'react';
import {
  PauseCircle20Regular as PauseCircle,
  CheckmarkCircle20Regular as Checkmark,
  RecordStop20Regular as Stop,
} from '@fluentui/react-icons';

const displayIcon = (icon) => {
  switch (icon) {
    case 'stop':
      return <Stop style={{ color: '#fc5a5a' }} />;
    case 'pause':
      return <PauseCircle style={{ color: '#ffc34e' }} />;
    default:
      return <Checkmark style={{ color: '#3dd598' }} />;
  }
};

const DashboardStatCard = (props) => {
  const { title, value, loading, icon } = props;

  if (loading) {
    return null;
  }

  return (
    <div
      style={{
        width: '100%',
        boxShadow: '0 6px 10px 0 rgba(0, 0, 0, 0.02)',
        height: '130px',
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        borderRadius: '20px',
        backgroundColor: '#ffffff',
      }}
    >
      <div className="horizontal-flex align-center space-between">
        <h6 style={{ color: '#858585' }}>{title}</h6>
        <h6>{displayIcon(icon)}</h6>
      </div>
      <h3>{value}</h3>
    </div>
  );
};

export default DashboardStatCard;
