import React from 'react';
import styled from 'styled-components';

const Field = styled.div`
    color: gray;
    font-size: 12px;
`;

const Row = styled.div`
    display: flex;
    align-items: center;
`;

const AssessmentBar = styled.div`
    background-color: #f2f3f5;
    border-radius: 5px;
    display: flex;
    flex: 1;
    margin-right: 10px;
`;

const AssessmentPercent = styled.div`
    border-radius: 5px;
`;

const Percent = styled.div`
    font-size: 14px;
    padding: 0px;
`

export default function AssessmentStatus({percent, height = 10, hideText, style = {}}) {

    const backgroundColor = percent === 100 ? 
    '#47d191' : '#ffc34d';

    return <>
        {!hideText && <Field>Assessment Status</Field>}
        <Row style={{...style}}>
            <AssessmentBar style={{height}}>
                <AssessmentPercent 
                style={{width: `${percent}%`, height, backgroundColor}}
                ></AssessmentPercent>
            </AssessmentBar>
            <Percent>{`${percent}%`}</Percent>
        </Row>
    </>
}
