import React from 'react';

export default function CircularProgress(props) {
  const { sqSize, strokeWidth, percentage, progressColor, icon, color } = props;

  // SVG centers the stroke width on the radius, subtract out so circle fits in square
  const radius = (sqSize - strokeWidth) / 2;
  // Enclose cicle in a circumscribing square
  const viewBox = `0 0 ${sqSize} ${sqSize}`;
  // Arc length at 100% coverage is the circle circumference
  const dashArray = radius * Math.PI * 2;
  // Scale 100% coverage overlay with the actual percent
  const dashOffset = dashArray - (dashArray * percentage) / 100;

  // const iconComponent = styled.icon`
  //     position: absolute;
  //     top: calc(50% - 10px);
  //     left: calc(50% - 10px)
  // `

  let iconComponent;

  if (icon) {
    iconComponent = React.cloneElement(icon, {
      style: {
        position: 'absolute',
        top: 'calc(50% - 12px)',
        left: 'calc(50% - 12px)',
        color: color,
      },
    });
  }

  return (
    <div style={{ position: 'relative', width: 'min-content' }}>
      <svg width={sqSize} height={sqSize} viewBox={viewBox}>
        <circle
          className="circle-background"
          cx={sqSize / 2}
          cy={sqSize / 2}
          r={radius}
          strokeWidth={`${strokeWidth}px`}
        />
        <circle
          className="circle-progress"
          cx={sqSize / 2}
          cy={sqSize / 2}
          r={radius}
          strokeWidth={`${strokeWidth}px`}
          // Start progress marker at 12 O'Clock
          transform={`rotate(-90 ${sqSize / 2} ${sqSize / 2})`}
          style={{
            stroke: color,
            strokeDasharray: dashArray,
            strokeDashoffset: dashOffset,
            transition: 'ease-out .3s',
          }}
        />
        {!icon ? (
          <text
            className="circle-text"
            x="50%"
            y="50%"
            dy=".3em"
            style={{ color: color }}
            textAnchor="middle"
          >
            {`${percentage}%`}
          </text>
        ) : null}
      </svg>
      {iconComponent}
    </div>
  );
}

// CircularProgressBar.defaultProps = {
//     sqSize: 100,
//     percentage: 25,
//     strokeWidth: 5
// };
