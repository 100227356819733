import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import HomeHeader from '../shared/home-header';
import StepIndicator from '../shared/step-indicator';
import UIWrapper from '../shared/ui-wrapper';

const HeaderText = styled.h1`
    font-weight: 800;
    margin-top: 25vh;
`

const P = styled.p` 
    max-width: 70%;
    margin-top: 20px;
    flex: 1;
`;

const TopContents = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 30px;
`;

const BottomBar = styled.div`
    display: flex;
    height: 100px;
    align-items: center;
    justify-content: center;
    padding: 30px;
`;

const NextButton = styled.div`
    background-color: black;
    font-weight: 800;
    padding: 0px 20px;
    border-radius: 10px;
    color: white;
    display: flex;
    align-items: center;
    justify-self: center;
    height: 40px;
    cursor: pointer;
`

export default function AssessmentInstructions() {

    const history = useHistory();

    const advance = () => {
        history.push('/parent/instructions/opportunity')
    }

    return <UIWrapper style={{ height: '100vh', flex: 1, }}>
        <HomeHeader />
        <TopContents>
            <HeaderText>Assessment</HeaderText>
            <P>
                After your child completes the assessment we will present options that match their skills and interests. 
                <br/> <b>This is all about them.</b>
            </P>
        </TopContents>
        <BottomBar>
            <StepIndicator style={{ flex: 1 }} totalSteps={2} currentStep={1} />
            <NextButton onClick={advance}>
                Next
            </NextButton>
        </BottomBar>
    </UIWrapper>

}