import React, { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { getOpportunityCategories } from '../../../redux/actions/options';

function OpportunityCategory(props) {
  useEffect(() => {
    props.getOpportunityCategories();
  }, []);
  return (
    <Form.Control {...props} as="select">
      <option disabled value={-1}>
        Select category
      </option>
      {props.opportunityCategories.map((category, i) => (
        <option value={`${category.id}`}>{category.name}</option>
      ))}
    </Form.Control>
  );
}

function mapStateToProps(state) {
  return {
    opportunityCategories: state.options.opportunityCategories.APIdata,
  };
}

export default connect(mapStateToProps, { getOpportunityCategories })(
  OpportunityCategory
);
