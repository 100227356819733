import { ACTIVITY_TYPES } from '../../../components/parent/shared/constants';
import { clusterActivityData, formatToList } from '../../../utils/cluster-by-date';
import { CHILD_ACTIVITY_GET, CHILDREN_GET } from '../../constants/action-types';

const initialState = {
  loading: true,
  activity: {},
  options: {},
  children: [],
  saved: [],
  interested: [],
};


function parentReducer(state = initialState, action) {
  switch (action.type) {
    case CHILDREN_GET:
      return {
        ...state,
        children: action.payload.data,
        loading: false,
      }
    case CHILD_ACTIVITY_GET:
      const data = action.payload.data;
      const formatted = clusterActivityData(data)
    
      return { ...state, 
            loading: false,
            activity: {
                ...state.activity,
                [action.payload.childId]: {
                  data: formatted,
                  name: action.payload.name,
                },
            },
            saved: {
              [action.payload.childId]: {
                data: formatToList(action.payload.data, ACTIVITY_TYPES.SAVED_OPTIONS),
                name: action.payload.name
              }
            },
            interested: {
              [action.payload.childId]: {
                data: formatToList(action.payload.data, ACTIVITY_TYPES.INTERESTED),
                name: action.payload.name
              }
            }
        };
    default:
        break;
  }
  return state;
}

export default parentReducer;
