import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import SubcategoriesList from './SubcategoriesList';

const SubcategoriesPanel = styled.div`
  background-color: #ffffff;
  border-radius: 1.25rem;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.02);
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const SubcategoriesWrapper = styled.div`
  max-height: 100%;
  overflow-y: scroll;
  height: 290px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Title = styled.h6`
  font-size: 1rem;
  color: rgb(133, 133, 133);
`;

const PopularSubcategories = (props) => {
  const { loading, title, subcategories } = props;

  if (loading) {
    return null;
  }

  return (
    <SubcategoriesPanel>
      <Title>{title}</Title>
      <SubcategoriesWrapper>
        <SubcategoriesList subcategories={subcategories} />
      </SubcategoriesWrapper>
    </SubcategoriesPanel>
  );
};

export default PopularSubcategories;

PopularSubcategories.propTypes = {
  loading: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  subcategories: PropTypes.array.isRequired,
};
