import { OPTIONS_GET_OPPORTUNITY_CATEGORIES } from '../../constants/action-types';

const initialState = {
  loading: false,
  count: null,
  APIdata: [],
};

function categoriesReducer(state = initialState, action) {
  switch (action.type) {
    case OPTIONS_GET_OPPORTUNITY_CATEGORIES:
      return Object.assign({}, state, {
        APIdata: action.payload.clusters,
      });
    default:
      break;
  }
  return state;
}

export default categoriesReducer;
