import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';
import dotenv from 'dotenv';
import { connect } from 'react-redux';
import AddNewStudent from '../AddNewStudent';
import StudentList from '../StudentList';

dotenv.config();

const PageHeader = styled.h3`
  margin-top: 6px;
  margin-bottom: 15px;
`;

class Students extends React.Component {
  render() {
    return (
      <Container className="p-0 h-100 ">
        <Row>
          <Col xs={6}>
            <PageHeader>Students</PageHeader>
          </Col>
          <Col xs={6}>
            <div className="float-right">
              <AddNewStudent />
            </div>
          </Col>
        </Row>
        <StudentList
          studentPageLinkBase="/counselor/students/"
          schoolId={this.props.user.userTypeProfile.schoolId}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(Students);
