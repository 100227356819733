import { STUDENT_PROFILE_GET } from '../../constants/action-types';

const initialState = {
  loading: true,
};

function studentProfile(state = initialState, action) {
  switch (action.type) {
    case STUDENT_PROFILE_GET:
      return { ...state, ...action.payload };
    default:
      break;
  }
  return state;
}

export default studentProfile;
