import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { API } from '../settings';
import AllSubcategoriesList from './AllSubcategoriesList';
import SubcategoryPanel from './SubcategoryPanel';

const BackLink = styled(Link)`
  color: #000000;
  display: inline-block;
  font-size: 0.875rem;
  &:hover {
    color: #000000;
  }
`;

const ClustersHeader = styled.h3`
  margin: 1rem 0 0;
  padding: 0;
  font-size: 1.5rem;
`;

const SubcategoriesCount = styled.div`
  margin-top: 0.5rem;
  font-size: 1rem;
`;

const Container = styled.div`
  min-height: 100vh;
  min-height: -webkit-fill-available;
`;

const PageWrapper = styled.div`
  min-height: 100vh;
  min-height: -webkit-fill-available;
  padding: 25px 25px 25px;
  position: relative;
  background-color: #f9f9f9;
`;

class AllClusters extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      cluster: {},
      loading: true,
      showPanel: false,
      skills: [],
      subcategories: [],
      subcategory: {},
    };

    this.toggleSubcategoryPanel = this.toggleSubcategoryPanel.bind(this);
  }

  componentDidMount() {
    const { clusterId } = this.props.match.params;

    API.get(`/clusters/${clusterId}`)
      .then((response) => {
        const cluster = response.data;
        const { skills, subcategories } = cluster;

        this.setState({ cluster, loading: false, skills, subcategories });
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        // TODO: handle error
        console.log(error);
      });
  }

  toggleSubcategoryPanel(subcategoryId) {
    const { showPanel, subcategories } = this.state;
    let subcategory = {};

    if (subcategoryId) {
      subcategory = subcategories.find((obj) => obj.id === subcategoryId);
    }

    this.setState({ showPanel: !showPanel, subcategory });
  }

  render() {
    const { clusterId } = this.props.match.params;
    const { cluster, showPanel, skills, subcategory, subcategories } =
      this.state;

    return (
      <Container>
        <PageWrapper>
          <BackLink to={`/clusters/${clusterId}`}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </BackLink>
          <ClustersHeader>{cluster.name}</ClustersHeader>
          <SubcategoriesCount>
            {subcategories.length} Sub-categories
          </SubcategoriesCount>
          <AllSubcategoriesList
            cluster={cluster}
            subcategories={subcategories}
            toggleSubcategoryPanel={this.toggleSubcategoryPanel}
          />
        </PageWrapper>
        <SubcategoryPanel
          showPanel={showPanel}
          skills={skills}
          subcategory={subcategory}
          toggleSubcategoryPanel={this.toggleSubcategoryPanel}
        />
      </Container>
    );
  }
}

export default AllClusters;
