import React, { Component, createRef } from 'react';
import styled from 'styled-components';

import { Dismiss16Regular } from '@fluentui/react-icons';
import { Spinner } from 'react-bootstrap';
import { API } from '../../../settings';
import MobileSlidePopUp from '../../common/MobileSlidePopUp';
import OpportunityItem from './OpportunityItem';

const PageWrapper = styled.div`
  min-height: 100vh;
  height: 100vh;
  padding: 25px 0 25px;
  position: relative;
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .padded-content {
    padding: 0 25px;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

const PageTitle = styled.h2`
  color: #000000;
  font-size: 25px;
  margin-bottom: 0;
  padding-bottom: 0;
`;

const PageLiner = styled.div`
  padding: 0 25px 25px;

  &.noScroll {
    overflow-y: hidden;
  }
`;

const OpportunitiesList = styled.div`
  padding: 20px 20px 60px 20px;
  overflow-y: auto;
`;

const LoadingSpinner = styled(Spinner)`
  height: 50px;
  width: 50px;
`;

const FavoritesCount = styled.div`
  float: right;
  font-weight: 600;
`;

const WhatsNextMsg = styled.div`
  background: #333333;
  color: #fff;
  padding: 15px;
  margin: 0 20px;
  border-radius: 10px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .closeBtn {
    background: transparent;
    color: #fff;
  }

  p {
    font-size: 12px;
    margin: 0;
  }
`;

const DismissIcon = styled(Dismiss16Regular)`
  color: #fff;
  display: block;
`;

const PopupButton = styled.button`
  height: 50px;
  display: block;
`;

class StudentInterestedIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      interestedOpportunities: [],
      page: 1,
      limit: 10,
      count: 0,
      prompt: undefined,
    };
    this.opportunitiesListRef = createRef();
  }

  getData = (page = this.state.page, limit = this.state.limit) => {
    this.setState({ loading: true, page: page, limit: limit });
    const {
      user: { uuid },
    } = this.props;
    API.get(
      `/opportunities/interested?page=${page}&limit=${limit}&sort=asc&uuid=${uuid}`
    )
      .then((res) => {
        let { interestedOpportunities } = this.state;
        interestedOpportunities = interestedOpportunities.concat(
          res.data.interestedOpportunities
        );
        this.setState({
          interestedOpportunities: interestedOpportunities,
          count: res.data.count,
          loading: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  componentDidMount() {
    this.getData();

    // TODO: fix the ability to hide the what's-next prompt
    const prompt = localStorage.getItem('interestedInPrompt');
    this.setState({ prompt: prompt === 1 ? false : true });
  }

  onScroll = () => {
    const el = this.opportunitiesListRef.current;
    if (el.scrollHeight - el.scrollTop - el.clientHeight < 1) {
      this.getData(this.state.page + 1);
    }
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevState !== this.state &&
      prevState.count === null &&
      this.opportunitiesListRef &&
      this.opportunitiesListRef.current
    ) {
      this.opportunitiesListRef.current.addEventListener(
        'scroll',
        this.onScroll
      );
    }
  }

  componentWillUnmount() {
    this.opportunitiesListRef.current.removeEventListener(
      'scroll',
      this.onScroll
    );
  }

  closePrompt = (e) => {
    e.stopPropagation();
    e.preventDefault();
    this.setState({ prompt: false });
    localStorage.setItem('interestedInPrompt', 1);
  };

  onInterestRevoke = (i) => {
    let { interestedOpportunities, count } = this.state;

    interestedOpportunities.splice(i, 1);
    count -= 1;

    this.setState({ interestedOpportunities, count });
    // this.props.getStudentInterested(null, interestedOpportunities);
  };

  render() {
    const { loading, interestedOpportunities, count, prompt } = this.state;
    return (
      <PageWrapper>
        <PageLiner>
          <TitleWrapper>
            <PageTitle>
              Opportunities <br /> I'm Interested In
            </PageTitle>
            <FavoritesCount>{count}</FavoritesCount>
          </TitleWrapper>
        </PageLiner>
        {prompt ? (
          <MobileSlidePopUp
            initiator={
              <WhatsNextMsg>
                <div className="header">
                  <h6>What's Next?</h6>
                  <DismissIcon onClick={(e) => this.closePrompt(e)} />
                </div>
                <p>
                  After you show interest in a few opportunities, the guidance
                  counselor will reach out to schedule an appointment
                </p>
              </WhatsNextMsg>
            }
            title="What's Next?"
            closeButton={false}
            footer={true}
            onOK={() => console.log('okClicked')}
            footerOKBtn={
              <PopupButton className="ln-btn primary full-width">
                OK, Got It
              </PopupButton>
            }
          >
            <p>
              It's great that you showed interest in so many opportunities.{' '}
              <br />
              Our guidance counselor will reach out to you to setup a meeting to
              discuss further about these opportunities and any other questions
              that you might have.
            </p>
          </MobileSlidePopUp>
        ) : null}
        {/*<ByCategories*/}
        {/*  percentDone={100}*/}
        {/*  clusters={[*/}
        {/*    {*/}
        {/*      assessmentId: 1,*/}
        {/*      assessmentKey: 'ASSESSMENT_1',*/}
        {/*      categoryName: null,*/}
        {/*      clusterNumber: 1,*/}
        {/*      createdAt: '2021-04-05T20:53:11.954Z',*/}
        {/*      hollandCode: 'Realistic/Investigative',*/}
        {/*      id: 1,*/}
        {/*      key: 'AGRICULTURE_FOOD_NATURAL_RESOURCES',*/}
        {/*      name: 'Agriculture/Food/Natural Resources',*/}
        {/*      order: 0,*/}
        {/*      updatedAt: '2021-04-05T20:53:11.975Z',*/}
        {/*    },*/}
        {/*  ]}*/}
        {/*/>*/}
        <OpportunitiesList ref={this.opportunitiesListRef}>
          {interestedOpportunities.map((opportunity, i) => (
            <OpportunityItem
              key={opportunity.id}
              to={`/student/opportunity/${opportunity.id}`}
              imgSrc=""
              imgAlt=""
              title={opportunity.title}
              companyName={opportunity.Employer.name}
              opportunityId={opportunity.id}
              onInterestRevoke={() => this.onInterestRevoke(i)}
              location={
                opportunity.performedAt === 'REMOTE'
                  ? 'Remote'
                  : `${opportunity.location}, ${
                      opportunity.State ? opportunity.State.code : ''
                    }`
              }
              customMeta
            />
          ))}
          {loading ? (
            <center>
              <LoadingSpinner animation="border" />
            </center>
          ) : null}
        </OpportunitiesList>
      </PageWrapper>
    );
  }
}

// export default connect(
//   (state) => ({
//     ...state.student.interestedOpportunities,
//     interestedOpportunities: [
//       ...state.student.interestedOpportunities.interestedOpportunities,
//     ],
//   }),
//   { getStudentInterested }
// )(StudentInterestedIn);

export default StudentInterestedIn;
