import React from 'react';
import styled from 'styled-components';
import { Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { API } from '../settings';
import AllClustersList from './AllClustersList';

const BackLink = styled(Link)`
  color: #000000;
  display: inline-block;
  font-size: 0.875rem;
  width: 100px;
  margin-top: -0.5rem;
  padding: 0.5rem 0;
  &:hover {
    color: #000000;
  }
`;

const ClustersHeader = styled.h3`
  margin: 0.5rem 0 0;
  padding: 0;
  font-size: 1.5rem;
`;

const PageWrapper = styled.div`
  height: 100vh;
  width: 100%;
  padding: 25px 25px 25px;
  position: relative;
`;

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  width: 100%;
`;

const LoadingSpinner = styled(Spinner)`
  height: 50px;
  width: 50px;
`;

class AllClusters extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      clusters: [],
      loading: true,
    };
  }

  componentDidMount() {
    API.get(`/clusters`)
      .then((response) => {
        const { clusters } = response.data;
        this.setState({ clusters, loading: false });
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        // TODO: handle error
        console.log(error);
      });
  }

  render() {
    const { clusters, loading } = this.state;

    const displayClusters = () => {
      if (loading) {
        return (
          <SpinnerWrapper>
            <LoadingSpinner animation="border" variant="secondary" />
          </SpinnerWrapper>
        );
      } else {
        return <AllClustersList clusters={clusters} />;
      }
    };

    return (
      <PageWrapper>
        <BackLink to="/dashboard">
          <FontAwesomeIcon icon={faChevronLeft} />
        </BackLink>
        <ClustersHeader>Categories</ClustersHeader>
        {displayClusters()}
      </PageWrapper>
    );
  }
}

export default AllClusters;
