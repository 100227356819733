import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import StudentProfile from './StudentProfile';

const Profile = (props) => {
  const { user } = props;
  const { userType } = user;

  if (!user || !user.isLoggedIn) {
    return <Redirect to="/" />;
  }

  if (user && (userType === 'COUNSELOR' || user.userType === 'SUPER_ADMIN')) {
    return <Redirect to="/admin/dashboard" />;
  }

  switch (userType) {
    case 'EMPLOYER':
      return <Redirect to="/employer/settings/my-profile" />;
    case 'PARENT':
      return <Redirect to="/" />;
    default:
      return <StudentProfile />;
  }
};

const mapStateToProps = (state) => ({ user: state.user });

export default connect(mapStateToProps)(Profile);
