import React from 'react';

export default function StatusIndicator(props) {
  const statuses = {
    positive: { color: '#3dd598', text: 'Active' },
    negative: { color: '#fc5a5a', text: 'Closed' },
    neutral: { color: 'orange', text: 'Matches Found' },
  };

  const { status } = props;
  return (
    <div {...props} className="horizontal-flex align-center">
      <span
        style={{
          backgroundColor: statuses[status].color,
          height: '8px',
          width: '8px',
          margin: '5px',
          borderRadius: '50%',
        }}
      ></span>
      <span style={{ margin: '5px' }}>{statuses[status].text}</span>
    </div>
  );
}
