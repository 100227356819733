import React from 'react';
import { Container, Row, Col, Spinner } from 'react-bootstrap';
import styled from 'styled-components';
import dotenv from 'dotenv';

import { Link } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { API } from '../../../settings';
import {
  compareLastNames,
  uniqueStudentsByEmail,
} from '../../../utils/arrayOps';
import { Search20Regular } from '@fluentui/react-icons';

dotenv.config();

const PageHeader = styled.h3`
  margin-top: 6px;
  margin-bottom: 15px;
`;

const StudentsWrapper = styled.div`
  background: #ffffff;
  padding: 1.5rem;
  border-radius: 1.25rem;
  table,
  th,
  td {
    border: none;
  }
  thead {
    th {
      border: none;
    }
  }
  tbody:before {
    content: '@';
    display: block;
    line-height: 0.5rem;
    text-indent: -99999px;
  }
  td {
    font-size: 0.875rem;
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
  }
  td.firstName,
  td.lastName {
    font-size: 1rem;
    font-weight: bold;
    background: none;
  }
  td.grade,
  td.percentDone {
    span {
      background: #fbfbfb;
      display: inline-block;
      padding: 0.2rem 1rem;
      border-radius: 8px;
    }
  }
`;

const FilterWrapper = styled.div`
  position: relative;
`;

const Filter = styled.input`
  margin-bottom: 1.25rem;
  width: 100%;

  font-size: 0.875rem;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  padding-left: 2.25rem;
  border-radius: 0.625rem;
  border: 1px solid #e2e2ea;
  font-family: 'Nunito';
  &::placeholder {
    color: #929292;
    opacity: 1;
  }
`;

const SearchIcon = styled(Search20Regular)`
  position: absolute;
  top: calc(50% - 10px);
  left: 5px;
  color: #92929d;
`;

const BootstrapTableWrapper = styled.div`
  .table td {
    overflow-wrap: break-word;
  }
  .table th {
    background: #fbfbfb;
    color: #858585;
    font-size: 0.6875rem;
    &:first-child {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }
    &:last-child {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }
  .react-bootstrap-table-pagination {
    margin-top: 2rem;
    justify-content: center;
    div {
      display: none;
    }
    div.react-bootstrap-table-pagination-list {
      display: flex;
      justify-content: center;
      ul {
        float: none;
      }
    }
    .page-link {
      border-color: #797878;
      color: #797878;
    }
    .page-item.active .page-link {
      color: #ffffff;
      background-color: #000000;
      border-color: #000000;
    }
  }
`;

export default class Employers extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: '',
      filteredStudents: [],
      loading: false,
      students: [],
    };

    this.handleFilterChange = this.handleFilterChange.bind(this);
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    this.setState({ loading: true });
    API.get(`/students/all`)
      .then((response) => {
        this.setState({
          filteredStudents: response.data.students,
          students: response.data.students,
          loading: false,
        });
      })
      .catch((error) => {
        // TODO: handle error
        console.log(error);
      })
      .finally(() => this.setState({ loading: false }));
  };

  handleFilterChange = (event) => {
    const { students } = this.state;
    const filter = event.target.value;

    const filteredByFirstName = students.filter((student) =>
      student.firstName.toLowerCase().includes(filter.toLowerCase())
    );

    const filteredByLastName = students.filter((student) =>
      student.lastName.toLowerCase().includes(filter.toLowerCase())
    );

    // Add filterered arrays, sort by last name and ensure uniqueness
    const filteredStudents = uniqueStudentsByEmail(
      [...filteredByFirstName, ...filteredByLastName].sort(compareLastNames)
    );

    this.setState({ filter, filteredStudents });
  };

  render() {
    const { filter, filteredStudents, loading } = this.state;

    const firstNameFormatter = (cell, row) => {
      return <Link to={`/counselor/students/${row.uuid}`}>{cell}</Link>;
    };

    const lastNameFormatter = (cell, row) => {
      return <Link to={`/counselor/students/${row.uuid}`}>{cell}</Link>;
    };

    const columns = [
      {
        text: 'Company Name',
        dataField: 'firstName',
        sort: true,
        formatter: firstNameFormatter,
        classes: 'firstName',
      },
      {
        text: 'Contact Person',
        dataField: 'lastName',
        sort: true,
        formatter: lastNameFormatter,
        classes: 'lastName',
      },
      {
        text: 'Company Category',
        dataField: 'grade',
        sort: true,
        classes: 'grade',
      },
      {
        text: 'Opportunities',
        dataField: 'percentDone',
        sort: true,
        classes: 'percentDone',
      },
    ];

    const paginationConfig = {
      sizePerPage: 100,
      hideSizePerPage: true,
    };

    return (
      <Container fluid className="p-0 h-100 ">
        <Row>
          <Col xs={12}>
            <div
              className="horizontal-flex align-center space-between children-mg-r mg-b"
              style={{ height: '40px' }}
            >
              <PageHeader>Employers</PageHeader>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <StudentsWrapper>
              <FilterWrapper>
                <Filter
                  type="text"
                  value={filter}
                  placeholder="Search by First or Last Name..."
                  onChange={this.handleFilterChange}
                />
                <SearchIcon icon={faSearch} size="1x" />
              </FilterWrapper>
              <BootstrapTableWrapper>
                {loading ? (
                  <center>
                    <Spinner animation="border" />
                  </center>
                ) : (
                  <BootstrapTable
                    columns={columns}
                    data={filteredStudents}
                    keyField="email"
                    pagination={paginationFactory(paginationConfig)}
                  />
                )}
              </BootstrapTableWrapper>
            </StudentsWrapper>
          </Col>
        </Row>
      </Container>
    );
  }
}
