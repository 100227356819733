import React from 'react';
import styled from 'styled-components';
import logo from '../../images/logo.png';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const Box = styled.div`
  width: 330px;
  margin-left: -20px;
  @media (max-width: 768px) {
    margin-top: 25px;
  }
`;

const LaunchNowHeader = () => {
  return (
    <Wrapper>
      <Box>
        <img src={logo} alt="Logo" width="200" />
      </Box>
    </Wrapper>
  );
};

export default LaunchNowHeader;
