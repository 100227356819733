import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';
import StudentOpportunityModal from '../../student-view/StudentOpportunityModal';
import { ACTIVITY_TYPES } from '../shared/constants';
import { INNER_MARGIN, SPACING } from '../shared/default-styles';

const ActivityDescription = styled.p`
    margin-top: 10px;
    padding-left: ${SPACING};
`;

const OpportunityListWrapper = styled.div`
    display: grid;
    grid-auto-flow: column;
    overflow-x: scroll;
    overflow-y: hidden;
    padding: 10px 5px;
    position: absolute;
    background-color: transparent;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    bottom: -20px;
    padding-left: ${SPACING};
    grid-auto-columns: 260px;
    grid-auto-rows: 135px;

`;

const OpportunityWrapper = styled.div`
    cursor: pointer;
    border-radius: 10px;
    background-color: white;
    box-shadow: 1px 1px 5px rgba(0,0,0,0.5);
    display: flex;
    flex-direction: column;
    padding: 15px;
    width: 250px;
    margin-right: 10px;
    padding-top: 20px;
`;

const OpportunityParent = styled.div`
    overflow: hidden;
    position: relative;
    height: 150px;
`;

const OpportunityName = styled.h4`
    font-size: 14px;
    font-weight: 800;
    margin:0px;
`;

const OpportunityNameWrapper = styled.div`
    display:flex;
    align-items: flex-end;
    height: 45px;
`;

const OpportunityDetail = styled.p`
    font-size: 10px;
    margin-bottom: 5px;
    margin-top: 3px;
    opacity: 0.5;
`;

const OpportunityTag = styled.div`
    background-color: #f5f5f5;
    padding: 5px 5px;
    border-radius: 5px;
    margin-right: 5px;
    color: rgba(0,0,0,0.5);
    font-size: 10px;
`;

const Row = styled.div`
    display: flex;
    align-items: center;
`;

const SeeAll = styled.div`
    font-size: 12px;
    cursor: pointer;
    ${INNER_MARGIN}
`

function Opportunity({ position, company, location, isPaid, description, isRemote, setModalData, isCounselor, toggleModal, id }) {

    const history = useHistory();

    const handleModal = () => {
        setModalData({
            title: position,
            description: description,
            stateName: location.split(',')[1],
            location: location.split(',')[0],
            performedAt: isRemote ? 'Remote' : 'On site',
            employerName: company
        });
        toggleModal()
    };

    const handleSeparatePage = () => {
        history.push(`/parent/job/${id}`);
    }

    const onClickHandler = () => {
        if (isCounselor) {
            handleModal();
        } else {
            handleSeparatePage();
        }
    }
    

    return <OpportunityWrapper onClick={onClickHandler}>
        <OpportunityNameWrapper>
            <OpportunityName>{position}</OpportunityName>
        </OpportunityNameWrapper>
        <OpportunityDetail>{company} - {location}</OpportunityDetail>
        <Row style={{ marginTop: 5 }}>
            {isPaid && <OpportunityTag>Paid</OpportunityTag>}
            <OpportunityTag>{isRemote ? 'Remote' : 'On Site'}</OpportunityTag>
        </Row>
    </OpportunityWrapper>

}


function OpportunityList({ type, disableSeeAll, name, opportunities, isCounselor }) {

    const history = useHistory();
    const { id } = useParams();
    const [modalShow, setModalShow] = useState(false);
    const [modalData, setModalData] = useState({});

    const suffix_text = opportunities.length === 1 ? 'an opportunity' : 'some options'

    const description = type === ACTIVITY_TYPES.INTERESTED ?
        'showed interest in ' : ACTIVITY_TYPES.FINISHED ?
        'completed ': 'saved ';

    const onClickSeeAll = () =>  history.push(
        `${type === ACTIVITY_TYPES.INTERESTED 
            ? '/parent/child/interested-in/' 
            : '/parent/child/saved/'}${id}`
    );

    return <div>
        <StudentOpportunityModal
            modalShow={modalShow}
            modalData={modalData}
            toggleModal={() => setModalShow((v) => !v)}
            />
        <Row style={{ justifyContent: 'space-between' }} >
            <ActivityDescription>{name} {description} {suffix_text}</ActivityDescription>
            {!disableSeeAll && <SeeAll onClick={onClickSeeAll}>See all &gt;</SeeAll>}
        </Row>
        <OpportunityParent>
            <OpportunityListWrapper>
                {opportunities.map((o, i) => <Opportunity {...o} key={i} 
                isCounselor={isCounselor}
                setModalData={setModalData}
                toggleModal={() => setModalShow(true)}/> )}
            </OpportunityListWrapper>
        </OpportunityParent>
    </div>
}

const mapStateToProps = (state) => {

    return {
        isCounselor: state.user.userType === 'COUNSELOR' ||  state.user.userType === 'SUPER_ADMIN'
    }

}


export default connect(mapStateToProps)(OpportunityList)