import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark as fasBookmark } from '@fortawesome/free-solid-svg-icons';
import { faBookmark as farBookmark } from '@fortawesome/free-regular-svg-icons';
import { API } from '../settings';

const ButtonWrapper = styled.div`
  width: 100%;
  position: absolute;
  bottom: 1rem;
  margin-left: -25px;
  padding: 0 25px;
`;

const Button = styled.button`
  width: 100%;
  height: 55px;
  border-radius: 12px;
  color: #ffffff;
  background-color: #1a1a1a;
  border: none;
  margin-top: auto;
  font-weight: bold;
`;

const ButtonSaved = styled(Button)`
  background-color: #ffc34e;
`;

const ButtonCopy = styled.span`
  display: inline-block;
  padding-left: 0.75rem;
`;

const SubcategoryPanelSaveButton = (props) => {
  const [saveButtonTriggered, setSaveButtonTriggered] = useState(false);
  const [saved, setSaved] = useState(false);
  const { subcategoryId, user } = props;

  // TODO: revisit
  useEffect(() => {
    const { isSaved } = props;

    if (isSaved && !saveButtonTriggered) {
      setSaved(true);
    } else if (saveButtonTriggered && !isSaved) {
      setSaved(true);
    } else {
      setSaved(false);
    }
  });

  const toggleSave = () => {
    if (!saveButtonTriggered) {
      setSaveButtonTriggered(true);
    } else {
      setSaveButtonTriggered(false);
    }

    if (saved) {
      API.delete(`/students/${user.uuid}/subcategories/saved/${subcategoryId}`)
        .then(function (response) {})
        .catch(function (error) {
          console.log(error);
        });
    } else {
      API.post(`/students/subcategories`, {
        subcategoryId,
        uuid: user.uuid,
      })
        .then(function (response) {})
        .catch(function (error) {
          console.log(error);
        });
    }

    setSaved(!saved);
  };

  const renderButton = () => {
    if (saved) {
      return (
        <ButtonSaved
          onClick={() => {
            toggleSave();
          }}
        >
          <FontAwesomeIcon icon={fasBookmark} size="1x" />
          <ButtonCopy>Saved</ButtonCopy>
        </ButtonSaved>
      );
    }

    return (
      <Button
        onClick={() => {
          toggleSave();
        }}
      >
        <FontAwesomeIcon icon={farBookmark} size="1x" />
        <ButtonCopy>Save</ButtonCopy>
      </Button>
    );
  };

  return <ButtonWrapper>{renderButton()}</ButtonWrapper>;
};

export default SubcategoryPanelSaveButton;

SubcategoryPanelSaveButton.propTypes = {
  user: PropTypes.object,
  subcategoryId: PropTypes.number,
};
