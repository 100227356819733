import React, { Component } from 'react';
import { Col, Form as BootstrapForm, Modal, Row } from 'react-bootstrap';
import { API } from '../../../settings';
import Loader from '../../common/Loader';
import { toast } from 'react-toastify';
import {
  Add20Regular as AddIcon,
  Dismiss20Regular as DismissIcon,
  Edit20Regular as EditIcon,
} from '@fluentui/react-icons';
import * as Yup from 'yup';
import { Formik, useField } from 'formik';
import styled from 'styled-components';
import ButtonLn from '../../common/Button';
import States from '../../common/Selects/States';
import OpportunityCategory from '../../common/Selects/OpportunityCategory';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

const StyledLabel = styled.label`
  margin-top: 1rem;
  font-size: 14px;
`;

const StyledInput = styled.input`
  &.error {
    border: 1px solid red;
  }
`;

const StyledErrorMessage = styled.div`
  font-size: 14px;
  color: red;
  width: 400px;
  margin-top: 0.25rem;
`;

const MyTextInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <>
      <StyledLabel htmlFor={props.id || props.name} className="form-label">
        {label}
      </StyledLabel>
      <StyledInput
        className={`text-input form-control ${
          meta.touched && meta.error ? 'error' : null
        }`}
        {...field}
        {...props}
      />
      {meta.touched && meta.error ? (
        <StyledErrorMessage className="error">{meta.error}</StyledErrorMessage>
      ) : null}
    </>
  );
};

const FormItemLn = ({ label, children, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <>
      <StyledLabel htmlFor={props.id || props.name} className="form-label">
        {label}
      </StyledLabel>
      {/* <StyledInput
                className={`text-input form-control ${meta.touched && meta.error ? 'error' : null
                    }`}
                {...field}
                {...props}
            /> */}
      {children}
      {meta.touched && meta.error ? (
        <StyledErrorMessage className="error">{meta.error}</StyledErrorMessage>
      ) : null}
    </>
  );
};

class AddNewEmployer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      loading: false,
      firstName: '',
      lastName: '',
      email: '',
      url: '',
      occupation: '',
      address: '',
      address1: '',
      address2: '',
      name: '',
      stateId: -1,
      clusterId: -1,
      city: '',
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    if (this.props.data && Object.keys(this.props.data).length) {
      this.setDataFromProps();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.data &&
      prevProps.data !== this.props.data &&
      Object.keys(this.props.data).length
    ) {
      this.setDataFromProps();
    }
  }

  toggleModal = () => {
    this.setState((state) => ({ modal: !state.modal }));
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSelect = (key, value) => this.setState({ [key]: value });

  async handleSubmit(
    values,
    { resetForm, setSubmitting, setFieldError, isValid }
  ) {
    setSubmitting(true);
    this.setState({ loading: true });
    const {
      firstName,
      lastName,
      email,
      url,
      address,
      name,
      occupation,
      stateId,
      clusterId,
      address1,
      address2,
      city,
    } = this.state;

    API({
      method: 'POST',
      url: `/employers`,
      data: {
        firstName,
        lastName,
        email,
        url,
        address,
        name,
        occupation,
        stateId,
        clusterId,
        address1,
        address2,
        city,
      },
    })
      .then((res) => {
        if (this.props.opportunityAdded) {
          this.props.opportunityAdded(res.data);
        }
        this.setState({
          firstName: '',
          lastName: '',
          email: '',
          url: '',
          address: '',
          name: '',
          occupation: '',
          stateId: '',
          clusterId: '',
          address1: '',
          address2: '',
          city: '',
          loading: false,
          modal: false,
        });
        toast.success(`Employer added`);
        resetForm();
        this.props.onFinish();
      })
      .catch((err) => {
        console.log(err);
        toast.error("Couldn't add employer");
      })
      .finally(() => {
        this.setState({ loading: false });
        setSubmitting(false);
      });

    this.setState({ formIsSubmitting: false });
  }

  render() {
    const {
      modal,
      loading,
      firstName,
      lastName,
      email,
      url,
      address,
      name,
      occupation,
      stateId,
      clusterId,
      address1,
      address2,
      city,
    } = this.state;
    const { data } = this.props;
    const validationSchema = Yup.object({
      firstName: Yup.string().required('Required'),
      lastName: Yup.string().required('Required'),
      email: Yup.string().email().required('Required'),
      url: Yup.string()
        .matches(
          /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
          'Enter correct url!'
        )
        .required('Required'),
      address: Yup.string().required('Required'),
      name: Yup.string().required('Required'),
      occupation: Yup.string().required('Required'),
      stateId: Yup.string()
        .matches(/^[0-9]+$/, 'Required')
        .required('Required'),
      clusterId: Yup.string()
        .matches(/^[0-9]+$/, 'Required')
        .required('Required'),
      city: Yup.string().required('Required'),
    });

    return (
      <>
        {data ? (
          <button onClick={this.toggleModal} className="ln-btn">
            <EditIcon className="ln-icon" />
            <span>Edit</span>
          </button>
        ) : (
          <button onClick={this.toggleModal} className="ln-btn primary">
            <AddIcon className="ln-icon" />
            <span>Add Employer</span>
          </button>
        )}
        <Modal
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={modal}
          onHide={this.toggleModal}
        >
          <Loader active={loading} dimmer={true} />
          <Formik
            enableReinitialize
            validationSchema={validationSchema}
            initialValues={{
              firstName,
              lastName,
              email,
              url,
              address,
              name,
              occupation,
              stateId,
              clusterId,
              address1,
              address2,
              city,
            }}
            onSubmit={this.handleSubmit}
            validateOnMount
          >
            {(props) => (
              <>
                <Modal.Header>
                  <Modal.Title id="contained-modal-title-vcenter">
                    {data ? 'Editing Employer' : 'Add New Employer'}
                  </Modal.Title>
                  <button
                    onClick={this.toggleModal}
                    className="ln-btn ln-icon-btn default"
                  >
                    <DismissIcon className="ln-icon" />
                  </button>
                </Modal.Header>
                <Modal.Body style={{ maxHeight: '70vh', overflowX: 'hidden' }}>
                  <div>
                    <Row>
                      <Col lg={12} xs={12}>
                        <BootstrapForm.Group controlId="studentEmail">
                          <MyTextInput
                            label="Employer Name"
                            name="name"
                            value={name}
                            onChange={this.onChange}
                            placeholder=""
                          />
                        </BootstrapForm.Group>
                      </Col>
                      <Col lg={6} xs={6}>
                        <BootstrapForm.Group controlId="firstName">
                          <MyTextInput
                            label="First Name"
                            name="firstName"
                            value={firstName}
                            onChange={this.onChange}
                            placeholder=""
                          />
                        </BootstrapForm.Group>
                      </Col>
                      <Col lg={6} xs={6}>
                        <BootstrapForm.Group controlId="lastName">
                          <MyTextInput
                            label="Last Name"
                            name="lastName"
                            value={lastName}
                            onChange={this.onChange}
                            placeholder=""
                          />
                        </BootstrapForm.Group>
                      </Col>
                      <Col lg={6} xs={6}>
                        <BootstrapForm.Group controlId="occupation">
                          <MyTextInput
                            label="Occupation"
                            name="occupation"
                            value={occupation}
                            onChange={this.onChange}
                            placeholder=""
                          />
                        </BootstrapForm.Group>
                      </Col>
                      <Col lg={6} xs={6}>
                        <BootstrapForm.Group controlId="email">
                          <MyTextInput
                            label="Email Address"
                            name="email"
                            value={email}
                            onChange={this.onChange}
                            placeholder=""
                          />
                        </BootstrapForm.Group>
                      </Col>
                      <Col lg={6} xs={6}>
                        <BootstrapForm.Group controlId="url">
                          <MyTextInput
                            label="Website URL"
                            name="url"
                            value={url}
                            onChange={this.onChange}
                            placeholder=""
                          />
                        </BootstrapForm.Group>
                      </Col>
                      <Col lg={6} xs={6}>
                        <BootstrapForm.Group controlId="clusterId">
                          <FormItemLn
                            label="Category"
                            name="clusterId"
                            value={clusterId}
                          >
                            <OpportunityCategory
                              name="clusterId"
                              value={clusterId}
                              onChange={this.onChange}
                              placeholder=""
                            />
                          </FormItemLn>
                        </BootstrapForm.Group>
                      </Col>
                      <Col lg={6} xs={6}>
                        <FormItemLn
                          label="State"
                          name="stateId"
                          value={stateId}
                        >
                          <States
                            name="stateId"
                            value={stateId}
                            onChange={this.onChange}
                            placeholder=""
                          />
                        </FormItemLn>
                        {/*<BootstrapForm.Group controlId="stateId">*/}
                        {/*  <BootstrapForm.Label>State</BootstrapForm.Label>*/}
                        {/*</BootstrapForm.Group>*/}
                      </Col>
                      <Col lg={6} xs={6}>
                        <BootstrapForm.Group controlId="city">
                          <MyTextInput
                            label="City"
                            name="city"
                            value={city}
                            onChange={this.onChange}
                            placeholder=""
                          />
                        </BootstrapForm.Group>
                      </Col>

                      <Col lg={12} xs={12}>
                        <BootstrapForm.Group controlId="address">
                          <MyTextInput
                            label="Address"
                            name="address"
                            value={address}
                            onChange={this.onChange}
                            placeholder=""
                          />
                        </BootstrapForm.Group>
                      </Col>
                      <Col lg={6} xs={6}>
                        <BootstrapForm.Group controlId="address">
                          <MyTextInput
                            label="Address 1"
                            name="address1"
                            value={address1}
                            onChange={this.onChange}
                            placeholder=""
                          />
                        </BootstrapForm.Group>
                      </Col>
                      <Col lg={6} xs={6}>
                        <BootstrapForm.Group controlId="address">
                          <MyTextInput
                            label="Address 2"
                            name="address2"
                            value={address2}
                            onChange={this.onChange}
                            placeholder=""
                          />
                        </BootstrapForm.Group>
                      </Col>
                    </Row>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <ButtonLn
                    onClick={() => props.submitForm()}
                    loading={props.isSubmitting}
                    type="submit"
                    content="Add Employer"
                    className="ln-btn primary"
                    style={{ float: 'right' }}
                  />
                </Modal.Footer>
              </>
            )}
          </Formik>
        </Modal>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps, null)(withRouter(AddNewEmployer));
