import {
  OPTIONS_GET_OPPORTUNITY_CATEGORIES,
  // OPTIONS_GET_OPPORTUNITY_SUBCATEGORIES,
  OPTIONS_GET_STATES,
  // OPTIONS_GET_EMPLOYMENT_TYPES,
} from '../constants/action-types';
// import axios from 'axios';
import { API } from '../../settings';

export function getOpportunityCategories() {
  return function (dispatch, getState) {
    const { options } = getState();
    if (getState(!options.opportunityCategories.APIdata[0])) {
      API.get(`/clusters/`)
        .then((res) => {
          dispatch({
            type: OPTIONS_GET_OPPORTUNITY_CATEGORIES,
            payload: res.data,
          });
        })
        .catch((err) => {});
    }
  };
}

export function getOpportunitySubCategories() {
  // function optionsList(options) {
  //   return options.map(function (option) {
  //     var newObj = {};
  //     newObj['key'] = option.id;
  //     newObj['value'] = option.id;
  //     newObj['text'] = option.name;
  //     return newObj;
  //   });
  // }
  return function (dispatch, getState) {
    // TODO: fetch OpportunityCategories
  };
}

export function getStates() {
  return async function (dispatch, getState) {
    if (!getState().options.states.APIdata[0]) {
      try {
        const res = await API.get(`/location/states/`);
        dispatch({ type: OPTIONS_GET_STATES, payload: res.data });
      } catch (err) {}
    }
  };
}
