import React from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Form } from 'react-bootstrap';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LaunchNowHeader from '../components/shared/LaunchNowHeader';
import AuthService from '../services/authService';
import { Redirect } from 'react-router-dom';
import { clearToast, login } from '../features/users/userSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const PageContainer = styled(Container)`
  min-height: 100vh;
  min-height: -webkit-fill-available;
  @media (min-width: 768px) {
    min-width: 100%;
    max-width: 100%;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const Box = styled.div`
  margin-top: 65px;
  width: 330px;
`;

const Welcome = styled.h2`
  margin-top: 40px;
  margin-bottom: 24px;
  font-size: 52px;
  line-height: 62px;
`;

const Button = styled.button`
  width: 100%;
  height: 55px;
  border-radius: 12px;
  color: #ffffff;
  background-color: #1a1a1a;
  border: none;
  margin-top: 40px;
`;

const ForgotPassword = styled(Link)`
  margin: 1.25rem 0;
  text-align: center;
  display: inline-block;
  width: 100%;
`;

const Label = styled(Form.Label)`
  font-size: 14px;
  color: #1a1a1a;
`;

const FormControl = styled(Form.Control)`
  border: 1px solid #ebebeb;
  height: 2.75rem;
  ::placeholder {
    color: #afafaf;
    opacity: 1;
    font-weight: 300;
  }
`;

class SignIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      message: '',
      loading: false,
      toastStatus: 'none',
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { clearToast, user } = this.props;

    if (user && user.toastStatus && user.toastMessage) {
      const { toastMessage, toastStatus } = user;

      if (toastStatus === 'success') {
        toast.success(toastMessage);
      }

      clearToast();
    }
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    const { login } = this.props;

    this.setState({
      message: '',
      loading: true,
    });

    AuthService.login(this.state.email, this.state.password).then(
      (userData) => {
        this.setState({ loading: false });
        login(userData);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        toast.error(resMessage, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        this.setState({
          loading: false,
          message: resMessage,
        });
      }
    );
  }

  render() {
    const { user } = this.props;

    if (user.isLoggedIn) {
      return <Redirect to="/dashboard" />;
    } else {
      return (
        <PageContainer className="h-100">
          {/* <FlashError
            flashMessage={message}
            handleFlashClick={this.handleFlashClick}
          /> */}
          <Row className="h-100">
            <Col lg={6}>
              <Wrapper>
                <LaunchNowHeader />
                <Box>
                  <Link to="/" className="ln-btn default">
                    <FontAwesomeIcon className="ln-icon" icon={faArrowLeft} />
                    <span>Back</span>
                  </Link>
                  <Welcome>
                    Welcome
                    <br />
                    Back
                  </Welcome>
                  <Form onSubmit={this.handleSubmit}>
                    <Form.Group controlId="formBasicEmail">
                      <Label>Email</Label>
                      <FormControl
                        type="email"
                        name="email"
                        placeholder="Email address"
                        onChange={this.handleInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="formBasicPassword">
                      <Label>Password</Label>
                      <FormControl
                        type="password"
                        name="password"
                        placeholder="Password"
                        onChange={this.handleInputChange}
                      />
                    </Form.Group>
                    <Button variant="primary" type="submit">
                      Sign In
                    </Button>
                  </Form>
                  <ForgotPassword to="/forgot-password">
                    Forgot Password?
                  </ForgotPassword>
                </Box>
              </Wrapper>
            </Col>
            <Col lg={6} className="d-none d-lg-block landing-right-col"></Col>
          </Row>
        </PageContainer>
      );
    }
  }
}

const mapStateToProps = (state) => ({ user: state.user });
const mapDispatchToProps = { clearToast, login };

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
