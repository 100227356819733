import React from 'react';
import styled from 'styled-components';


const CurrentStep = styled.div`
    background-color: #45d191;
    height: 8px;
    width: 30px;
    border-radius: 100px;
    margin-right: 10px;
`;

const Step = styled.div`
    height: 8px;
    width: 8px;
    background-color: black;
    margin-right: 10px;
    border-radius: 100px;
`;

const Wrapper = styled.div`
    display: flex;
`

export default function StepIndicator({ totalSteps, currentStep, ...props }) {

    return <Wrapper {...props} >
        {Array.from(Array(totalSteps)).map((_, i) => 
            i === currentStep - 1 ? <CurrentStep /> : <Step />
        )}
    </Wrapper>

}