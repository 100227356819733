import { COUNSELOR_JOBS_GET, COUNSELOR_SET_LOADING } from '../../constants/action-types';

const initialState = {
  loading: true,
  limit: 20,
  students: [],
  jobs: [],
  loadingStudent: []
};

function counselorJobs(state = initialState, action) {
  switch (action.type) {
    case COUNSELOR_JOBS_GET:
      return { ...state, ...action.payload };
    case COUNSELOR_SET_LOADING:
      let loadingStudent;
      if (state.loadingStudent.includes(action.payload.studentId)) {
        loadingStudent = state.loadingStudent.filter((s) => {
          return s !== action.payload.studentId
        })
      } else {
        loadingStudent = [...state.loadingStudent, action.payload.studentId];
      }

      console.log('prop', loadingStudent)
      return { ...state, loadingStudent }
    default:
      break;
  }
  return state;
}

export default counselorJobs;
