import React from 'react';
import styled from 'styled-components';

const GreetingBlock = styled.div`
  font-size: 30px;
  font-weight: 600;
`;

const Title = styled.h5`
  font-weight: 500;
`;

const Greeting = (props) => (
  <div>
    <GreetingBlock>Hi {props.name},</GreetingBlock>
    <Title>Your great opportunity awaits</Title>
  </div>
);

export default Greeting;
