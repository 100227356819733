import React from 'react';
import { Container, Row, Col, Table } from 'react-bootstrap';
import styled from 'styled-components';
import dotenv from 'dotenv';

import { API } from '../settings';

dotenv.config();

const PageHeader = styled.h3`
  margin-top: 6px;
`;

const ClusterPageHeader = styled(PageHeader)`
  margin-bottom: 25px;
`;

const SectionTitle = styled.h5`
  padding: 10px 0;
  font-family: Nunito;
`;

const SectionTitleSmaller = styled.h6`
  padding: 10px 0;
  font-family: Nunito;
`;

const ClusterTable = styled(Table)`
  margin-bottom: 12px;
  td:first-child {
    width: 25%;
  }
`;

const SubcategoryTable = styled(Table)`
  margin-bottom: 12px;
  td:first-child {
    width: 25%;
  }
`;

const SkillsTable = styled(Table)`
  margin-bottom: 40px;
`;

export default class SuperAdminClusters extends React.Component {
  constructor(props) {
    super(props);

    this.state = { clusters: [], loading: false };
  }

  componentDidMount() {
    // const assessmentKey = 'ASSESSMENT_1';

    API.get(`/clusters/`).then((response) => {
      const { clusters } = response.data;

      console.log(clusters);

      this.setState({
        clusters: clusters,
      });

      return response.data;
    });
  }

  render() {
    const clusters = () => {
      const { clusters } = this.state;

      const clustersToDisplay = clusters.map((cluster, index) => {
        return (
          <div>
            <SectionTitle>
              Cluster {cluster.clusterNumber} - {cluster.name}
            </SectionTitle>
            <ClusterTable striped bordered hover>
              {displayClusterData(cluster)}
            </ClusterTable>

            <SectionTitleSmaller>Subcategories</SectionTitleSmaller>
            <SubcategoryTable striped bordered hover>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Description</th>
                </tr>
              </thead>
              {displaySubcategories(cluster)}
            </SubcategoryTable>

            <SectionTitleSmaller>Skills</SectionTitleSmaller>
            <SkillsTable striped bordered hover>
              <tbody>{displaySkills(cluster)}</tbody>
            </SkillsTable>
          </div>
        );
      });

      return <div>{clustersToDisplay}</div>;
    };

    const displayClusterData = (cluster) => {
      return (
        <tbody>
          <tr>
            <td>Name</td>
            <td>{cluster.name}</td>
          </tr>
          <tr>
            <td>Number</td>
            <td>{cluster.clusterNumber}</td>
          </tr>
          <tr>
            <td>Key</td>
            <td>
              <code>{cluster.key}</code>
            </td>
          </tr>
          <tr>
            <td>Holland Code</td>
            <td>{cluster.hollandCode}</td>
          </tr>
        </tbody>
      );
    };

    const displaySubcategories = (cluster) => {
      return cluster.subcategories.map((subcategory) => {
        return (
          <tbody>
            <tr>
              <td>{subcategory.name}</td>
              <td>{subcategory.description}</td>
            </tr>
          </tbody>
        );
      });
    };

    const displaySkills = (cluster) => {
      console.log('DISPLAYING SKILLS');
      console.log(cluster);
      const skillsToDisplay = cluster.skills.map((skill) => {
        console.log(skill);
        return (
          <tr>
            <td>
              <code>{skill.name}</code>
            </td>
          </tr>
        );
      });

      return skillsToDisplay;
    };

    return (
      <Container className="p-0 h-100 ">
        <Row>
          <Col xs={12}>
            <ClusterPageHeader>Clusters</ClusterPageHeader>
            {clusters()}
          </Col>
        </Row>
      </Container>
    );
  }
}
