import React from 'react';
import styled from 'styled-components';

import StudentProfileAssessmentTracker from './StudentProfileAssessmentTracker';
import StudentProfileAssessmentTrackerDone from './StudentProfileAssessmentTrackerDone';
import StudentProfileRankedClusters from './StudentProfileRankedClusters';
import StudentProfileSkillsCloud from './StudentProfileSkillsCloud';
import { API } from '../settings';
import { connect } from 'react-redux';

const AssessmentTitle = styled.h6`
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.25rem;
  padding: 0;
  margin: 2rem 0 0;
  text-align: left;
`;

const AssessmentCompleteCopy = styled.p`
  font-size: 0.875rem;
  text-align: left;
`;

const Button = styled.button`
  width: 100%;
  height: 55px;
  border-radius: 12px;
  border: 1px solid #afafaf;
  color: #afafaf;
  font-weight: bold;
  background: none;
  border: 2px solid #afafaf;
  margin-top: auto;
`;

class StudentProfileAssessmentSection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      rankedClusters: [],
      skills: [],
    };
  }

  componentDidMount() {
    const { user } = this.props;
    const { uuid } = user;

    // API.get(`/students/${uuid}/profile`)
    //   .then((response) => {
    //     const { rankedClusters } = response.data;
    //     const skills = [];
    //
    //     rankedClusters.forEach((cluster) => {
    //       cluster.skills.forEach((skill) => {
    //         if (!skills.includes(skill.name)) {
    //           skills.push(skill.name);
    //         }
    //       });
    //     });
    //
    //     this.setState({
    //       loading: false,
    //       rankedClusters,
    //       skills,
    //     });
    //   })
    //   .catch((error) => {
    //     this.setState({
    //       loading: false,
    //     });
    //     // TODO: handle error
    //     console.log(error);
    //   });
  }

  render() {
    const {
      retakeAssessment,
      percentDone,
      user,
      loading,
      rankedClusters,
      skills,
    } = this.props;

    if (loading) {
      return null;
    }

    if (percentDone === 100) {
      return (
        <>
          <AssessmentTitle>Assessment completed</AssessmentTitle>
          <StudentProfileAssessmentTrackerDone percentDone={percentDone} />
          <AssessmentCompleteCopy>
            Based on your answers here are the three categories that match your
            skills and interests
          </AssessmentCompleteCopy>
          <StudentProfileRankedClusters
            loading={loading}
            rankedClusters={rankedClusters}
            user={user}
          />
          <Button
            onClick={() => {
              if (
                window.confirm(
                  'Are you sure you want to retake your assessment? Your current results will be deleted.'
                )
              )
                retakeAssessment(user.uuid);
            }}
          >
            Retake Assessment
          </Button>
          <StudentProfileSkillsCloud skills={skills} />
        </>
      );
    }

    return (
      <>
        <AssessmentTitle>My Assessment</AssessmentTitle>
        <StudentProfileAssessmentTracker
          user={user}
          percentDone={percentDone}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  ...state.student.studentProfile,
});

export default connect(mapStateToProps)(StudentProfileAssessmentSection);
