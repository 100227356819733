import React from 'react';
import styled from 'styled-components';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import {
  ArrowSortDown20Regular as ArrowDown,
  ArrowSortUp20Regular as ArrowUp,
  Desktop24Regular as DesktopIcon,
  Person24Regular as PersonIcon,
  Prohibited24Regular as Prohibited,
} from '@fluentui/react-icons';
import CircularProgress from '../../common/CircularProgress';

const Title = styled.h6`
  font-size: 0.875rem;
  text-transform: uppercase;
`;

const StatsContainer = styled(Container)`
  margin-top: 1rem;
`;

const Wrapper = styled(Row)`
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.02);
  border-radius: 20px;
  background-color: #ffffff;
`;

const SectionCol = styled(Col)`
  padding: 20px;
`;

const Loader = styled(Spinner)`
  margin: 5px;
`;

export default function ParentStatus({
  totalCount,
  joined,
  notJoined,
  loading,
}) {
  return (
    <>
      <Title>Parent Status</Title>
      <StatsContainer fluid>
        <Wrapper className="mg-b">
          <SectionCol lg={4}>
            <CircleProgressCard
              loading={loading}
              title="Total"
              value={totalCount ? totalCount : '0'}
              color={'#3dd598'}
              icon={<PersonIcon />}
            />
          </SectionCol>
          <SectionCol lg={4}>
            <CircleProgressCard
              loading={loading}
              title="Joined Platform"
              percent={joined ? joined.percent : ''}
              color={'#ffc34e'}
              icon={<DesktopIcon />}
            />
          </SectionCol>
          <SectionCol lg={4}>
            <CircleProgressCard
              loading={loading}
              title="Didn't Join"
              percent={notJoined ? notJoined.percent : ''}
              color={'#fc5a5a'}
              icon={<Prohibited />}
            />
          </SectionCol>
        </Wrapper>
      </StatsContainer>
    </>
  );
}

export function CircleProgressCard({
  title,
  percent,
  icon,
  color,
  value,
  loading,
}) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress
        strokeWidth="5"
        sqSize="70"
        percentage={value ? 100 : percent}
        icon={icon}
        color={color}
      />
      {loading ? (
        <Loader animation="grow" size="sm" />
      ) : (
        <h4 style={{ marginTop: '10px', marginBottom: '0' }}>
          {value ? value : `${percent >= 0 ? percent : ''}%`}
        </h4>
      )}
      <h6 style={{ color: '#858585', fontSize: '12px' }}>{title}</h6>
    </div>
  );
}

export function Card(props) {
  const { title, raise, percent } = props;
  return (
    <>
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          borderRadius: '20px',
        }}
      >
        <h6 style={{ color: '#858585' }}>{title}</h6>
        <div className="horizontal-flex align-center">
          <h3 style={{ marginRight: '2rem', marginBottom: 0 }}>9</h3>
          <h6 style={{ color: raise ? '#3dd598' : '#fc5a5a', marginBottom: 0 }}>
            {percent}% {raise ? <ArrowUp /> : <ArrowDown />}
          </h6>
        </div>
      </div>
    </>
  );
}
