import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import StudentOpportunity from './StudentOpportunity';

const Panel = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100%;
  border-radius: 20px;
  background: #ffffff;
  align-items: stretch;
`;

const OpportunitiesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 0.5rem;
`;

const Title = styled.h6`
  font-size: 1rem;
`;

const Count = styled.div`
  font-size: 1rem;
  font-weight: bold;
  color: #858585;
`;

const StudentOpportunitiesList = (props) => {
  const { name, opportunities, toggleModal } = props;

  const displayOpportunities = (opportunities) => {
    if (opportunities) {
      return opportunities.map((opportunity, i) => {
        return (
          <StudentOpportunity
            key={`${opportunity.name}-${i}`}
            title={opportunity.title}
            performedAt={opportunity.performedAt}
            location={opportunity.location}
            stateName={opportunity.stateName}
            toggleModal={() => {
              toggleModal(opportunity.id);
            }}
            id={opportunity.id}
          />
        );
      });
    }

    return null;
  };

  return (
    <Panel>
      <TitleWrapper>
        <Title>{name}</Title>
        <Count>{opportunities.length}</Count>
      </TitleWrapper>
      <OpportunitiesWrapper>
        {displayOpportunities(opportunities)}
      </OpportunitiesWrapper>
    </Panel>
  );
};

export default StudentOpportunitiesList;

StudentOpportunitiesList.propTypes = {
  name: PropTypes.string.isRequired,
  opportunities: PropTypes.array.isRequired,
};
