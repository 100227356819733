import React from 'react';
import styled from 'styled-components';
import StudentNav from '../StudentNav';

const MAX_WIDTH = 576;

const Wrapper = styled.div`
  display: flex;
  position: relative;
  min-height: 100vh;
  margin: 0 auto;
  max-width: ${MAX_WIDTH}px;
  overflow-y: scroll;
  overflow-x: scroll;
  width: 100%;
  &::-webkit-scrollbar {
    display: none;
  } /* Chrome, Safari and Opera */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  @media (min-width: 600px) {
    box-shadow: 1px 1px 10px rgba(0,0,0,0.5);
  }
`;

const PrimaryLayout = ({ children, showNav, user }) => {
  const renderNav = () => {
    if (user !== undefined && user.userType !== 'STUDENT') {
      return null;
    }

    if (showNav) {
      return <StudentNav />;
    }

    return null;
  };

  return (
    <Wrapper>
      {children}
      {renderNav()}
    </Wrapper>
  );
};

export default PrimaryLayout;
