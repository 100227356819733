import {
  EMPLOYER_DASHBOARD_GET,
  EMPLOYER_OPPORTUNITIES_GET,
} from '../constants/action-types';
import { API } from '../../settings';

export function getOpportunities(
  page,
  employerId,
  searchValue = '',
  sortBy = 'createdAt',
  sort = 'desc'
) {
  return function (dispatch, getState) {
    const {
      user: {
        userType,
        userTypeProfile: {
          id
        },
      },
      employer: {
        opportunities: { limit },
      },
    } = getState();
    dispatch({
      type: EMPLOYER_OPPORTUNITIES_GET,
      payload: {
        loading: true,
        page: page,
        sortBy: sortBy,
        sort: sort,
        searchValue: searchValue,
      },
    });

    API.get(
      `/opportunities/?employerId=${userType === 'EMPLOYER' ? id : employerId}&page=${page}&limit=${limit}&search=${searchValue}&sortBy=${sortBy}&sort=${sort}`
    ).then((res) => {
      dispatch({
        type: EMPLOYER_OPPORTUNITIES_GET,
        payload: { loading: false, ...res.data },
      });
    });
  };
}

export function setSearchValue(value = '') {
  return function (dispatch) {
    dispatch({
      type: EMPLOYER_OPPORTUNITIES_GET,
      payload: { searchValue: value },
    });
  };
}

export function employerGetDashboard() {
  return async function (dispatch, getState) {
    const {
      user: {
        userTypeProfile: { id },
      },
    } = getState();
    dispatch({
      type: EMPLOYER_DASHBOARD_GET,
      payload: { loading: true },
    });
    const { data } = await API.get(`/employers/${id}/dashboard`);
    dispatch({
      type: EMPLOYER_DASHBOARD_GET,
      payload: { loading: false, ...data },
    });
  };
}
