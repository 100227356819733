import React from 'react';
import styled from 'styled-components';
import { ProgressBar as PBar } from 'react-bootstrap';

const ProgressContainer = styled.div`
  width: 100%;
  .progress {
    background-color: #f2f3f5;
    width: 100%;
    appearance: none;
    height: 0.75rem;
    margin-bottom: 0.75rem;
  }
  .progress-bar {
    background-color: #46d191;
  }
`;

const ProgressBarDone = (props) => {
  const { value, max } = props;

  return (
    <ProgressContainer>
      <PBar now={value} max={max} />
    </ProgressContainer>
  );
};

export default ProgressBarDone;
