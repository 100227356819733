import React from 'react';
import { Form } from 'react-bootstrap';
import { connect } from 'react-redux';

function EmploymentLocation(props) {
  return (
    <Form.Control {...props} as="select">
      {props.opportunityLocations.map((type, i) => (
        <option value={type.value}>{type.name}</option>
      ))}
    </Form.Control>
  );
}

function mapStateToProps(state) {
  return {
    opportunityLocations: state.options.opportunityLocations.APIdata,
  };
}

export default connect(mapStateToProps, null)(EmploymentLocation);
