import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { API } from '../../settings';
import { Column, INNER_MARGIN, Row } from './shared/default-styles';
import Loading from './shared/loading';
import MainHeader from './shared/main-header';
import TabBar from './shared/tab-bar';
import UIWrapper from './shared/ui-wrapper';

const Headline = styled.h3`
    margin: 0px;
    max-width: 80%;
    text-align: center;
`;

const Details = styled.div`
    font-size: 12px;
    opacity: 0.5;
`;

const Description = styled.p`
    margin: 30px 40px;
    margin-top: 20px;
`;

const DescriptionHeader = styled.p`
    margin: 0px 40px;
    font-weight: 800;
`;

const Tag = styled.div`
    background-color: #f5f5f5;
    padding: 5px 5px;
    border-radius: 5px;
    margin-right: 5px;
    color: rgba(0,0,0,0.5);
    font-size: 12px;
`;

const Visit = styled.div`
    cursor: pointer;
    border-radius: 10px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
    margin: 30px 40px;
`;

const VisitText = styled.a`
`;

export default function Job() {

    const { id } = useParams();

    const [tab, setTab] = useState('Description');
    const [job, setJob] = useState({});

    const getJob = async () => {
        const result = await API.get('/opportunities/' + id);
        setJob(result.data)
    }

    useEffect(() => {
        getJob();
    }, [])

    if (!Object.keys(job).length) return <UIWrapper>
        <Loading />
    </UIWrapper>

    
    return <UIWrapper>
        <MainHeader title={job?.Employer?.name} />
        <Column style={{ alignItems: 'center', marginBottom: 45 }}>
            <Headline style={{ marginTop: 50 }}>{ job.title }</Headline>
            <Details style={{ marginTop: 10 }}>{ job?.Employer?.name } - { job.location }, { job.State?.code} - { job.employmentType }</Details>
            <Row style={{ marginTop: 10 }}>
                {job.employmentType === 'Job Shadow' && <Tag>Unpaid</Tag>}
                {job.performedAt !== 'ONSITE' && <Tag>Remote</Tag>}
            </Row>
        </Column>
        <div style={{ width: '80%', marginBottom: 30, alignSelf: 'center'}}>
            <TabBar 
                optionA={'Description'} 
                optionB={'Company'} 
                tab={tab} 
                setTab={setTab} 
            />
        </div>
        {tab === 'Description' && <DescriptionHeader>About the Opportunity</DescriptionHeader>}
        {tab === 'Company' && <DescriptionHeader>About {job.Employer?.name}</DescriptionHeader>}
        {tab === 'Description' && <Description>{job.description}</Description>}
        {tab === 'Company' && <Visit><VisitText href={job?.Employer?.url} target='_blank'>Visit {job.Employer?.name}'s website</VisitText></Visit>}
    </UIWrapper>

}