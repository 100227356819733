import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 2rem 1rem 4rem;
`;

const Title = styled.div`
  font-size: 1.25rem;
  text-align: left;
`;

const Cloud = styled.div`
  padding: 1rem 0 2rem;
  margin-left: -0.5rem;
  text-align: left;
`;

const Skill = styled.div`
  display: inline-block;
  padding: 0.75rem;
  background-color: #ffffff;
  margin: 0.5rem;
  border-radius: 0.875rem;
  font-size: 0.875rem;
  line-height: 0.875rem;
`;

const StudentProfileSkillsCloud = (props) => {
  const { skills } = props;

  const displaySkillsCloud = (skills) => {
    if (skills) {
      return skills.map((skill, i) => {
        return <Skill>{skill}</Skill>;
      });
    }

    return null;
  };

  return (
    <Wrapper>
      <Title>My Skills</Title>
      <Cloud>{displaySkillsCloud(skills)}</Cloud>
    </Wrapper>
  );
};

export default StudentProfileSkillsCloud;
