import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Spinner } from 'react-bootstrap';
import styled from 'styled-components';
import JobSidebar from './JobSidebar';
import { withRouter } from 'react-router-dom';
import { getJobs } from '../../redux/actions/counselor';
import StudentJobList from '../StudentJobList';


const InnerRow = styled(Row)`
  margin-left: -15px;
  margin-right: 0px;
`;

const ContentWrapper = styled(Col)`
  margin-top: 1.5rem;
  width: 100%;
  display: flex;
  flex-direction: row;
`;

const Column = styled(Col)`
  display: flex;
  height: 90vh;
  margin-left: 30px;
`;

const Panel = styled(Row)`
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 20px;
  background: #ffffff;
  align-items: stretch;
  width: 100%;
  box-sizing: border-box;
`;


function JobDetails (props) {

  const { id } = props.match.params;
  const j = props?.jobs.find((j) => j.id == id);
  let job = j ? j : {};

  console.log(job);

  const fetchData = () => {
    const thisJob = props?.jobs.find((j) => j.id == id);
    if (!thisJob) props.getJobs();
  }

  useEffect(() => {
    fetchData()
  }, [props?.jobs])

  const filtered = (props?.students.filter((s) => (
    job?.interestedIn?.includes(s.id)
  )));

  const students = filtered.map((s) => {
    const result = Object.assign({}, s)
    if (job?.approvedByEmployer?.includes(s.id)) result.approvedByEmployer = true;
    if (job?.approvedByCounselor?.includes(s.id)) result.approvedByCounselor = true;
    if (job?.finished?.includes(s.id)) result.finished = true;
    return result;
  });

  const positionsAvailable = job?.numberOfPositions - job.matched;

  const RightColumn = () => <>
      <Column lg={9}>
      <Panel>
        <StudentJobList positionsAvailable={positionsAvailable} students={students} isActive={job.isActive} opportunityId={job.id} />
      </Panel>
      </Column>
    </>

  const renderDetails = () => {
    return (
      <>
        <JobSidebar 
          name={job?.title}
          location={job?.location}
          numberOfPositions={positionsAvailable}
          description={job?.description}
          employmentType={job?.employmentType}
          company={job?.employer?.name}
          stateId={job?.stateId}
          isActive={job?.isActive}
          url={job?.employer?.url}
        />
        <RightColumn/>
      </>
    )
    
  }

  return (
    <Container className="p-0 h-100 ">
      <InnerRow className="h-100">
          <ContentWrapper lg={12}>
            {renderDetails()}
          </ContentWrapper>
      </InnerRow>
    </Container>
  );
    
  
 
}

const mapStateToProps = (state) => ({
  students: state.counselor.jobs.students,
  jobs: state.counselor.jobs.jobs
})

const mapDispatchToProps = {
  getJobs
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(JobDetails));
