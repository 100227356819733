// import { OPTIONS_GET_OPPORTUNITY_CATEGORIES } from '../../constants/action-types';

const initialState = {
  loading: false,
  count: null,
  APIdata: [
    {
      name: 'Full-Time',
      value: 'Full-Time',
    },
    {
      name: 'Part-Time',
      value: 'Part-Time',
    },
    {
      name: 'Temporary',
      value: 'Temporary',
    },
    {
      name: 'Seasonal',
      value: 'Seasonal',
    },
    {
      name: 'Job Shadow',
      value: 'Job Shadow',
    },
    {
      name: 'Virtual Meet & Greet',
      value: 'Virtual Meet & Greet',
    },
    {
      name: 'ELO',
      value: 'ELO',
    },
  ],
};

function employmentTypesReducer(state = initialState, action) {
  // switch (action.type) {
  //     case OPTIONS_GET_OPPORTUNITY_CATEGORIES:
  //         return Object.assign({}, state, {
  //             APIdata: action.payload.clusters
  //         });
  //     default:
  //         break;
  // }
  return state;
}

export default employmentTypesReducer;
