import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';

import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import ActivateAccountPasswordForm from './ActivateAccountPasswordForm';
import LaunchNowHeader from '../components/shared/LaunchNowHeader';
import { Redirect, withRouter } from 'react-router-dom';
import { API } from '../settings';
import { login, logout, setToast } from '../features/users/userSlice';
import AuthService from '../services/authService';
import { track, ACTIVATE_ACCOUNT, identify, reset } from '../utils/mixpanel';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const Box = styled.div`
  margin-top: 65px;
  width: 330px;
`;

class ActivateAccount extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      password: '',
      message: '',
      loading: false,
      isLoggedIn: false,
      formIsSubmitting: false,
      toastMessage: '',
      toastStatus: 'success',
      userIsActive: false,
      uuid: '',
    };

    this.handleActivate = this.handleActivate.bind(this);
  }

  async componentDidMount() {
    const { logout, user, setToast } = this.props;
    const { activationToken } = this.props.match.params;

    const isActive = await AuthService.isUserActive(activationToken);
    console.log(`isActive: ${isActive}`);

    if (isActive) {
      console.log('USER ALREADY ACTIVE');
      setToast({
        toastMessage: 'User is already activated. Please log in.',
        toastStatus: 'success',
      });
      this.setState({ userIsActive: true });
    }

    this.setState({ activationToken });

    // Log out if there's a logged-in user
    if (user && user.isLoggedIn) {
      logout(user.id);
      localStorage.removeItem('_session');
    }
  }

  // TODO: move logout/login to auth service
  handleActivate(password, confirmPassword) {
    const { activationToken } = this.state;
    const { login } = this.props;

    API.post(`/users/activate`, {
      activationToken,
      password,
      confirmPassword,
    })
      .then((response) => {

        let responseData = response.data;
    
        if (responseData?.uuid) {
          identify(responseData);
          track(ACTIVATE_ACCOUNT, { 
            email: responseData?.email, 
            userType: responseData?.userType
          })
          reset();
        }     
        
        const message = responseData.message;

        if (message) {
          if (message === 'user activated') {
            responseData.toastMessage = 'User activated. Welcome!';
            responseData.toastStatus = 'success';
          }
          if (message === 'user already activated') {
            responseData.toastMessage = 'User already activated. Welcome back!';
            responseData.toastStatus = 'info';
          }
        }

        // Log user in
        this.props.history.push('/logout');

        // TODO: do we need to set _session here?
        // if (responseData.accessToken) {
        //   localStorage.setItem('_session', JSON.stringify(response.data));
        // }

        // this.setState({ userIsActive: true });
      })
      .catch((error) => {
        console.log(error);
        let errorMessage = 'Something went wrong.';

        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          errorMessage = error.response.data.message;
        }

        toast.error(errorMessage);
      });

    this.setState({ formIsSubmitting: false });
  }

  render() {
    const { formIsSubmitting, userIsActive } = this.state;

    if (userIsActive) {
      return (
        <Redirect
          to={{
            pathname: '/sign-in',
          }}
        />
      );
    } else {
      return (
        <Container fluid>
          <Row>
            <Col>
              <LaunchNowHeader />
              <Wrapper>
                <Box>
                  <p>
                    Welcome to Launch Now! Please enter a password to finish
                    creating your account...
                  </p>
                  <ActivateAccountPasswordForm
                    handleActivate={this.handleActivate}
                    formIsSubmitting={formIsSubmitting}
                  />
                </Box>
              </Wrapper>
            </Col>
          </Row>
        </Container>
      );
    }
  }
}

const mapStateToProps = (state) => ({ user: state.user });
const mapDispatchToProps = { login, logout, setToast };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ActivateAccount));
