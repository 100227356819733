import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

const NavListItem = styled.li`
  display: block;
  width: 100%;
  font-weight: 500;
  margin: 0;
  padding: 0;
`;

const NavListItemLink = styled(NavLink)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  color: #858585;
  padding: 15px 18px;
  width: 100%;
  &.selected {
    color: #ffffff;
    background-color: #292929;
  }
  &:hover {
    text-decoration: none;
    color: #ffffff;
    background-color: #292929;
  }
  .ln-icon {
    margin-right: 10px;
  }
  .nav-item-link-title {
    width: 100%;
  }
`;

export default function SidebarNavItem(props) {
  const { icon, title, meta, to } = props;
  return (
    <NavListItem>
      <NavListItemLink to={to} activeClassName="selected">
        {icon}
        <span className="nav-item-link-title">{title}</span>
        <span>{meta}</span>
      </NavListItemLink>
    </NavListItem>
  );
}
