import React from 'react';
import styled from 'styled-components';
import { Spinner } from 'react-bootstrap';

const ActivateButton = styled.button`
  color: #ffffff;
  border-radius: 12px;
  border: none;
  padding: 0.75rem 2rem;
  font-size: 1rem;
  font-weight: bold;
  background-color: #1a1a1a;
  min-width: 200px;
  margin-top: auto;
  margin-left: auto;
  margin-right: auto;
`;

const ActivateButtonSpinner = styled(Spinner)`
  height: 16px;
  width: 16px;
`;

const StudentsActivateButton = (props) => {
  const { activationLoading, handleClick } = props;
  if (activationLoading) {
    return (
      <ActivateButton>
        <ActivateButtonSpinner animation="border" variant="light" />
      </ActivateButton>
    );
  }

  return (
    <ActivateButton onClick={handleClick}>I Confirm My Consent</ActivateButton>
  );
};

export default StudentsActivateButton;
