import React from 'react';
import styled from 'styled-components';
import Avatar from 'react-avatar';
import moment from 'moment';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  background: #f1f1f1;
  border-radius: 10px;
  padding: 15px;
`;

const TopBlock = styled.div``;

const BottomBlock = styled.div``;

const CompanyName = styled.h4`
  padding-top: 10px;
`;

const MetaText = styled.h6`
  color: #707070;
  font-size: 12px;
`;

const EmployerInfo = ({className,...props}) => {
  const {
    employer: { name, createdAt, url, User },
  } = props;
  if (User) {
    return (
      <Wrapper className={className}>
        <TopBlock>
          <h6>Info</h6>
          <center>
            <Avatar
              className="profileAvatar"
              round={true}
              size={100}
              name={`${name}`}
              color="#797878"
            />
            <CompanyName>{name}</CompanyName>
            <MetaText>
              Joined {moment(createdAt).format('MMMM Do, YYYY')}
            </MetaText>
          </center>
        </TopBlock>
        <BottomBlock>
          <Description title="EMAIL" value={User.email} />
          <Description title="WEBSITE" value={url} />
          {/* <Description title="MOBILE" value="" /> */}
          <Description
            title="CONTACT"
            value={`${User.firstName} ${User.lastName}`}
          />
        </BottomBlock>
      </Wrapper>
    );
  } else {
    return null;
  }
};

export default EmployerInfo;

const DescTitle = styled.h6`
  color: #707070;
  font-size: 12px;
`;

const DescText = styled.h6`
  font-size: 12px;
`;

const Description = ({ title, value }) => {
  return (
    <div>
      <DescTitle>{title}</DescTitle>
      <DescText>{value}</DescText>
    </div>
  );
};
