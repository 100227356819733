import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import EmployerLoggedInLayout from '../components/layouts/EmployerLoggedInLayout';
import EmployerDashboard from '../components/employer/EmployerDashboard';
import EmployerSettings from '../components/employer/EmployerSettings';
import EmployerProfile from '../components/employer/settings/EmployerProfile';
import EmployerBusiness from '../components/employer/settings/EmployerBusiness';
import EmployerPassword from '../components/employer/settings/EmployerPassword';
import EmployerDocuments from '../components/employer/settings/EmployerDocuments';
import EmployerOpportunities from '../components/employer/EmployerOpportunities';
import EmployerOpportunity from '../components/employer/EmployerOpportunity';

function EmployerRoutes(props) {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route
        exact
        path={`${path}`}
        render={(props) => (
          <EmployerLoggedInLayout {...props}>
            <EmployerDashboard {...props} />
          </EmployerLoggedInLayout>
        )}
      />
      <Route
        path={`${path}/dashboard`}
        render={(props) => (
          <EmployerLoggedInLayout {...props}>
            <EmployerDashboard {...props} />
          </EmployerLoggedInLayout>
        )}
      />
      <Route
        exact
        path={`${path}/opportunities`}
        render={(props) => (
          <EmployerLoggedInLayout {...props}>
            <EmployerOpportunities {...props} />
          </EmployerLoggedInLayout>
        )}
      />
      <Route
        path={`${path}/opportunities/:opportunityId`}
        render={(props) => (
          <EmployerLoggedInLayout {...props}>
            <EmployerOpportunity {...props} />
          </EmployerLoggedInLayout>
        )}
      />
      <Route
        path={`${path}/settings`}
        exact
        render={(props) => (
          <EmployerLoggedInLayout {...props}>
            <EmployerSettings {...props}>
              <EmployerProfile {...props} />
            </EmployerSettings>
          </EmployerLoggedInLayout>
        )}
      />
      <Route
        path={`${path}/settings/my-business`}
        render={(props) => (
          <EmployerLoggedInLayout {...props}>
            <EmployerSettings {...props}>
              <EmployerBusiness {...props} />
            </EmployerSettings>
          </EmployerLoggedInLayout>
        )}
      />
      <Route
        path={`${path}/settings/password`}
        render={(props) => (
          <EmployerLoggedInLayout {...props}>
            <EmployerSettings {...props}>
              <EmployerPassword {...props} />
            </EmployerSettings>
          </EmployerLoggedInLayout>
        )}
      />
      <Route
        path={`${path}/settings/documents`}
        render={(props) => (
          <EmployerLoggedInLayout {...props}>
            <EmployerSettings {...props}>
              <EmployerDocuments {...props} />
            </EmployerSettings>
          </EmployerLoggedInLayout>
        )}
      />
      <Route
        path={`${path}/settings`}
        render={(props) => (
          <EmployerLoggedInLayout {...props}>
            <EmployerSettings {...props} />
          </EmployerLoggedInLayout>
        )}
      />
    </Switch>
  );
}

const mapStateToProps = (state) => ({ user: state.user });
export default connect(mapStateToProps)(EmployerRoutes);
