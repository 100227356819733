import React from 'react';
import { connect } from 'react-redux';
import { Col, Row, Form as BootstrapForm } from 'react-bootstrap';
import styled from 'styled-components';

import FlashSuccess from '../../../FlashSuccess';

import { API } from '../../../../settings';

import { Formik, Form, useField, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

const PageTitle = styled.h2`
  color: #000000;
  font-size: 25px;
`;

const PageSubTitle = styled.h3`
  color: #333333;
  font-size: 10px;
  margin-top: 10px;
`;

const StyledLabel = styled.label`
  margin-top: 1rem;
  font-size: 14px;
`;

const StyledInput = styled.input`
  &.error {
    border: 1px solid red;
  }
`;

const StyledErrorMessage = styled.div`
  font-size: 14px;
  color: red;
  width: 400px;
  margin-top: 0.25rem;
`;

const StyledRow = styled(Row)`
  padding: 25px;
`;

const ProfileForm = styled(Form)`
  margin-bottom: auto;
  height: 100%;
`;

const ButtonWrapper = styled.div`
  padding-top: 15px;
`;

const MyTextInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <>
      <StyledLabel htmlFor={props.id || props.name} className="form-label">
        {label}
      </StyledLabel>
      <StyledInput
        className={`text-input form-control ${
          meta.touched && meta.error ? 'error' : null
        }`}
        {...field}
        {...props}
      />
      {meta.touched && meta.error ? (
        <StyledErrorMessage className="error">{meta.error}</StyledErrorMessage>
      ) : null}
    </>
  );
};

const PageWrapper = styled.div`
  min-height: 100vh;
  min-height: -webkit-fill-available;
  position: relative;
`;

const validationSchema = Yup.object({
  currentPassword: Yup.string().required('Required'),
  newPassword: Yup.string().required('Required'),
  confirmNewPassword: Yup.string()
    .required('Required')
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
});

class EmployerPassword extends React.Component {
  constructor(props) {
    super(props);
    const {
      user: { uuid },
    } = props;
    this.state = {
      uuid,
      formIsSubmitting: false,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFlashClick = this.handleFlashClick.bind(this);
  }

  componentDidMount() {
    const {
      user: { uuid },
    } = this.props;

    this.setState({ uuid });
  }

  handleFlashClick() {
    this.setState({ flashMessage: '' });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  updateUserPassword({ uuid, currentPassword, newPassword }) {
    return API.put(`/users/${uuid}/password`, {
      currentPassword,
      newPassword,
    });
  }

  async handleSubmit(values, { resetForm, setSubmitting, setFieldError }) {
    console.log(values);
    const {
      user: { uuid },
    } = this.props;
    const { currentPassword, newPassword } = values;
    // const [setFlashMessage] = useState('');

    try {
      const response = await this.updateUserPassword({
        uuid,
        currentPassword,
        newPassword,
      });
      if (response) {
        toast.success('Password updated');
      }
      setSubmitting(false);
      resetForm();
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        setFieldError('newPassword', error.response.data.error);
        setFieldError('currentPassword', error.response.data.error);
        setFieldError('confirmNewPassword', error.response.data.error);
      }

      setSubmitting(false);
    }

    this.setState({ formIsSubmitting: false });
  }

  render() {
    const { formIsSubmitting } = this.state;

    return (
      <PageWrapper>
        <Row>
          <Col xs={12}>
            <FlashSuccess
              flashMessage={this.flashMessage}
              handleFlashClick={this.handleFlashClick}
            />
            <Formik
              initialValues={{
                currentPassword: '',
                newPassword: '',
                confirmNewPassword: '',
              }}
              validationSchema={validationSchema}
              onSubmit={this.handleSubmit}
            >
              <div>
                <Row>
                  <Col lg={12} xs={12}>
                    <ProfileForm>
                      <PageTitle>Reset your password</PageTitle>
                      <PageSubTitle>
                        Enter your old password and set a new one
                      </PageSubTitle>
                      <BootstrapForm.Group controlId="currentPassword">
                        <MyTextInput
                          type="password"
                          label="Old Password"
                          name="currentPassword"
                          placeholder=""
                        />
                      </BootstrapForm.Group>
                      <BootstrapForm.Group controlId="newPassword">
                        <MyTextInput
                          type="password"
                          label="New Password"
                          name="newPassword"
                          placeholder=""
                        />
                      </BootstrapForm.Group>
                      <BootstrapForm.Group controlId="confirmNewPassword">
                        <MyTextInput
                          type="password"
                          label="Retype New Password"
                          name="confirmNewPassword"
                          placeholder=""
                        />
                      </BootstrapForm.Group>
                      <BootstrapForm.Group>
                        <ButtonWrapper>
                          <button
                            style={{ float: 'right' }}
                            type="submit"
                            className="ln-btn primary"
                          >
                            Save changes
                          </button>
                        </ButtonWrapper>
                      </BootstrapForm.Group>
                    </ProfileForm>
                  </Col>
                </Row>
              </div>
            </Formik>
          </Col>
        </Row>
      </PageWrapper>
    );
  }
}

const mapStateToProps = (state) => ({ user: state.user });

export default connect(mapStateToProps)(EmployerPassword);
