import React from 'react';
import { Form } from 'react-bootstrap';
import { connect } from 'react-redux';

function EmploymentType(props) {
  return (
    <Form.Control {...props} as="select">
      <option disabled value={-1}>
        Select employment type
      </option>
      {props.employmentTypes.map((type, i) => (
        <option value={type.value}>{type.name}</option>
      ))}
    </Form.Control>
  );
}

function mapStateToProps(state) {
  return {
    employmentTypes: state.options.employmentTypes.APIdata,
  };
}

export default connect(mapStateToProps, null)(EmploymentType);
