import React, { Component, createRef } from 'react';
import styled from 'styled-components';
import OpportunityListItem from '../OpportunityListItem';
import { API } from '../../../settings';
import { connect } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import { getStudentSaved } from '../../../redux/actions/student';

const PageWrapper = styled.div`
  min-height: 100vh;
  height: 100vh;
  padding: 25px 0 25px;
  position: relative;
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .padded-content {
    padding: 0 25px;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

const PageTitle = styled.h2`
  color: #000000;
  font-size: 25px;
  margin-bottom: 0;
  padding-bottom: 0;
`;

const SavedCount = styled.div`
  float: right;
  font-weight: 600;
`;

const PageLiner = styled.div`
  padding: 0 25px 25px;

  &.noScroll {
    overflow-y: hidden;
  }
`;

const OpportunitiesList = styled.div`
  padding: 20px 20px 100px 20px;
  overflow-y: auto;
`;

const LoadingSpinner = styled(Spinner)`
  height: 50px;
  width: 50px;
`;

class SavedOpportunities extends Component {
  constructor(props) {
    super(props);
    this.state = {
      savedOpportunities: [],
      count: null,
      page: 1,
      limit: 20,
      loading: true,
    };
    this.opportunitiesListRef = createRef();
  }

  onScroll = () => {
    const el = this.opportunitiesListRef.current;
    if (
      el.scrollHeight - el.scrollTop - el.clientHeight < 1 &&
      !this.props.loading
    ) {
      this.getData(this.props.page + 1);
    }
  };

  componentDidMount() {
    if (this.props.count === undefined) {
      this.props.getStudentSaved(1);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevState !== this.state &&
      prevState.count === null &&
      this.opportunitiesListRef &&
      this.opportunitiesListRef.current
    ) {
      this.opportunitiesListRef.current.addEventListener(
        'scroll',
        this.onScroll
      );
    }
  }

  componentWillUnmount() {
    this.opportunitiesListRef.current.removeEventListener(
      'scroll',
      this.onScroll
    );
  }

  getData = (page = this.state.page, limit = this.state.limit) => {
    // this.setState({ loading: true, page: page, limit: limit });
    const {
      user: { uuid },
    } = this.props;
    API.get(
      `/opportunities/saved?page=${page}&limit=${limit}&sort=asc&uuid=${uuid}`
    )
      .then((res) => {
        let { savedOpportunities } = this.state;
        savedOpportunities = savedOpportunities.concat(
          res.data.savedOpportunities
        );
        this.setState({
          count: res.data.count,
          savedOpportunities: savedOpportunities,
          loading: false,
        });
      })
      .finally(() => this.setState({ loading: false }));
  };

  bookmarkOpportunity = (i, id, isBookmarked) => {
    // let { savedOpportunities } = this.state;
    // savedOpportunities[i].isBookmarked = isBookmarked;
    // this.setState({ savedOpportunities });
  };

  render() {
    const { loading, savedOpportunities, count } = this.props;
    return (
      <PageWrapper>
        <PageLiner>
          <TitleWrapper>
            <PageTitle>Saved Opportunities</PageTitle>
            <SavedCount>{count}</SavedCount>
          </TitleWrapper>
        </PageLiner>
        <OpportunitiesList ref={this.opportunitiesListRef}>
          {/*<ByCategories*/}
          {/*  percentDone={100}*/}
          {/*  clusters={[*/}
          {/*    {*/}
          {/*      assessmentId: 1,*/}
          {/*      assessmentKey: 'ASSESSMENT_1',*/}
          {/*      categoryName: null,*/}
          {/*      clusterNumber: 1,*/}
          {/*      createdAt: '2021-04-05T20:53:11.954Z',*/}
          {/*      hollandCode: 'Realistic/Investigative',*/}
          {/*      id: 1,*/}
          {/*      key: 'AGRICULTURE_FOOD_NATURAL_RESOURCES',*/}
          {/*      name: 'Agriculture/Food/Natural Resources',*/}
          {/*      order: 0,*/}
          {/*      updatedAt: '2021-04-05T20:53:11.975Z'*/}
          {/*    }*/}
          {/*  ]}*/}
          {/*/>*/}
          <>
            {savedOpportunities.map((opportunity, i) => {
              const { id, title, Employer, performedAt, location, State } =
                opportunity;
              return (
                <OpportunityListItem
                  key={id}
                  to={`/student/opportunity/${id}`}
                  linkState={opportunity}
                  title={title}
                  // imgSrc='https://upload.wikimedia.org/wikipedia/commons/thumb/5/51/Facebook_f_logo_%282019%29.svg/900px-Facebook_f_logo_%282019%29.svg.png'
                  // imgAlt='Facebook logo'
                  companyName={Employer.name}
                  location={
                    performedAt === 'REMOTE'
                      ? 'Remote'
                      : `${location}, ${State.code}`
                  }
                  isBookmarked={true}
                  id={id}
                  onBookmark={(id, isBookmarked) =>
                    this.bookmarkOpportunity(i, id, isBookmarked)
                  }
                />
              );
            })}
          </>
          {loading ? (
            <center>
              <LoadingSpinner animation="border" />
            </center>
          ) : null}
        </OpportunitiesList>
      </PageWrapper>
    );
  }
}

export default connect(
  (state) => ({
    ...state.student.savedOpportunities,
  }),
  { getStudentSaved }
)(SavedOpportunities);
