import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMedal } from '@fortawesome/free-solid-svg-icons';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import { faBolt } from '@fortawesome/free-solid-svg-icons';
import assessmentPanelLogo from '../images/assessmentPanelLogo.png';

const MAX_WIDTH = 576;

const Overlay = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  ${'' /* visibility: hidden; */}
  display: none;
  ${'' /* transition: visibility 0.3s 0s; */}
  background-color: #000000;
  opacity: 0.5;
  z-index: 200;
`;

const PanelWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 300;
  display: none;
  justify-content: center;
  &.panelVisable {
    display: flex;
    .overlay {
      display: block;
      ${'' /* visibility: visible; */}
      ${'' /* transition: visibility 0s 0s; */}
    }
    .panelContainer {
      display: block;
      transform: translate3d(0, 0, 0);
      transition-delay: 0s;
    }
  }
`;

const PanelContainer = styled.div`
  display: none;
  position: fixed;
  bottom: 0;
  max-width: ${MAX_WIDTH}px;
  opacity: 1;
  width: 100%;
  max-height: 90vh;
  z-index: 301;
  transform: translate3d(0, 100%, 0);
  transition: transform 0.2s 0.2s;
  background-color: #ffffff;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
`;

const PanelTitleWrapper = styled.div`
  display: flex;
  top: 0;
  width: 100%;
  padding: 25px 25px 0;
`;

const PanelTitle = styled.h3`
  overflow-wrap: break-word;
  font-size: 1.5rem;
  line-height: 1.5rem;
  margin: 0;
  padding: 0;
`;

const PanelContent = styled.div`
  margin: 1.5rem 1.5625rem 1.5625rem;
  color: #333333;
  p {
    margin-bottom: 1.5rem;
  }
`;

const ArtContainer = styled.div`
  display: flex;
  margin-bottom: 1.5rem;
`;

const Artwork = styled.div`
  width: 50%;
  padding: 0 10px;
`;

const ArtworkImg = styled.img`
  width: 100%;
  align-self: center;
`;

const ListContainer = styled.div`
  width: 50%;
`;

const ListRow = styled.div`
  width: 100%;
  display: flex;
  margin-top: 1rem;
`;
const ListRowIcon = styled.div`
  width: 20%;
  text-align: right;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: 1.25rem;
  color: #ffc34e;
`;
const ListRowCopy = styled.div`
  width: 80%;
  font-size: 0.875rem;
  font-weight: bold;
  color: #afafaf;
`;

const Button = styled(Link)`
  display: block;
  width: 100%;
  height: 55px;
  border-radius: 12px;
  color: #ffffff;
  background-color: #1a1a1a;
  border: none;
  margin-top: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-weight: bold;
  &:hover {
    color: #ffffff;
    text-decoration: none;
  }
`;

const StudentDashboardPanel = (props) => {
  const { dataLoaded, percentDone, showPanel, togglePanel } = props;

  let panelVisibilityClassName = '';

  if (percentDone === 0 && dataLoaded && showPanel) {
    panelVisibilityClassName = 'panelVisable';
  }

  return (
    <PanelWrapper className={panelVisibilityClassName}>
      <Overlay
        className="overlay"
        onClick={() => {
          togglePanel();
        }}
      />
      <PanelContainer className="panelContainer">
        <PanelTitleWrapper>
          <PanelTitle>Take the Assessment Survey</PanelTitle>
        </PanelTitleWrapper>
        <PanelContent>
          <p>
            We spent some time designing and developing this survey in order to
            help you find the best options available.
          </p>
          <ArtContainer>
            <Artwork>
              <ArtworkImg src={assessmentPanelLogo} alt="Logo" />
            </Artwork>
            <ListContainer>
              <ListRow>
                <ListRowIcon>
                  <FontAwesomeIcon icon={faMedal} />
                </ListRowIcon>
                <ListRowCopy>95% Accurate</ListRowCopy>
              </ListRow>
              <ListRow>
                <ListRowIcon>
                  <FontAwesomeIcon icon={faClock} />
                </ListRowIcon>
                <ListRowCopy>5 min total</ListRowCopy>
              </ListRow>
              <ListRow>
                <ListRowIcon>
                  <FontAwesomeIcon icon={faBolt} />
                </ListRowIcon>
                <ListRowCopy>No typing</ListRowCopy>
              </ListRow>
            </ListContainer>
          </ArtContainer>
          <p>
            Based on your selections our algorithm will suggest options tailored
            to your skills and interests.
          </p>
          <Button to="/assessment-intro-1">Start Assessment</Button>
        </PanelContent>
      </PanelContainer>
    </PanelWrapper>
  );
};

export default StudentDashboardPanel;
