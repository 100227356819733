import React from 'react';
import Avatar from 'react-avatar';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledAvatar = styled(Avatar)`
  font-family: Nunito;
`;

const ProfileAvatar = (props) => {
  const { firstName, lastName } = props;

  return (
    <StyledAvatar
      className="profileAvatar"
      round={true}
      size={80}
      name={`${firstName} ${lastName}`}
      color="#797878"
    />
  );
};

export default ProfileAvatar;

ProfileAvatar.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
};
