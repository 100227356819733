import React, { Component } from 'react';
import styled from 'styled-components';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import { Link, withRouter } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';

import { API } from '../../settings';
import EmployerAddNewOpportunity from './EmployerAddNewOpportunity/EmployerAddNewOpportunity';
import moment from 'moment';
import StatusIndicator from '../common/StatusIndicator';
import debounce from 'lodash.debounce';
import Pagination from 'rc-pagination';
import { Search20Regular } from '@fluentui/react-icons';
import { connect } from 'react-redux';
import locale from 'rc-pagination/es/locale/en_US';
import { getOpportunities, setSearchValue } from '../../redux/actions/employer';

const PageTitle = styled.h3`
  margin-bottom: 0;
`;

const FilterWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const Filter = styled.input`
  margin-bottom: 1.25rem;
  width: 100%;

  font-size: 0.875rem;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  padding-left: 2.25rem;
  border-radius: 0.625rem;
  border: 1px solid #e2e2ea;
  font-family: 'Nunito';

  &::placeholder {
    color: #929292;
    opacity: 1;
  }
`;

const SearchIcon = styled(Search20Regular)`
  position: absolute;
  top: calc(50% - 10px);
  left: 5px;
  color: #92929d;
`;

const OpportunitiesWrapper = styled.div`
  background: #ffffff;
  padding: 1rem;
  border-radius: 1.25rem;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.02);

  table,
  th,
  td {
    border: none;
  }

  thead {
    th {
      border: none;
    }
  }

  tbody:before {
    content: '@';
    display: block;
    line-height: 0.5rem;
    text-indent: -99999px;
  }

  td {
    font-size: 0.875rem;
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
  }

  td.firstName,
  td.lastName {
    font-size: 1rem;
    font-weight: bold;
    background: none;
  }

  td.grade,
  td.percentDone {
    span {
      background: #fbfbfb;
      display: inline-block;
      padding: 0.2rem 1rem;
      border-radius: 8px;
    }
  }
`;

const BootstrapTableWrapper = styled.div`
  .table td {
    overflow-wrap: break-word;
  }

  .table th {
    background: #fbfbfb;
    color: #858585;
    font-size: 0.6875rem;

    &:first-child {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    &:last-child {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }

  .react-bootstrap-table-pagination {
    margin-top: 2rem;
    justify-content: center;

    div {
      display: none;
    }

    div.react-bootstrap-table-pagination-list {
      display: flex;
      justify-content: center;

      ul {
        float: none;
      }
    }

    .page-link {
      border-color: #797878;
      color: #797878;
    }

    .page-item.active .page-link {
      color: #ffffff;
      background-color: #000000;
      border-color: #000000;
    }
  }
`;

const Button = styled.button`
  height: 35px;
  border-radius: 12px;
  color: #ffffff;
  background-color: #1a1a1a;
  border: none;
  margin-top: auto;
`;

class EmployerOpportunities extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchValue: '',
      opportunities: [],
      page: 1,
      limit: 10,
      count: null,
      search: '',
      sortBy: 'createdAt',
      sort: 'desc',
      loading: false,
    };

    this.getOpportunitiesDebounce = debounce(
      this.getOpportunitiesDebounce,
      700
    );
  }

  componentDidMount() {
    if (this.props.count === undefined) {
      this.props.getOpportunities(1, this.props.employerId);
    }
  }

  getOpportunities = (
    page = this.state.page,
    limit = this.state.limit,
    search = this.state.searchValue,
    sortBy = this.state.sortBy,
    sort = this.state.sort
  ) => {
    this.setState({
      page: page,
      limit: limit,
      searchValue: search,
      loading: true,
      sortBy: sortBy,
      sort: sort,
    });
    API.get(
      `/opportunities/?employerId=${this.props.user.userTypeProfile.id}&page=${page}&limit=${limit}&search=${search}&sortBy=${sortBy}&sort=${sort}`
    )
      .then((res) => {
        if (res.data && Array.isArray(res.data.opportunities)) {
          this.setState({
            opportunities: res.data.opportunities,
            count: res.data.count,
            loading: false,
          });
        }
      })
      .catch((error) => {
        // TODO: handle error
        console.log(error);
      })
      .finally(() => this.setState({ loading: false }));
  };

  onSearch = (e) => {
    this.props.setSearchValue(e.target.value);
    this.getOpportunitiesDebounce(e.target.value, 'createdAt', 'desc');
  };

  getOpportunitiesDebounce = (searchValue, sortBy, sort) => {
    this.props.getOpportunities(this.state.page, this.props.employerId, searchValue, sortBy, sort);
  };

  onTableChange = ({
    page, // newest page
    sizePerPage, // newest sizePerPage
    sortField, // newest sort field
    sortOrder, // newest sort order
    filters, // an object which have current filter status per column
    data, // when you enable remote sort, you may need to base on data to sort if data is filtered/searched
    cellEdit: {
      // You can only see this prop when type is cellEdit
      rowId,
      dataField,
      newValue,
    },
  }) => {
    this.getOpportunities(page, this.props.employerId);
  };

  opportunityAdded = (opportunity) => {
    let { opportunities, limit } = this.state;
    if (opportunities.length === limit) {
      opportunities.pop();
    }
    opportunities.unshift(opportunity);
    this.setState({ opportunities: opportunities });
  };

  onPageChange = (newpage) => {
    this.getOpportunities(newpage, this.props.employerId);
  };

  onTableSort = (order, dataField) => {
    if (dataField === this.props.sortBy) {
      this.props.sort === 'desc' ? (order = 'asc') : (order = 'desc');
    }
    this.getOpportunitiesDebounce(this.props.search, dataField, order);
  };

  render() {
    const { searchValue, opportunities, page, count, limit, loading } =
      this.props;
    const columns = [
      {
        text: 'Opportunity Title',
        dataField: 'title',
        formatter: (cell, row) => (
          <Link
            to={{
              pathname: `${this.props.match.path}/${row.id}`,
              state: row,
            }}
          >
            {cell}
          </Link>
        ),
        sort: true,
        sortFunc: (a, b, order, dataField, rowA, rowB) => {
          this.onTableSort(order, dataField);
        },
        classes: 'title',
      },
      {
        text: 'Opportunity Type',
        dataField: 'employmentType',
        sort: true,
        classes: 'employmentType',
        sortFunc: (a, b, order, dataField, rowA, rowB) => {
          this.onTableSort(order, dataField);
        },
      },
      {
        text: 'Posted On',
        dataField: 'createdAt',
        formatter: (cell, row) => moment(cell).fromNow(),
        sort: true,
        classes: 'createdAt',
        sortFunc: (a, b, order, dataField, rowA, rowB) => {
          this.onTableSort(order, dataField);
        },
      },
      {
        text: 'Performed At',
        dataField: 'performedAt',
        formatter: (cell, row) => {
          return cell === 'REMOTE' ? 'Remote' : 'Onsite';
        },
        sort: true,
        classes: 'performedAt',
        sortFunc: (a, b, order, dataField, rowA, rowB) => {
          this.onTableSort(order, dataField);
        },
      },
      {
        text: 'City',
        dataField: 'location',
        sort: true,
        classes: 'location',
        sortFunc: (a, b, order, dataField, rowA, rowB) => {
          this.onTableSort(order, dataField);
        },
      },
      {
        text: 'State',
        dataField: 'stateCode',
        sort: false,
        classes: 'stateCode',
        sortFunc: (a, b, order, dataField, rowA, rowB) => {
          this.onTableSort(order, dataField);
        },
      },
      {
        text: 'Status',
        dataField: 'isActive',
        formatter: (cell, row) => {
          const unavailable = row.matched === row.numberOfPositions
          return <StatusIndicator status={!row.isActive ? 'negative' : unavailable ? 'neutral' : 'positive'} />
        },
        sort: true,
        classes: 'isActive',
        sortFunc: (a, b, order, dataField, rowA, rowB) => {
          this.onTableSort(order, dataField);
        },
      },
    ];

    return (
      <Container fluid className="p-0 h-100 ">
        <Row>
          <Col xs={12}>
            <div
              className="horizontal-flex align-center space-between children-mg-r mg-b"
              style={{ height: '40px' }}
            >
              <PageTitle>Opportunities</PageTitle>
              <EmployerAddNewOpportunity
                opportunityAdded={this.opportunityAdded}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <OpportunitiesWrapper>
              <FilterWrapper>
                <Filter
                  type="text"
                  placeholder="Search by Opportunity Title"
                  onChange={this.onSearch}
                  value={searchValue}
                />
                <SearchIcon />
              </FilterWrapper>
              <BootstrapTableWrapper>
                {loading ? (
                  <center>
                    <Spinner animation="border" />
                  </center>
                ) : (
                  <>
                    <BootstrapTable
                      columns={columns}
                      data={opportunities}
                      keyField="id"
                      // pagination={paginationFactory(paginationConfig)}
                    />
                    <Pagination
                      locale={locale}
                      current={page}
                      total={count}
                      pageSize={limit}
                      onChange={this.onPageChange}
                      prevIcon="Prev"
                      nextIcon="Next"
                    />
                  </>
                )}
              </BootstrapTableWrapper>
            </OpportunitiesWrapper>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default connect(
  (state) => ({
    user: state.user,
    ...state.employer.opportunities,
  }),
  {
    getOpportunities,
    setSearchValue,
  }
)(withRouter(EmployerOpportunities));
