import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import ProgressBarDone from './ProgressBarDone';

const AssessmentTrackerContainer = styled.div`
  display: flex;
  padding: 0;
  margin-top: 12px;
`;

const PercentContentsWrapper = styled.div`
  width: 100%;
  display: flex;
`;

const PercentBarContainer = styled.div`
  width: 85%;
  padding-top: 0.3rem;
`;

const PercentCopy = styled.div`
  font-size: 0.875rem;
  text-align: left;
  padding-top: 0rem;
  padding-left: 0.75rem;
  width: 15%;
  text-align: right;
`;

const StudentProfileAssessmentTrackerDone = (props) => {
  const { percentDone } = props;

  return (
    <AssessmentTrackerContainer>
      <PercentContentsWrapper>
        <PercentBarContainer>
          <ProgressBarDone value={percentDone} max={100} />
        </PercentBarContainer>
        <PercentCopy>{percentDone}%</PercentCopy>
      </PercentContentsWrapper>
    </AssessmentTrackerContainer>
  );
};

export default StudentProfileAssessmentTrackerDone;
