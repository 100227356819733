import { EMPLOYER_DASHBOARD_GET } from '../../constants/action-types';

const initialState = {
  loading: true,
  opportunitiesStatus: {},
};

function employerDashboard(state = initialState, action) {
  switch (action.type) {
    case EMPLOYER_DASHBOARD_GET:
      return { ...state, ...action.payload };
    default:
      break;
  }
  return state;
}

export default employerDashboard;
