import React from 'react';
import styled from 'styled-components';
import Loading from './loading';

const Body = styled.div`
    max-width: 600px;
    min-height: 100vh;
    margin: auto;
    font-family: nunito;
    display: flex;
    flex-direction: column;
    background-color: #f9f9f9;
    
    @media (min-width: 600px) {
        box-shadow: 1px 1px 10px rgba(0,0,0,0.5);
    }
`

const Contents = styled.div`
    padding: 0px 0px;
    flex: 1;
    display: flex;
    flex-direction: column;
`

export default function UIWrapper ({ loading, children, headerProps = {}, ...props}) {

    return <Body {...props}>
        <Contents>
            {loading ? <Loading /> : children}
        </Contents>
    </Body>

};

