import React from 'react';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import styled from 'styled-components';
import dotenv from 'dotenv';
import { Link, withRouter } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { API } from '../../settings';
import { compareEmployerNames } from '../../utils/arrayOps';
import AddNewEmployer from './Employer/AddNewEmployer';


import { Search20Regular } from '@fluentui/react-icons';

dotenv.config();

const PageTitle = styled.h3`
  margin-bottom: 0;
`;

const FilterWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const Filter = styled.input`
  margin-bottom: 1.25rem;
  width: 100%;

  font-size: 0.875rem;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  padding-left: 2.25rem;
  border-radius: 0.625rem;
  border: 1px solid #e2e2ea;
  font-family: 'Nunito';

  &::placeholder {
    color: #929292;
    opacity: 1;
  }
`;

const ApprovalStatus = styled.div`
  display: inline-block;
  align-items: center;
  margin: 0 5px 5px 0;

  .indicator {
    border-radius: 50%;
    height: 10px;
    width: 10px;
    margin-right: 5px;
    display: inline-block;
  }

  .indicator.APPROVED {
    background: #46d191;
  }

  .indicator.EXPIRED,
  & .indicator.REJECTED {
    background: #fe7272;
  }

  .indicator.PENDING {
    background: #ffc34e;
  }
`;

const SearchIcon = styled(Search20Regular)`
  position: absolute;
  top: calc(50% - 10px);
  left: 5px;
  color: #92929d;
`;

const OpportunitiesWrapper = styled.div`
  background: #ffffff;
  padding: 1rem;
  border-radius: 1.25rem;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.02);

  table,
  th,
  td {
    border: none;
  }

  thead {
    th {
      border: none;
    }
  }

  tbody:before {
    content: '@';
    display: block;
    line-height: 0.5rem;
    text-indent: -99999px;
  }

  td {
    font-size: 0.875rem;
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
  }

  td.firstName,
  td.lastName {
    font-size: 1rem;
    font-weight: bold;
    background: none;
  }

  td.grade,
  td.percentDone {
    span {
      background: #fbfbfb;
      display: inline-block;
      padding: 0.2rem 1rem;
      border-radius: 8px;
    }
  }
`;

const BootstrapTableWrapper = styled.div`
  .table td {
    overflow-wrap: break-word;
  }

  .table th {
    background: #fbfbfb;
    color: #858585;
    font-size: 0.6875rem;

    &:first-child {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    &:last-child {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }

  .react-bootstrap-table-pagination {
    margin-top: 2rem;
    justify-content: center;

    div {
      display: none;
    }

    div.react-bootstrap-table-pagination-list {
      display: flex;
      justify-content: center;

      ul {
        float: none;
      }
    }

    .page-link {
      border-color: #797878;
      color: #797878;
    }

    .page-item.active .page-link {
      color: #ffffff;
      background-color: #000000;
      border-color: #000000;
    }
  }
`;

const Button = styled.button`
  height: 35px;
  border-radius: 12px;
  color: #ffffff;
  background-color: #1a1a1a;
  border: none;
  margin-top: auto;
`;

class SuperAdminEmployers extends React.Component {
  constructor(props) {
    super(props);

    // this.state = {
    //   employers: [],
    //   page: 1,
    //   limit: 10,
    //   count: null,
    //   search: '',
    //   loading: false,
    // };

    this.state = {
      filter: '',
      filteredEmployers: [],
      loading: false,
      employers: [],
    };

    // this.getEmployersDebounce = debounce(this.getEmployersDebounce, 1500);
    this.getEmployers = this.getEmployers.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
  }

  componentDidMount() {
    this.getEmployers();
  }

  getEmployers = () => {
    API.get(`/employers/all`)
      .then((res) => {
        // TODO: add search query param. Waiting Max.
        this.setState({
          employers: res.data.employers,
          filteredEmployers: res.data.employers,
        });
      })
      .catch((error) => {
        // TODO: handle error
        console.log(error);
      })
      .finally(() => this.setState({ loading: false }));
  };

  // onSearch = (e) => {
  //   this.setState({ searchValue: e.target.value });
  //   this.getEmployersDebounce(e.target.value);
  // };

  // getEmployersDebounce = (searchValue) => {
  //   this.getEmployers(this.state.page, this.state.limit, searchValue);
  // };

  onTableChange = ({
    page, // newest page
    sizePerPage, // newest sizePerPage
    sortField, // newest sort field
    sortOrder, // newest sort order
    filters, // an object which have current filter status per column
    data, // when you enable remote sort, you may need to base on data to sort if data is filtered/searched
    cellEdit: {
      // You can only see this prop when type is cellEdit
      rowId,
      dataField,
      newValue,
    },
  }) => {
    this.getOpportunities(page, this.props.employerId);
  };

  opportunityAdded = (opportunity) => {
    let { opportunities, limit } = this.state;
    if (opportunities.length === limit) {
      opportunities.pop();
    }
    opportunities.unshift(opportunity);
    this.setState({ opportunities: opportunities });
  };

  onPageChange = (newpage) => {
    this.getOpportunities(newpage, this.props.employerId);
  };

  handleFilterChange = (event) => {
    const { employers } = this.state;
    const filter = event.target.value;

    const filteredByName = employers.filter((employer) =>
      employer.name.toLowerCase().includes(filter.toLowerCase())
    );

    const filteredEmployers = [...filteredByName].sort(compareEmployerNames);

    this.setState({ filter, filteredEmployers });
  };

  onTableSort = (order, dataField) => {};

  render() {
    const { filter, filteredEmployers, loading, employers } = this.state;

    // const { searchValue, employers, page, count, limit, loading } = this.state;

    const columns = [
      {
        text: 'Employer Name',
        dataField: 'name',
        formatter: (cell, row) => (
          <Link
            to={{
              pathname: `${this.props.match.path}/${row.id}`,
              state: row,
            }}
          >
            {cell}
          </Link>
        ),
        sort: true,
        // sortFunc: (a, b, order, dataField, rowA, rowB) => {
        //   this.onTableSort(order, dataField);
        // },
      },
      {
        text: 'Employer Contact',
        dataField: 'User',
        sort: true,
        formatter: (cell, row) =>
          cell ? `${cell.firstName} ${cell.lastName}` : '',
        // sortFunc: (a, b, order, dataField, rowA, rowB) => {
        //   this.onTableSort(order, dataField);
        // },
      },
      {
        text: 'Status',
        dataField: 'EmployerApprovals',
        formatter: (cell, row) =>
          Array.isArray(cell)
            ? cell.map((approval, i) => {
                let status =
                  approval.status.charAt(0).toUpperCase() +
                  approval.status.slice(1).toLowerCase();
                return (
                  <ApprovalStatus>
                    <div className={`indicator ${approval.status}`} />
                    {`${approval.State.code} ${status}`}
                  </ApprovalStatus>
                );
              })
            : '',
        sort: true,
        classes: 'EmployerApprovals',
        // sortFunc: (a, b, order, dataField, rowA, rowB) => {
        //   this.onTableSort(order, dataField);
        // },
      },
      {
        text: 'Restriction',
        dataField: 'Schools',
        sort: true,
        formatter: (cell, row) => (cell && cell[0] ? cell[0].name : ''),
        // sortFunc: (a, b, order, dataField, rowA, rowB) => {
        //   this.onTableSort(order, dataField);
        // },
      },
    ];

    const paginationConfig = {
      sizePerPage: 10,
      hideSizePerPage: true,
      // totalSize: count,
      // page: page,
      // hideSizePerPage: true,
      // onPageChange: this.onPageChange,
    };

    return (
      <Container fluid className="p-0 h-100">
        <Row>
          <Col xs={12}>
            <div
              className="horizontal-flex align-center space-between children-mg-r mg-b"
              style={{ height: '40px' }}
            >
              <PageTitle>Employers</PageTitle>
              <AddNewEmployer
                onFinish={() => {
                  this.getEmployers();
                }}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <OpportunitiesWrapper>
              <FilterWrapper>
                <Filter
                  type="text"
                  // name="search"
                  // value={searchValue}
                  value={filter}
                  placeholder="Search by Company Name..."
                  onChange={this.handleFilterChange}
                  // onTableChange={this.handleFilterChange}
                />
                <SearchIcon icon={faSearch} size="1x" />
              </FilterWrapper>
              <BootstrapTableWrapper>
                {loading ? (
                  <center>
                    <Spinner animation="border" />
                  </center>
                ) : (
                  <>
                    <BootstrapTable
                      columns={columns}
                      data={filteredEmployers}
                      keyField="id"
                      pagination={paginationFactory(paginationConfig)}
                    />
                    {/* <Pagination
                      current={page}
                      total={count}
                      pageSize={limit}
                      onChange={this.onPageChange}
                      prevIcon="Prev"
                      nextIcon="Next"
                      locale={locale}
                    /> */}
                  </>
                )}
              </BootstrapTableWrapper>
            </OpportunitiesWrapper>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withRouter(SuperAdminEmployers);
