import mixpanel from "mixpanel-browser";

export const SIGN_IN = 'SIGN_IN'; 
export const SIGN_OUT = 'SIGN_OUT'; 
export const ACTIVATE_ACCOUNT = 'ACTIVATE_ACCOUNT'; 
export const VIEW_DASHBOARD = 'VIEW_DASHBOARD'; 

export const CREATE_STUDENT = 'CREATE_STUDENT'; 
export const BULK_CREATE_STUDENT = 'BULK_CREATE_STUDENT'; 
export const EDIT_STUDENT = 'EDIT_STUDENT';
export const VIEW_STUDENT = 'VIEW_STUDENT';

export const DELETE = 'DELETE'; 
export const STUDENT = 'STUDENT';
export const EMPLOYER = 'EMPLOYER';
export const COUNSELOR = 'COUNSELOR';
export const ADMIN= 'ADMIN';

export const START_ASSESSMENT = 'START_ASSESSMENT';  
export const RESTART_ASSESSMENT = 'RESTART_ASSESSMENT'; 
export const FINISH_ASSESSMENT = 'FINISH_ASSESSMENT'; 
export const VIEW_CLUSTER = 'VIEW_CLUSTER'; 
export const VIEW_SUBCATEGORY = 'VIEW_SUBCATEGORY'; 
export const SAVE_SUBCATEGORY = 'SAVE_SUBCATEGORY'; 
export const UNSAVE_SUBCATEGORY = 'UNSAVE_SUBCATEGORY'; 
export const VIEW_SAVED_OPPORTUNITIES = 'VIEW_SAVED_OPPORTUNITIES'; 
export const SAVE_OPPORTUNITY = 'SAVE_OPPORTUNITY'; 
export const UNSAVE_OPPORTUNITY = 'UNSAVE_OPPORTUNITY'; 
export const UNDO_INTERESTED_IN_OPPORTUNITY = 'UNDO_INTERESTED_IN_OPPORTUNITY'; 
export const INTERESTED_IN_OPPORTUNITY = 'INTERESTED_IN_OPPORTUNITY'; 


export const UPLOAD_DOCUMENTS = 'UPLOAD_DOCUMENTS'; 
export const CREATE_OPPORTUNITY = 'CREATE_OPPORTUNITY'; 
export const EDIT_OPPORTUNITY = 'EDIT_OPPORTUNITY'; 
export const VIEW_OPPORTUNITY = 'VIEW_OPPORTUNITY'; 
export const MATCH_OPPORTUNITY = 'MATCH_OPPORTUNITY'; 
export const COMPLETE_OPPORTUNITY = 'COMPLETE_OPPORTUNITY'; //needs talk!

export const reset = () => {
    mixpanel.reset();
}

export const identify = (user) => {  
    if (!user?.uuid) return;
    
    mixpanel.identify(user?.uuid);
    mixpanel.people.set({ 
      '$email': user.email, 
      name: user.firstName + ' ' + user.lastName, 
      userType: user.userType 
    });
};

export const trackWithRole = (action, user) => {
    const suffix = user.userType === 'COUNSELOR' ? COUNSELOR
        : user.userType === 'STUDENT' ? STUDENT
        : user.userType === 'EMPLOYER' ? EMPLOYER
        : user.userType === 'SUPER_ADMIN' ? ADMIN 
        : ''

    track(action + '_' + suffix);
}

export const track = (action, props) => {
    //console.log('tracking:', action, props);
    mixpanel.track(action, props);
}
