import React, { useEffect } from 'react';
import { Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { getStates } from '../../redux/actions/options';

const Sidebar = styled(Col)`
  display: flex;
  flex-direction: column;
  padding: 20px;
  height: 90vh;
  border-radius: 20px;
  background: #f1f1f1;
  overflow: scroll;
`;

const Top = styled.div`
  width: 100%;
  text-align: center;
`;
const Bottom = styled(Top)`
  text-align: left;
  padding-bottom: 35px;
`;

const NameWrapper = styled.div`
  margin-top: 1.25rem;
`;

const Name = styled.div`
  color: #000000;
  margin-top: 0.125rem;
  font-weight: bold;
  font-size: 1.05rem;
  line-height: 1.5rem;
`;

const Field = styled.div`
  margin-top: 0.25rem;
  color: #858585;
  font-size: 0.875rem;
  font-weight: bold;

  & b {
    color: black
  }
`;

const JobSidebar = ({ 
  name = 'Undefined', 
  company = 'Undefined', 
  numberOfPositions = 'Undefined', 
  description = 'Undefined', 
  location = 'Undefined', 
  employmentType = 'Undefined',
  url = 'undefined',
  states,
  stateId,
  isActive,
  ...props
}) => {

  useEffect(() => {
    props.getStates();
  }, []);

  return (
    <Sidebar lg={3}>
      <Top>
        <NameWrapper>
          <Name>{name}</Name>
          <Field>{numberOfPositions} {numberOfPositions !== 1 ? 'positions' : 'position'} available</Field>
          <Field>At <a href={url} target='__blank'>{company}</a></Field>
        </NameWrapper>
      </Top>
      <div style={{ flex: 1 }} />
      <Bottom>
        <Field><b>Status:</b> {!isActive ? 'Closed' : numberOfPositions === 0 ? 'Matches Found' : 'Active'  }</Field>
        <Field><b>Location:</b> {location}, {states[stateId]?.code}</Field>
        <Field><b>Employment Type:</b> {employmentType}</Field>
        <div style={{ height: 30}} />
        <Field>{description}</Field>
      </Bottom>
    </Sidebar>
  );
};

const mapStateToProps = (state) => ({
  states: state.options.states.APIdata,
});

const mapDispatchToProps = { getStates }

export default connect(mapStateToProps, mapDispatchToProps)(JobSidebar);


