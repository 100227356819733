import { ACTIVITY_TYPES } from '../components/parent/shared/constants';


export function formatToList(data, type) {
    data = data.filter((d) => d.type === type);
    return data.map((d) => ({
      company: d.employer.name,
      location: d.location,
      position: d.title,
      id: d.opportunityId
    }))
  }
  
  
function dateDifference(dateA, dateB) {
  
    if (!dateA || !dateB ) return NaN;
  
    return Math.abs(dateA.getTime() - dateB.getTime()) / (1000 * 60 * 60 * 24);
  
}
  
function mapOpportunityProps(rawData) {
  
    return ({
        location: rawData.location,
        id: rawData.opportunityId,
        position: rawData.title,
        company: rawData.employer?.name,
        companyId: rawData.employer?.id,
        companyUrl: rawData.employer?.url,
        description: rawData.description,
        isRemote: rawData.performedAt === 'REMOTE',
    })
  
}
  
function clusterByType(data, type = ACTIVITY_TYPES.SAVED_OPTIONS) {
  
    if (!data) return [];
  
    const saved_options = data.filter((d) => d.type === type );
    
    let date;
    let clusters = [];
  
    saved_options.forEach((op, i) => {
        let thisDate = new Date(op.date);
        let offsetFromToday = dateDifference(new Date(), thisDate);
        let threshold = offsetFromToday > 30 ? 30 : offsetFromToday > 7 ? 7 : 1;
        let difference = dateDifference(date, thisDate);
                
        if (difference > threshold || isNaN(difference)) {
            clusters.push({
                date: thisDate,
                type,
                payload: { opportunities: [] }
            });
            date = thisDate;
        }; 
        clusters[clusters.length - 1]['payload']['opportunities'].push(mapOpportunityProps(op));
    });
  
    return clusters;
}



function clusterByStatus(data, status = 'finished') {
  
    if (!data) return [];
  
    const options = data.filter((d) => d[status] );
    
    let date;
    let clusters = [];
  
    options.forEach((op, i) => {
        let thisDate = new Date(op.updatedAt);
        let offsetFromToday = dateDifference(new Date(), thisDate);
        let threshold = offsetFromToday > 30 ? 30 : offsetFromToday > 7 ? 7 : 1;
        let difference = dateDifference(date, thisDate);
                
        if (difference > threshold || isNaN(difference)) {
            clusters.push({
                date: thisDate,
                type: status,
                payload: { opportunities: [] }
            });
            date = thisDate;
        }; 
        clusters[clusters.length - 1]['payload']['opportunities'].push(mapOpportunityProps(op));
    });
  
    return clusters;
}
  
export function clusterActivityData(data) {
  
    const raw = data.map((d) => ({...d, date: new Date(d.date)}));
    const saved = clusterByType(raw, ACTIVITY_TYPES.SAVED_OPTIONS);
    const interested = clusterByType(raw, ACTIVITY_TYPES.INTERESTED);
    const finished = clusterByStatus(raw, 'finished');

    const rest = raw.filter((p) => ![ACTIVITY_TYPES.SAVED_OPTIONS, ACTIVITY_TYPES.INTERESTED].includes(p.type));
    
    console.log('F', finished);

    const activity = [...saved, ...interested, ...rest, ...finished];
    activity.sort((a, b) => b.date.getTime() - a.date.getTime())
  

    return activity;
  
  }
  