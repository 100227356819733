import React, { cloneElement, Component, createRef } from 'react';
import styled, { keyframes } from 'styled-components';
import { fadeIn, fadeInLeftBig, fadeInRightBig } from 'react-animations';

const TabsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Wrapper = styled.div`
  background: #f3f3f3;
  border: solid 3px #f3f3f3;
  border-radius: 12px;
  position: relative;
  height: 40px;
  width: 100%;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  max-width: 100%;
  overflow: hidden;
`;

const Menu = styled.div`
  background: transparent;
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
  z-index: 5;
`;

export const Tab = styled.div`
  height: 100%;
  width: 100%;
  padding: 10px;
  display: flex;
  font-weight: 600;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #bababa;
  transition: ease-out 0.3s;

  &.active {
    color: #000;
  }
`;

const Indicator = styled.div`
  background: #fff;
  border-radius: 10px;
  padding: 10px;
  height: 100%;
  position: absolute;
  top: 0;
  box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
  transition: ease-in 0.2s;
`;

export class Tabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeItemIndex: 0,
      items: ['Description', 'Opportunities'],
      fade: '',
      indicatorParams: {
        width: null,
      },
      animate: false,
    };
    this.menuItemRef = createRef();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps !== this.props && this.state.animate) {
      this.setState({ animate: false });
    } else if (prevState !== this.state && !this.state.animate) {
      this.setState({ animate: true });
    }
  }

  componentDidMount() {
    if (this.menuItemRef && this.menuItemRef.current) {
      const {
        current: { scrollWidth },
      } = this.menuItemRef;
      this.setState({ indicatorParams: { width: scrollWidth } });
    }
  }

  onItemClick = (e, i) => {
    this.setState((state) => ({
      activeItemIndex: i,
      fade: state.activeItemIndex < i ? 'right' : 'left',
    }));
  };

  render() {
    const { items, transition } = this.props;
    const { indicatorParams, activeItemIndex, fade, animate } = this.state;
    if (this.props.items.length) {
      let Animation = styled.div`
        padding: 20px 0 0 0;
        width: 100%;
        animation: 0.4s
          ${keyframes`${
            fade === 'left'
              ? fadeInLeftBig
              : fade === 'right'
              ? fadeInRightBig
              : fadeIn
          }`};
      `;
      // if (!animate) {
      //   Animation = styled.div
      // }
      return (
        <TabsWrapper>
          <Wrapper>
            <Indicator
              style={{
                ...indicatorParams,
                left: activeItemIndex * indicatorParams.width,
              }}
            />
            <div>
              <Menu>
                {items.map((item, i) => {
                  let active = i === activeItemIndex ? 'active' : '';
                  return cloneElement(
                    item,
                    {
                      onClick: (e) => this.onItemClick(e, i),
                      className: active,
                      ref: this.menuItemRef,
                      key: i,
                    },
                    item.props.title
                  );
                })}
              </Menu>
            </div>
          </Wrapper>
          <ContentWrapper>
            <Animation key={activeItemIndex}>
              {items[activeItemIndex].props.children
                ? items[activeItemIndex].props.children
                : null}
            </Animation>
          </ContentWrapper>
        </TabsWrapper>
      );
    }
    return null;
  }
}

export default Tabs;
