import React, { useState } from 'react';
import styled from 'styled-components';
import {
  Bookmark28Filled as BookmarkedIcon,
  Bookmark28Regular as BookmarkIcon,
  ChevronRight28Regular as ChevronRightIcon,
} from '@fluentui/react-icons';
import { Link } from 'react-router-dom';
import { API } from '../../settings';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { getStudentSaved } from '../../redux/actions/student';

const Wrapper = styled(Link)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  box-shadow: 0 8px 25px 0 rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  margin: 0 0 20px 0;
  &:hover {
    text-decoration: none;
  }
`;
const ItemTitle = styled.h5`
  font-size: 16px;
  margin-bottom: 2px;
`;
const MetaData = styled.p`
  margin: 0;
  font-size: 12px;
  color: #afafaf;
`;

const BookmarkBtn = styled.button`
  color: #d4d4d4;
  transition: ease-out 0.3s;

  &.bookmarked {
    color: #ffc44e;
  }
`;

const ItemDataWrapper = styled.div`
  justify-self: flex-start;
  padding-left: 10px;
  width: 100%;
`;

function OpportunityListItem(props) {
  let {
    id,
    imgSrc,
    imgAlt,
    title,
    name,
    companyName,
    location,
    to,
    isBookmarked,
    linkState,
    onBookmark,
    hit,
  } = props;
  const [active, setState] = useState(false);
  console.log(props);
  const bookmark = (e, id, status) => {
    e.stopPropagation();
    e.preventDefault();
    onBookmark(id, status);
    const {
      user: { uuid },
    } = props;
    API({
      url: `/opportunities/saved/${id}?uuid=${uuid}`,
      method: status ? 'POST' : 'DELETE',
    }).then(() => {
      toast.success(`Opportunity ${status ? 'saved' : 'removed'}`, {
        style: {
          background: status ? '#ffc34e' : '#333333',
        },
      });
      props.getStudentSaved(1);
    });
  };

  if (hit) {
    id = hit.id;
    title = hit.title;
  }

  return (
    <Wrapper
      to={{
        pathname: to,
        state: linkState,
      }}
      onClick={() => setState(true)}
    >
      {/*<CompanyLogo src={imgSrc} alt={imgAlt} />*/}
      <ItemDataWrapper>
        <ItemTitle>{title}</ItemTitle>
        <MetaData>
          {companyName} • {location}
        </MetaData>
      </ItemDataWrapper>
      <BookmarkBtn
        className={`ln-btn ln-icon-btn ${isBookmarked ? 'bookmarked' : ''}`}
        onClick={(e) => bookmark(e, id, !isBookmarked)}
        title={isBookmarked ? 'Remove from saved' : 'Save this opportunity'}
      >
        {isBookmarked ? (
          <BookmarkedIcon className="ln-icon" />
        ) : (
          <BookmarkIcon className="ln-icon" />
        )}
      </BookmarkBtn>
    </Wrapper>
  );
}

export default connect(
  (state) => ({
    user: state.user,
  }),
  { getStudentSaved }
)(OpportunityListItem);
