import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const Pointer = styled.div`
  width: 20px;
  height: 20px;
  background-color: #fff;
  transform: rotate(45deg);
  border-radius: 5px;
  position: absolute;
  right: calc(50% - 10px);
  box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.1);
`;

export default function ConfirmLn({
  renderBtn,
  title,
  okText,
  noText,
  onOk,
  onNo,
  danger,
  position,
  description,
  upperMetaTitle,
  okBtnStyle,
}) {
  const wrapperRef = useRef(null);
  const [show, setState] = useState(false);
  const btn = React.cloneElement(renderBtn, {
    onClick: () => setState(!show),
  });
  useOutsideAlerter(wrapperRef, () => setState(false));
  return (
    <div
      ref={wrapperRef}
      className={`ln-dropdown-wrapper confirm ${show ? 'show' : 'hidden'}`}
    >
      {btn}
      <Pointer className="pointer" />
      <div
        className={`options-wrapper ${show ? 'show' : 'hidden'} ${position}`}
      >
        <p style={{ fontSize: '12px', color: '#818181' }}>{upperMetaTitle}</p>
        <h5 className="ln-dropdown title">{title}</h5>
        <p style={{ fontSize: '14px', color: '#44444f' }}>{description}</p>
        <div
          className="options"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <button
            onClick={() => {
              if (onNo) {
                onNo();
              }
              setState(false);
            }}
            className="ln-btn default"
          >
            {noText ? noText : 'No'}
          </button>
          <button
            onClick={() => {
              if (onOk) {
                onOk();
              }
              setState(false);
            }}
            className={`ln-btn ${danger ? 'danger' : 'primary'}`}
            style={okBtnStyle}
          >
            {okText ? okText : 'Yes'}
          </button>
        </div>
      </div>
    </div>
  );
}

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideAlerter(ref, callback) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
}
