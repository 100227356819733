import { EMPLOYER_OPPORTUNITIES_GET } from '../../constants/action-types';

const initialState = {
  loading: false,
  count: undefined,
  limit: 20,
  sortBy: 'createdAt',
  sort: 'desc',
  opportunities: [],
  searchValue: '',
};

function employerOpportunities(state = initialState, action) {
  switch (action.type) {
    case EMPLOYER_OPPORTUNITIES_GET:
      return { ...state, ...action.payload };
    default:
      break;
  }
  return state;
}

export default employerOpportunities;
