import { STUDENT_DASHBOARD_GET } from '../../constants/action-types';

const initialState = {
  loading: true,
};

function studentSaved(state = initialState, action) {
  switch (action.type) {
    case STUDENT_DASHBOARD_GET:
      return { ...state, ...action.payload };
    default:
      break;
  }
  return state;
}

export default studentSaved;
