import React from 'react';
import LaunchNowHeader from './components/shared/LaunchNowHeader';
import SignedOutWelcome from './login/SignedOutWelcome';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

function Home() {
  return (
    <Wrapper>
      <LaunchNowHeader />
      <SignedOutWelcome />
    </Wrapper>
  );
}

export default Home;
