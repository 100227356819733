import React from 'react';
import { Container, Row, Col, Table } from 'react-bootstrap';
import styled from 'styled-components';
import dotenv from 'dotenv';
import { API } from '../settings';

dotenv.config();

const PageHeader = styled.h3`
  margin-top: 6px;
`;

const ClusterPageHeader = styled(PageHeader)`
  margin-bottom: 25px;
`;

const SectionTitle = styled.h5`
  padding: 10px 0;
`;

const ClusterQuestionsTable = styled(Table)`
  margin-bottom: 25px;
`;

export default class ClusterQuestions extends React.Component {
  constructor(props) {
    super(props);

    this.state = { clusterQuestions: [], loading: false };
  }

  componentDidMount() {
    const assessmentKey = 'ASSESSMENT_1';

    API.get(`/assessments/${assessmentKey}`).then((response) => {
      const { assessment } = response.data;

      this.setState({
        clusterQuestions: assessment.clusterQuestions,
      });

      return response.data;
    });
  }

  render() {
    const clusters = () => {
      const clustersToDisplay = this.state.clusterQuestions.map(
        (cluster, index) => {
          return (
            <div>
              <SectionTitle>Cluster {index + 1}</SectionTitle>
              <ClusterQuestionsTable striped bordered hover>
                <thead>
                  <tr>
                    <th>{cluster.key}</th>
                  </tr>
                </thead>
                <tbody>{displayClusterQuestions(cluster.questions)}</tbody>
              </ClusterQuestionsTable>
            </div>
          );
        }
      );

      return <div>{clustersToDisplay}</div>;
    };

    const displayClusterQuestions = (questions) => {
      const questionsToDisplay = questions.map((question) => {
        return (
          <tr>
            <td>
              <code>{question}</code>
            </td>
          </tr>
        );
      });

      return questionsToDisplay;
    };

    return (
      <Container className="p-0 h-100 ">
        <Row>
          <Col xs={12}>
            <ClusterPageHeader>Questions per Cluster</ClusterPageHeader>
            {clusters()}
          </Col>
        </Row>
      </Container>
    );
  }
}
