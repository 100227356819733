//import { OPTIONS_GET_OPPORTUNITY_LOCATIONS } from '../../constants/action-types';

const initialState = {
  loading: false,
  APIdata: [
    {
      name: 'Remotely',
      value: 'REMOTE',
    },
    {
      name: 'Location',
      value: 'ONSITE',
    },
  ],
};

function categoriesReducer(state = initialState, action) {
  return state;
}

export default categoriesReducer;
