/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import ReactTooltip from 'react-tooltip';
import icon from '../images/cluster-icons/017-tooltip-question.svg';
import styled from 'styled-components';

const Icon = styled.img`
    height: 15px;
    margin-left: 8px;
`


export default function Tooltip({ id, children }) {
    return <>
        <a data-tip data-for={id}>
            <Icon src={icon} alt='' />
        </a>
        <ReactTooltip id={id} type="dark" effect="float">
            {children}
        </ReactTooltip>
    </>
}