import React from 'react';
import styled from 'styled-components';
import SubcategoryItem from './student/StudentCategory/SubcategoryItem';

const SubcategoriesListWrapper = styled.div`
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 6.25rem;
`;

const SubcategoryClusterName = styled.h3`
  margin-top: 1.5rem;
  font-size: 1.5rem;
`;

const Subcategory = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1.25rem;
  width: 100%;
  margin-right: 0.75rem;
  background-color: #ffffff;
  border-radius: 12px;
  padding: 0.5rem 0.875rem;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.1);
  overflow-wrap: break-word;
  word-wrap: break-word;

  &:hover,
  &:active {
    cursor: pointer;
  }
`;

const SubcategoryLeft = styled.div``;

const SubcategoryName = styled.div`
  font-size: 1rem;
  line-height: 1.375rem;
  color: #000000;
`;

const SubcategoryDetails = styled.div`
  color: #afafaf;
  font-size: 0.75rem;
  line-height: 1.25rem;
`;

const SubcategoryRight = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  color: #d4d4d4;
`;

const StudentDashboardSubcategoriesList = (props) => {
  const { percentDone, subcategories, toggleSubcategoryPanel } = props;
  let currentClusterName = '';
  let showNewClusterName = false;
  console.log(props);
  const subcategoriesList = (subcategories) => {
    return subcategories.map((subcategory, i) => {
      const displayClusterName = subcategory.clusterName;

      if (displayClusterName !== currentClusterName) {
        showNewClusterName = true;
        currentClusterName = displayClusterName;
      }
      props.rankedClusters.forEach((rankedCluster, i) => {
        if (rankedCluster.id === subcategory.clusterId) {
          subcategory.cluster = rankedCluster;
          subcategory.cluster.opportunitiesCountBySubcategoriesOfCategory.forEach(
            (item, i) => {
              if (item.subcategoryId === subcategory.id) {
                subcategory.opportunitiesCount = item.count;
              }
            }
          );
        }
      });
      return (
        <>
          {(() => {
            if (showNewClusterName) {
              showNewClusterName = false;
              return (
                <SubcategoryClusterName>
                  {currentClusterName}
                </SubcategoryClusterName>
              );
            }
          })()}
          <SubcategoryItem
            key={subcategory.id}
            cluster={subcategory.cluster}
            name={subcategory.name}
            description={subcategory.description}
            opportunitiesCount={subcategory.opportunitiesCount}
            subcategoryId={subcategory.id}
            meta={subcategory.clusterName}
            isSaved={subcategory.isSaved}
          />
          {/*<Subcategory key={name} onClick={() => toggleSubcategoryPanel(id)}>*/}
          {/*  <SubcategoryLeft>*/}
          {/*    <SubcategoryName>{name}</SubcategoryName>*/}
          {/*    <SubcategoryDetails>{clusterName}</SubcategoryDetails>*/}
          {/*  </SubcategoryLeft>*/}
          {/*  <SubcategoryRight>*/}
          {/*    <FontAwesomeIcon icon={faChevronRight} />*/}
          {/*  </SubcategoryRight>*/}
          {/*</Subcategory>*/}
        </>
      );
    });
  };

  if (percentDone > 98 && subcategories) {
    return (
      <SubcategoriesListWrapper>
        {subcategoriesList(subcategories)}
      </SubcategoriesListWrapper>
    );
  }

  return null;
};

export default StudentDashboardSubcategoriesList;
