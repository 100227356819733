import React from 'react';
import { Form } from 'react-bootstrap';
import styled from 'styled-components';
import { Formik } from 'formik';
import * as yup from 'yup';
import FormPasswordField from '../components/shared/forms/FormPasswordField';

const Button = styled.button`
  width: 100%;
  height: 55px;
  border-radius: 12px;
  color: #ffffff;
  background-color: #1a1a1a;
  border: none;
  margin-top: 40px;
  font-weight: bold;
`;

const validationSchema = yup.object({
  password: yup
    .string('Enter your password')
    .min(8, 'Password should be of minimum 8 characters length')
    .required('Password is required'),
  confirmPassword: yup.string().when('password', {
    is: (val) => (val && val.length > 0 ? true : false),
    then: yup
      .string()
      .oneOf([yup.ref('password')], 'Both passwords need to be the same'),
  }),
});

const ResetPasswordForm = (props) => {
  const { formIsSubmitting, handlePasswordSubmit } = props;

  return (
    <Formik
      validationSchema={validationSchema}
      onSubmit={(values) => {
        const { password, confirmPassword } = values;
        handlePasswordSubmit(password, confirmPassword);
      }}
      initialValues={{
        password: '',
        confirmPassword: '',
      }}
    >
      {({
        handleSubmit,
        handleChange,
        values,
        errors,
        isValid,
        isSubmitting,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <FormPasswordField
            controlId="validationFormik01"
            label="New Password"
            type="password"
            name="password"
          />
          <FormPasswordField
            controlId="validationFormik02"
            label="Confirm New Password"
            type="password"
            name="confirmPassword"
          />
          <Button
            disabled={!isValid || formIsSubmitting}
            variant="success"
            as="input"
            size="lg"
            type="submit"
            value="Submit"
          />
        </Form>
      )}
    </Formik>
  );
};

export default ResetPasswordForm;
