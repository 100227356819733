import React from 'react';
import { connect } from 'react-redux';
import { Col, Form as BootstrapForm, Row } from 'react-bootstrap';
import styled from 'styled-components';

import FlashSuccess from '../../FlashSuccess';

import { API } from '../../../settings';

import { Formik, useField } from 'formik';
import * as Yup from 'yup';
import EmployerProfileSubmitButton from './EmployerProfileSubmitButton';
import { toast } from 'react-toastify';
import { updateUser } from '../../../features/users/userSlice';

const PageTitle = styled.h2`
  color: #000000;
  font-size: 25px;
`;

const StyledLabel = styled.label`
  margin-top: 1rem;
  font-size: 14px;
`;

const StyledInput = styled.input`
  &.error {
    border: 1px solid red;
  }
`;

const StyledErrorMessage = styled.div`
  font-size: 14px;
  color: red;
  width: 400px;
  margin-top: 0.25rem;
`;

const ButtonWrapper = styled.div`
  padding-top: 15px;
`;

const MyTextInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <>
      <StyledLabel htmlFor={props.id || props.name} className="form-label">
        {label}
      </StyledLabel>
      <StyledInput
        className={`text-input form-control ${
          meta.touched && meta.error ? 'error' : null
        }`}
        {...field}
        {...props}
      />
      {meta.touched && meta.error ? (
        <StyledErrorMessage className="error">{meta.error}</StyledErrorMessage>
      ) : null}
    </>
  );
};

const PageWrapper = styled.div`
  min-height: 100vh;
  min-height: -webkit-fill-available;
  position: relative;
`;

const validationSchema = Yup.object({
  name: Yup.string().required('Required'),
  address: Yup.string().required('Required'),
  occupation: Yup.string().required('Required'),
  url: Yup.string().required('Required'),
});

class EmployerBusiness extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formIsSubmitting: false,
      employer: {},
      schools: [],
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFlashClick = this.handleFlashClick.bind(this);
  }

  getEmployer = () => {
    API.get(`/employers/users/${this.props.user.id}`).then((res) =>
      this.setState({ employer: res.data })
    );
  };

  componentDidMount() {
    this.setState({ employer: { ...this.props.user.userTypeProfile } });
  }

  handleFlashClick() {
    this.setState({ flashMessage: '' });
  }

  handleInputChange(e) {
    const value =
      e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    let { employer } = this.state;
    employer[e.target.name] = value;
    this.setState({
      employer,
    });
  }

  updateEmployerCompany({ employer }) {
    // TODO: SchoolIds are not returned in userTypeProfile in login response
    employer.schoolIds = this.props.user.employer.Schools;
    return API.put(`/employers/${this.state.employer.id}`, {
      userTypeProfile: {
        ...employer,
      },
    });
  }

  async handleSubmit(values, { resetForm, setSubmitting, setFieldError }) {
    const {
      user: { id: userId },
    } = this.props;
    const { name, address, occupation, url } = values;
    const employer = {
      name,
      address,
      occupation,
      url,
    };

    try {
      const response = await this.updateEmployerCompany({ userId, employer });
      if (response) {
        toast.success('Business info updated');
      }
      this.props.updateUser({
        userTypeProfile: { ...this.state.employer },
      });
      setSubmitting(false);
      // resetForm();
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        setFieldError('name', error.response.data.error);
        setFieldError('address', error.response.data.error);
        setFieldError('occupation', error.response.data.error);
        setFieldError('url', error.response.data.error);
      }

      setSubmitting(false);
    }

    this.setState({ formIsSubmitting: false });
  }

  render() {
    const {
      employer: { name, address, occupation, url },
    } = this.state;

    return (
      <PageWrapper>
        <Row>
          <Col xs={12}>
            <FlashSuccess
              flashMessage={this.flashMessage}
              handleFlashClick={this.handleFlashClick}
            />
            <Formik
              enableReinitialize
              initialValues={{
                name,
                address,
                occupation,
                url,
              }}
              validationSchema={validationSchema}
              onSubmit={this.handleSubmit}
            >
              <div>
                <PageTitle>Your business information</PageTitle>
                <BootstrapForm.Group controlId="companyName">
                  <MyTextInput
                    label="Company Name"
                    name="name"
                    value={name}
                    placeholder=""
                    onChange={this.handleInputChange}
                  />
                </BootstrapForm.Group>
                <BootstrapForm.Group controlId="address">
                  <MyTextInput
                    label="Company Address"
                    name="address"
                    value={address}
                    placeholder=""
                    onChange={this.handleInputChange}
                  />
                </BootstrapForm.Group>
                <BootstrapForm.Group controlId="occupation">
                  <MyTextInput
                    label="Company Occupation"
                    name="occupation"
                    value={occupation}
                    placeholder=""
                    onChange={this.handleInputChange}
                  />
                </BootstrapForm.Group>
                <BootstrapForm.Group controlId="url">
                  <MyTextInput
                    label="Company Website URI"
                    name="url"
                    value={url}
                    placeholder=""
                    onChange={this.handleInputChange}
                  />
                </BootstrapForm.Group>
                <BootstrapForm.Group>
                  <ButtonWrapper>
                    <EmployerProfileSubmitButton />
                  </ButtonWrapper>
                </BootstrapForm.Group>
              </div>
            </Formik>
          </Col>
        </Row>
      </PageWrapper>
    );
  }
}

const mapStateToProps = (state) => ({ user: state.user });

export default connect(mapStateToProps, { updateUser })(EmployerBusiness);
