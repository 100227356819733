import { combineReducers } from 'redux';
import dashboardReducer from './dashboard';
import studentSavedOpportunities from './saved';
import studentInterestedOpportunities from './interested';
import studentProfile from './profile';

const studentReducer = combineReducers({
  dashboard: dashboardReducer,
  savedOpportunities: studentSavedOpportunities,
  interestedOpportunities: studentInterestedOpportunities,
  studentProfile: studentProfile,
});

export default studentReducer;
