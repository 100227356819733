import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div``;

const Skill = styled.div`
  display: inline-block;
  padding: 0.75rem;
  background-color: #f2f3f5;
  margin: 0.5rem;
  border-radius: 0.875rem;
  font-size: 0.875rem;
  line-height: 0.875rem;
`;

const SubCategorySkillsCloud = (props) => {
  const { skills } = props;

  const displaySkillsCloud = (skills) => {
    if (skills) {
      return skills.map((skill, i) => {
        const { name } = skill;
        return <Skill key={name}>{name}</Skill>;
      });
    }

    return null;
  };

  return <Wrapper>{displaySkillsCloud(skills)}</Wrapper>;
};

export default SubCategorySkillsCloud;
