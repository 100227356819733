import React, { useState } from 'react';
import styled from 'styled-components';
import signOut from '../../images/sign-out.svg';
import SignoutPanel from '../SignoutPanel';


const Button = styled.img`
    height: 20px;
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 20px;
`;

export default function SignOut() {

    const [panelVisible, setPanelVisible] = useState(false);

    return <>
        <Button onClick={() => setPanelVisible(true)} src={signOut} />
        <SignoutPanel isVisible={panelVisible} togglePanel={() => setPanelVisible(v => !v)} />
    </>
    
}