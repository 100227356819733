import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const List = styled.div`
  text-align: left;
  margin-top: 0.75rem;
`;

const ListItem = styled.div`
  display: flex;
  padding-bottom: 1.25rem;
  width: 100%;
  margin: 0;
  font-size: 1rem;
  line-height: 1rem;
  color: #000000;
  font-weight: bold;
`;

const ItemName = styled.div`
  flex: 1;
`;

const ItemCount = styled.div``;

const SubcategoriesList = (props) => {
  const { subcategories } = props;

  const displaySubcategories = (subcategories) => {
    if (subcategories.length === 0) {
      return (
        <p>
          <em>no results yet</em>
        </p>
      );
    }

    if (subcategories) {
      return subcategories.map((subcategory, i) => {
        return (
          <ListItem key={`${subcategory}-${i}`}>
            <ItemName>{subcategory.name}</ItemName>
            <ItemCount>{subcategory.count}</ItemCount>
          </ListItem>
        );
      });
    }

    return null;
  };

  return <List>{displaySubcategories(subcategories)}</List>;
};

export default SubcategoriesList;

SubcategoriesList.propTypes = {
  subcategories: PropTypes.array.isRequired,
};
