import React from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Form } from 'react-bootstrap';
import styled from 'styled-components';
import LaunchNowHeader from '../components/shared/LaunchNowHeader';
import AuthService from '../services/authService';
import { Redirect } from 'react-router-dom';
import { logout } from '../features/users/userSlice';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const Box = styled.div`
  margin-top: 65px;
  width: 330px;
`;

const Button = styled.button`
  width: 100%;
  height: 55px;
  border-radius: 12px;
  color: #ffffff;
  background-color: #1a1a1a;
  border: none;
  margin-top: 40px;
`;

const Label = styled(Form.Label)`
  font-size: 14px;
  color: #1a1a1a;
`;

const FormControl = styled(Form.Control)`
  border: 1px solid #ebebeb;
  height: 2.75rem;
  ::placeholder {
    color: #afafaf;
    opacity: 1;
    font-weight: 300;
  }
`;

class ActivateStudentAccount extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      password: '',
      message: '',
      loading: false,
      isLoggedIn: false,
      uuid: '',
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { logout, user } = this.props;
    const { activationToken } = this.props.match.params;

    if (user.isLoggedIn && user.userType && user.userType !== 'STUDENT') {
      logout(user.id);
      this.setState({ activationToken, isLoggedIn: false });
    } else if (
      user.isLoggedIn &&
      user.userType &&
      user.userType === 'STUDENT'
    ) {
      this.setState({ isLoggedIn: true });
    } else {
      this.setState({ activationToken });
    }
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    const { activationToken } = this.state;

    this.setState({
      message: '',
      loading: true,
    });

    AuthService.activateStudent(activationToken, this.state.password).then(
      () => {
        this.setState({ isLoggedIn: true, loading: false });
      },
      (error) => {
        console.log('ERROR');
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        console.log(resMessage);

        this.setState({
          loading: false,
          message: resMessage,
        });
      }
    );
  }

  render() {
    const { isLoggedIn } = this.state;
    if (isLoggedIn) {
      return <Redirect to="/dashboard" />;
    } else {
      return (
        <Container fluid className="p-0 h-100 ">
          <Row className="h-100">
            <Col lg={6}>
              <LaunchNowHeader />
              <Wrapper>
                <Box>
                  <p>
                    Welcome to LaunchNow! Please enter a password to finish
                    creating your account...
                  </p>
                  <Form onSubmit={this.handleSubmit}>
                    <Form.Group controlId="formBasicPassword">
                      <Label>Password</Label>
                      <FormControl
                        type="password"
                        name="password"
                        placeholder="Password"
                        onChange={this.handleInputChange}
                      />
                    </Form.Group>
                    <Button variant="primary" type="submit">
                      Activate
                    </Button>
                  </Form>
                </Box>
              </Wrapper>
            </Col>
          </Row>
        </Container>
      );
    }
  }
}

const mapStateToProps = (state) => ({ user: state.user });
const mapDispatchToProps = { logout };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivateStudentAccount);
