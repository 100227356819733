import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import CircularProgressBar from '../shared/progress/CircularProgressBar';

const AssessmentStatusWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h6`
  font-size: 1rem;
`;

const ProgressWrapper = styled.div`
  margin-top: 20px;
`;

const CompletionStatus = styled.div`
  font-weight: bold;
  margin-top: 1rem;
`;

const StudentAssessmentStatus = (props) => {
  const { percentDone } = props;

  let completionStatusText = 'Not Complete';

  if (percentDone === 100) {
    completionStatusText = 'Completed';
  }

  return (
    <>
      <Title>Assessment Status</Title>
      <AssessmentStatusWrapper>
        <ProgressWrapper>
          <CircularProgressBar percent={percentDone} />
        </ProgressWrapper>
        <CompletionStatus>{completionStatusText}</CompletionStatus>
      </AssessmentStatusWrapper>
    </>
  );
};

export default StudentAssessmentStatus;

StudentAssessmentStatus.propTypes = {
  percentDone: PropTypes.number.isRequired,
};
