import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { ChevronRight20Regular as ChevronRightIcon } from '@fluentui/react-icons';

const NavListItemStyled = styled.li`
  display: block;
  width: 100%:
  margin: 0;
  padding: 0;
`;

const NavListItemLinkStyled = styled(NavLink)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 600;
  color: #858585;
  padding: 10px;
  transition: ease-out 0.1s;
  border-radius: 10px;
  width: 100%;
  &.selected {
    color: #000;
    background-color: #f4f4f4;
    .ln-icon {
      visibility: visible;
    }
  }
  &:hover {
    text-decoration: none;
    // color: #000;
    // background-color: #f4f4f4;
    .ln-icon {
      visibility: visible;
    }
  }
  .ln-icon {
    float: right;
    visibility: hidden;
  }
`;

export default function NavListItem({ url, text, exact, rightIcon }) {
  return (
    <NavListItemStyled>
      <NavListItemLinkStyled to={url} activeClassName="selected" exact={exact}>
        <span>{text}</span>
        {/* <FontAwesomeIcon className='ln-icon' icon={faChevronRight} /> */}
        {rightIcon ? rightIcon : <ChevronRightIcon className="ln-icon" />}
      </NavListItemLinkStyled>
    </NavListItemStyled>
  );
}
