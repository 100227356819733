import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import StudentAssessmentStatus from './StudentAssessmentStatus';
import StudentSkills from './StudentSkills';
import StudentTopClusters from './StudentTopClusters';

const Panel = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px;
  height: 345px;
  width: 100%;
  border-radius: 20px;
  background: #ffffff;
`;

const Left = styled.div`
  flex: 1;
`;

const Center = styled(Left)``;
const Right = styled(Left)``;

const StudentAssessmentPanel = (props) => {
  const { percentDone, rankedClusterNames, skills } = props;

  return (
    <Panel>
      <Left>
        <StudentAssessmentStatus percentDone={percentDone} />
      </Left>
      <Center>
        <StudentSkills skills={skills} />
      </Center>
      <Right>
        <StudentTopClusters rankedClusterNames={rankedClusterNames} />
      </Right>
    </Panel>
  );
};

export default StudentAssessmentPanel;

StudentAssessmentPanel.propTypes = {
  percentDone: PropTypes.number.isRequired,
  skills: PropTypes.array.isRequired,
  rankedClusterNames: PropTypes.array.isRequired,
};
