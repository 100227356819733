import React from 'react';
import { Container, Row, Col, Modal } from 'react-bootstrap';
import styled from 'styled-components';
import dotenv from 'dotenv';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { API } from '../../settings';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { compareSchoolNames } from '../../utils/arrayOps';
import AddSchool from './AddSchool';

dotenv.config();

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const PageHeader = styled.h3`
  margin-top: 6px;
  margin-bottom: 15px;
`;

const PlusIcon = styled(FontAwesomeIcon)`
  font-size: 0.875rem;
  font-weight: bold;
  margin-right: 0.5rem;
  color: #858585;
`;

const AddSchoolButton = styled.button`
  background: #333333;
  height: auto;
  border-radius: 10px;
  font-size: 0.875rem;
  color: #fafafa;
  font-weight: bold;
  border: none;
  padding: 0.5rem 0.625rem;
`;

const StudentsWrapper = styled.div`
  background: #ffffff;
  padding: 1.5rem;
  border-radius: 1.25rem;
  table,
  th,
  td {
    border: none;
  }
  thead {
    th {
      border: none;
    }
  }
  tbody:before {
    content: '@';
    display: block;
    line-height: 0.5rem;
    text-indent: -99999px;
  }
  td {
    font-size: 0.875rem;
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
  }
  td.firstName,
  td.lastName {
    font-size: 1rem;
    font-weight: bold;
    background: none;
  }
  td.grade,
  td.percentDone {
    span {
      background: #fbfbfb;
      display: inline-block;
      padding: 0.2rem 1rem;
      border-radius: 8px;
    }
  }
`;

const FilterWrapper = styled.div`
  position: relative;
`;

const Filter = styled.input`
  margin-bottom: 1.25rem;
  width: 100%;

  font-size: 0.875rem;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  padding-left: 2.25rem;
  border-radius: 0.625rem;
  border: 1px solid #e2e2ea;
  font-family: 'Nunito';
  &::placeholder {
    color: #929292;
    opacity: 1;
  }
`;

const SearchIcon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 14px;
  left: 0.625rem;
  color: #92929d;
`;

const BootstrapTableWrapper = styled.div`
  .table td {
    overflow-wrap: break-word;
  }
  .table th {
    background: #fbfbfb;
    color: #858585;
    font-size: 0.6875rem;
    &:first-child {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }
    &:last-child {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }
  .react-bootstrap-table-pagination {
    margin-top: 2rem;
    justify-content: center;
    div {
      display: none;
    }
    div.react-bootstrap-table-pagination-list {
      display: flex;
      justify-content: center;
      ul {
        float: none;
      }
    }
    .page-link {
      border-color: #797878;
      color: #797878;
    }
    .page-item.active .page-link {
      color: #ffffff;
      background-color: #000000;
      border-color: #000000;
    }
  }
`;

export default class Schools extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: '',
      filteredSchools: [],
      loading: false,
      schools: [],
      states: [],
      showAddSchool: false,
    };

    this.getSchools = this.getSchools.bind(this);
    this.getStates = this.getStates.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleShowAddSchool = this.handleShowAddSchool.bind(this);
    this.handleCloseAddSchool = this.handleCloseAddSchool.bind(this);
    this.handleAddSchool = this.handleAddSchool.bind(this);
  }

  componentDidMount() {
    this.getSchools();
    this.getStates();
  }

  getSchools = () => {
    API.get(`/schools/all`)
      .then((response) => {
        this.setState({
          filteredSchools: response.data.schools,
          schools: response.data.schools,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getStates = () => {
    API.get(`/location/states`)
      .then((response) => {
        this.setState({
          states: response.data.states,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleFilterChange = (event) => {
    const { schools } = this.state;
    const filter = event.target.value;

    const filteredByName = schools.filter((school) =>
      school.name.toLowerCase().includes(filter.toLowerCase())
    );

    const filteredSchools = [...filteredByName].sort(compareSchoolNames);

    this.setState({ filter, filteredSchools });
  };

  handleShowAddSchool = () => {
    this.setState({ showAddSchool: true });
  };

  handleCloseAddSchool = () => {
    this.setState({ showAddSchool: false });
  };

  handleAddSchool = (values) => {
    const { name, address1, city, stateCode, zipcode } = values;

    API.post(`/schools/`, {
      name,
      address1,
      city,
      stateCode,
      zipcode,
    })
      .then((response) => {
        this.handleCloseAddSchool();
        this.getSchools();
        toast.success(`${name} added`);
      })
      .catch((error) => {
        console.log(error);
        toast.error(`There was an error adding ${name}`);
      });
  };

  render() {
    const { filter, filteredSchools, showAddSchool, states } = this.state;

    const nameFormatter = (cell, row) => {
      return <Link to={`/super-admin/schools/${row.id}`}>{cell}</Link>;
    };

    const columns = [
      {
        text: 'Name',
        dataField: 'name',
        sort: true,
        formatter: nameFormatter,
        classes: 'name',
      },
      {
        text: 'Address',
        dataField: 'address1',
        sort: false,
        classes: 'address1',
      },
      {
        text: 'City',
        dataField: 'city',
        sort: true,
        classes: 'city',
      },
      {
        text: 'State',
        dataField: 'stateCode',
        sort: true,
        classes: 'stateCode',
      },
      {
        text: 'Zip Code',
        dataField: 'zipcode',
        sort: false,
        classes: 'zipcode',
      },
    ];

    const paginationConfig = {
      sizePerPage: 100,
      hideSizePerPage: true,
    };

    return (
      <Container className="p-0 h-100">
        <Row>
          <Col xs={12}>
            <HeaderWrapper>
              <PageHeader>Schools</PageHeader>
              <AddSchoolButton onClick={() => this.handleShowAddSchool()}>
                <PlusIcon icon={faPlus} size="1x"></PlusIcon>Add School
              </AddSchoolButton>
              <AddSchool
                handleCloseAddSchool={this.handleCloseAddSchool}
                showAddSchool={showAddSchool}
                handleAddSchool={this.handleAddSchool}
                states={states}
              />
            </HeaderWrapper>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <StudentsWrapper>
              <FilterWrapper>
                <Filter
                  type="text"
                  value={filter}
                  placeholder="Search by Name..."
                  onChange={this.handleFilterChange}
                />
                <SearchIcon icon={faSearch} size="1x" />
              </FilterWrapper>
              <BootstrapTableWrapper>
                <BootstrapTable
                  columns={columns}
                  data={filteredSchools}
                  keyField="id"
                  pagination={paginationFactory(paginationConfig)}
                />
              </BootstrapTableWrapper>
            </StudentsWrapper>
          </Col>
        </Row>
      </Container>
    );
  }
}
