import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

function CreateAccount() {
  return (
    <Container fluid className="p-0 h-100 ">
      <Row className="h-100">
        <Col lg={6}>
          <div>TODO: Create Account</div>
        </Col>
        <Col lg={6} className="d-none d-lg-block landing-right-col"></Col>
      </Row>
    </Container>
  );
}

export default CreateAccount;
