import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
`;

const Box = styled.div`
  width: 330px;
`;

const Welcome = styled.h2`
  font-size: 52px;
  line-height: 52px;
`;

const SignInText = styled.p`
  margin-top: 28px;
  margin-bottom: 28px;
`;

const Button = styled.button`
  width: 100%;
  height: 55px;
  border-radius: 12px;
  color: #ffffff;
  background-color: #1a1a1a;
  border: none;
`;

const SignedOutWelcome = () => {
  return (
    <Wrapper>
      <Box>
        <Welcome>Welcome!</Welcome>
        <SignInText>Sign in to continue</SignInText>
        <Link to="/sign-in">
          <Button>Sign In</Button>
        </Link>
      </Box>
    </Wrapper>
  );
};

export default SignedOutWelcome;
