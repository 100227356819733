import { combineReducers } from 'redux';
import opportunityCategories from './opportunityCategories';
import opportunityLocations from './opportunityLocations';
import statesReducer from './states';
import employmentTypes from './employmentTypes';

const optionsReducer = combineReducers({
  opportunityCategories,
  opportunityLocations,
  employmentTypes,
  states: statesReducer,
});

export default optionsReducer;
