import {
  STUDENT_DASHBOARD_GET,
  STUDENT_INTERESTED_GET,
  STUDENT_PROFILE_GET,
  STUDENT_SAVED_GET,
} from '../constants/action-types';
import { API } from '../../settings';

export function getStudentDashboard() {
  return function (dispatch, getState) {
    dispatch({
      type: STUDENT_DASHBOARD_GET,
      payload: { loading: true },
    });
    const {
      user: { uuid },
    } = getState();
    return API.get(`/students/${uuid}/dashboard`).then((res) =>
      dispatch({
        type: STUDENT_DASHBOARD_GET,
        payload: { loading: false, ...res.data },
      })
    );
  };
}

export function getStudentSaved(page, newArr) {
  return function (dispatch, getState) {
    if (newArr) {
      dispatch({
        type: STUDENT_SAVED_GET,
        payload: { savedOpportunities: newArr },
      });
    } else {
      dispatch({
        type: STUDENT_SAVED_GET,
        payload: { loading: true, page },
      });
      let {
        user: { uuid },
        student: {
          savedOpportunities: { savedOpportunities, limit },
        },
      } = getState();
      API.get(
        `/opportunities/saved?page=${page}&limit=${limit}&sort=asc&uuid=${uuid}`
      )
        .then((res) => {
          savedOpportunities = savedOpportunities.concat(
            res.data.savedOpportunities
          );
          dispatch({
            type: STUDENT_SAVED_GET,
            payload: { loading: false, ...res.data, count: res.data.count },
          });
        })
        .finally(() =>
          dispatch({
            type: STUDENT_SAVED_GET,
            payload: { loading: false },
          })
        );
    }
  };
}

export function getStudentInterested(page, newArr) {
  return function (dispatch, getState) {
    if (newArr) {
      const {
        student: {
          interestedOpportunities: { count },
        },
      } = getState();
      dispatch({
        type: STUDENT_INTERESTED_GET,
        payload: {
          loading: false,
          interestedOpportunities: newArr,
          count: count - 1,
        },
      });
    } else {
      dispatch({
        type: STUDENT_INTERESTED_GET,
        payload: { loading: true, page },
      });
      let {
        user: { uuid },
        student: {
          interestedOpportunities: { interestedOpportunities, limit },
        },
      } = getState();
      API.get(
        `/opportunities/interested?page=${page}&limit=${limit}&sort=asc&uuid=${uuid}`
      )
        .then((res) => {
          interestedOpportunities = interestedOpportunities.concat(
            res.data.interestedOpportunities
          );
          dispatch({
            type: STUDENT_INTERESTED_GET,
            payload: { loading: false, ...res.data, count: res.data.count },
          });
        })
        .finally(() =>
          dispatch({
            type: STUDENT_INTERESTED_GET,
            payload: { loading: false },
          })
        );
    }
  };
}

export function getStudentProfile() {
  return async function (dispatch, getState) {
    dispatch({
      type: STUDENT_PROFILE_GET,
      payload: { loading: true },
    });
    let {
      user: { uuid },
    } = getState();
    const studentProfileData = await API.get(
      `/users/${uuid}/student-profile-data`
    );
    const studentProfile = await API.get(`/students/${uuid}/profile`);
    const { rankedClusters } = studentProfile.data;
    const skills = [];

    rankedClusters.forEach((cluster) => {
      cluster.skills.forEach((skill) => {
        if (!skills.includes(skill.name)) {
          skills.push(skill.name);
        }
      });
    });
    dispatch({
      type: STUDENT_PROFILE_GET,
      payload: {
        loading: false,
        ...studentProfileData.data,
        rankedClusters,
        skills,
      },
    });
  };
}
