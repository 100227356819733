import React from 'react';
import styled from 'styled-components';
import { INNER_MARGIN } from './default-styles';

const TabBarBackground = styled.div`
    background-color: #f5f5f5;
    border-radius: 10px;
    padding: 5px;
    display: flex;
    height: 35px;
    ${INNER_MARGIN};
    margin-bottom: 25px;
`;

const ToggleButton = styled.div`
    background-color: white;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    font-size: 13px;
    cursor: pointer;
`;

function TabButton({tab, setTab, children}) {

    const selected = tab === children;

    const style = selected ? 
        {   
            boxShadow: '1px 1px 5px rgba(0,0,0,0.2)',
            pointerEvents: 'none',
        } : 
        { 
            backgroundColor: 'transparent', 
            opacity: 0.5, 
        };

    return <ToggleButton onClick={() => setTab(children)} style={style}>
        <div>{children}</div>
    </ToggleButton>

}

export default function TabBar({ optionA, optionB, style = {}, ...props } ) {    
    return <TabBarBackground style={style}>
        <TabButton {...props}>{ optionA }</TabButton>
        <TabButton {...props}>{ optionB }</TabButton>
    </TabBarBackground>
}
