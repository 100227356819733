import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

const Flash = styled.div`
  background-color: #45d191;
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 1.25rem;
  color: #ffffff;
  font-size: 1rem;
  line-height: 1rem;
  text-align: center;
  border-bottom-left-radius: 23px;
  border-bottom-right-radius: 23px;
  box-shadow: 1px 3px 5px 3px #cdf3e1;
`;

const FlashSuccess = (props) => {
  const { flashMessage, handleFlashClick } = props;

  if (flashMessage && flashMessage !== '') {
    return (
      <Flash
        onClick={() => {
          handleFlashClick();
        }}
      >
        <FontAwesomeIcon icon={faCheckCircle} /> {flashMessage}
      </Flash>
    );
  } else {
    return null;
  }
};

export default FlashSuccess;
