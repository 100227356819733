import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const BackLink = styled.div`
  color: #ffffff;
  display: flex;
  cursor: pointer;
  align-items: center;
  background-color: #333333;
  font-weight: bold;
  font-size: 0.75rem;
  line-height: 0.75rem;
  &:hover {
    color: #fbfbfb;
  }
  padding: 0.5rem 0.75rem;
  border-radius: 0.5rem;
  &:hover {
    text-decoration: none;
  }
`;

const ArrowWrapper = styled.span`
  display: inline-block;
  color: #858585;
  font-size: 0.75rem;
  padding-right: 0.25rem;
`;

const BackButton = ({ ...props}) => {
  return (
    <BackLink {...props}>
      <ArrowWrapper>
        <FontAwesomeIcon icon={faArrowLeft} />
      </ArrowWrapper>{' '}
      Back
    </BackLink>
  );
};

export default BackButton;
