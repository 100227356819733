import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark as fasBookmark } from '@fortawesome/free-solid-svg-icons';
import { API } from '../settings';

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const PageTitle = styled.h2`
  color: #000000;
  font-size: 25px;
  margin-bottom: 0;
  padding-bottom: 0;
`;

const FavoritesCount = styled.div`
  float: right;
  margin-top: auto;
`;

const PageWrapper = styled.div`
  min-height: 100vh;
  min-height: -webkit-fill-available;
  height: 100vh;
  padding: 25px 0 25px;
  position: relative;
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const PageLiner = styled.div`
  padding: 0 25px 25px;
  overflow-y: auto;
  &.noScroll {
    overflow-y: hidden;
  }
`;

const Subcategory = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1.25rem;
  width: 100%;
  margin-right: 0.75rem;
  background-color: #ffffff;
  border-radius: 12px;
  padding: 0.5rem 0.875rem;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.1);
  overflow-wrap: break-word;
  word-wrap: break-word;
  &:hover,
  &:active {
    cursor: pointer;
  }
`;

const SubcategoryLeft = styled.div``;

const SubcategoryName = styled.div`
  font-size: 1rem;
  line-height: 1.375rem;
  color: #000000;
`;

const SubcategoryDetails = styled.div`
  color: #afafaf;
  font-size: 0.75rem;
  line-height: 1.25rem;
`;

const SubcategoryRight = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  color: #ffc34e;
  padding: 0 20px;
  margin-right: -20px;
`;

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  width: 100%;
`;

const LoadingSpinner = styled(Spinner)`
  height: 50px;
  width: 50px;
`;

const Favorites = (props) => {
  const { user } = props;
  const { accessToken, uuid } = user;
  const [favorites, setFavorites] = useState([]);
  const [loading, setLoading] = useState(true);

  const requestConfig = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  useEffect(() => {
    API.get(`/students/${uuid}/favorites`, requestConfig)
      .then((response) => {
        setFavorites(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const removeFavoriteSubcategory = (id, name) => {
    const favoritesAfterDelete = favorites.filter(
      (favorite) => favorite.id !== id
    );

    setFavorites(favoritesAfterDelete);

    API.delete(`/students/${uuid}/subcategories/saved/${id}`)
      .then(function (response) {
        toast.info(`${name} removed`);
      })
      .catch(function (error) {
        console.log(error);
      });
    console.log(`remove: ${id}`);
  };

  const savedSubcategoriesList = (favorites) => {
    return favorites.map((subcategory, i) => {
      const { clusterName, id, name } = subcategory;

      return (
        <div key={name}>
          <Subcategory key={name}>
            <SubcategoryLeft>
              <SubcategoryName>{name}</SubcategoryName>
              <SubcategoryDetails>{clusterName}</SubcategoryDetails>
            </SubcategoryLeft>
            <SubcategoryRight
              onClick={() => removeFavoriteSubcategory(id, name)}
            >
              <FontAwesomeIcon icon={fasBookmark} />
            </SubcategoryRight>
          </Subcategory>
        </div>
      );
    });
  };

  if (loading) {
    return (
      <SpinnerWrapper>
        <LoadingSpinner animation="border" variant="secondary" />
      </SpinnerWrapper>
    );
  } else {
    return (
      <PageWrapper>
        <PageLiner>
          <TitleWrapper>
            <PageTitle>Favorites</PageTitle>
            <FavoritesCount>{favorites.length}</FavoritesCount>
          </TitleWrapper>
          {savedSubcategoriesList(favorites)}
        </PageLiner>
      </PageWrapper>
    );
  }
};

export default Favorites;
