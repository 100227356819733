import React from 'react';
import styled from 'styled-components';
import AssessmentStatus from '../shared/assessment-status';
import { SPACING } from '../shared/default-styles';

const ActivityDescription = styled.p`
    margin-top: 10px;
    padding-left: ${SPACING};
`;

const AssessmentStartWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export default function AssessmentStart({ name, percent }) {
    
    return <AssessmentStartWrapper>
        <ActivityDescription>{name} started the assessment</ActivityDescription>
        <AssessmentStatus style={{ padding: `0px ${SPACING}` }} percent={percent} hideText />
    </AssessmentStartWrapper> 
}
