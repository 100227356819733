import { OPTIONS_GET_STATES } from '../../constants/action-types';

const initialState = {
  loading: false,
  count: null,
  APIdata: [],
};

function statesReducer(state = initialState, action) {
  switch (action.type) {
    case OPTIONS_GET_STATES:
      return Object.assign({}, state, {
        APIdata: action.payload.states,
        count: action.payload.count,
        loading: false,
      });
    default:
      break;
  }
  return state;
}

export default statesReducer;
