import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
const RatingWrapper = styled.div`
  width: min-content;
  display: flex;
  background-color: #f8f8f8;
  border-radius: 10px;
  padding: 5px 9px;
  align-items: center;
  * {
  }
  .ln-icon {
    height: 13px;
    margin-right: 5px;
  }
  h5 {
    font-size: 14px;
    margin-bottom: -2px;
  }
`;

export default function RatingLn({ rating }) {
  return (
    <RatingWrapper>
      <span>
        <FontAwesomeIcon color="#ffc34e" className="ln-icon" icon={faStar} />
      </span>
      <h5>{rating}</h5>
    </RatingWrapper>
  );
}
