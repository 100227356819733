import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faBan } from '@fortawesome/free-solid-svg-icons';

const CellWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding: 0.25rem 0.5rem;
  background: #fbfbfb;
  progress {
    width: 100%;
    appearance: none;
    height: 0.5rem;
    border-radius: 25px;
    background-color: #f2f3f5;

    ::-webkit-progress-bar {
      height: 0.5rem;
      border-radius: 25px;
      background-color: #f2f3f5;
    }

    ::-moz-progress-bar {
      height: 0.5rem;
      border-radius: 25px;
      background-color: #f2f3f5;
    }

    ::-webkit-progress-value {
      height: 10px;
      border-radius: 25px;
      background-color: #ffc34e;
    }
  }

  &.notStarted {
    progress {
      ::-webkit-progress-value {
        background-color: #fc5a5a;
      }
    }
  }

  &.completed {
    progress {
      ::-webkit-progress-value {
        background-color: #3dd598;
      }
      ::-moz-progress-bar {
        background-color: #3dd598;
      }
    }
  }
`;

const ProgressWrapper = styled.div`
  display: flex;
  width: 90%;
  align-items: center;
`;

const ProgressValue = styled.div`
  ${'' /* background: cyan; */}
  padding: 0 0.5rem;
  &.notStarted {
    color: #fc5a5a;
  }
  &.completed {
    color: #3dd598;
  }
`;

const StudentPercentDone = (props) => {
  const { percentDone } = props;

  let percentDoneForDisplay = percentDone;

  // If percentDone is 0, we need to increase the value to get
  // the progress bar to show a little color.
  if (percentDone === 0) {
    percentDoneForDisplay = 2;
  }

  let progressClassName = 'notStarted';
  let progressValueDisplay = <FontAwesomeIcon icon={faBan} size="1x" />;

  if (percentDone === 100) {
    progressClassName = 'completed';
    progressValueDisplay = <FontAwesomeIcon icon={faCheckCircle} size="1x" />;
  } else if (percentDone > 0) {
    progressClassName = 'started';
    progressValueDisplay = `${percentDone}%`;
  }

  return (
    <CellWrapper className={progressClassName}>
      <ProgressWrapper>
        <progress value={percentDoneForDisplay} max={100} />
      </ProgressWrapper>
      <ProgressValue className={progressClassName}>
        {progressValueDisplay}
      </ProgressValue>
    </CellWrapper>
  );
};

export default StudentPercentDone;
