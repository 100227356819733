import { STUDENT_SAVED_GET } from '../../constants/action-types';

const initialState = {
  loading: true,
  savedOpportunities: [],
  count: undefined,
  page: 1,
  limit: 20,
};

function studentSavedOpportunities(state = initialState, action) {
  switch (action.type) {
    case STUDENT_SAVED_GET:
      return { ...state, ...action.payload };
    default:
      break;
  }
  return state;
}

export default studentSavedOpportunities;
