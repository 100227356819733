import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import StudentDashboard from './StudentDashboard';

const Dashboard = (props) => {
  const { user } = props;
  const { userType } = user;

  if (!user || !user.isLoggedIn) {
    return <Redirect to="/" />;
  }

  switch (userType) {
    case 'COUNSELOR':
      return <Redirect to="/counselor/dashboard" />;
    case 'SUPER_ADMIN':
      return <Redirect to="/super-admin/dashboard" />;
    case 'EMPLOYER':
      return <Redirect to="/employer/dashboard" />;
    case 'PARENT':
      return <Redirect to="/parent/children" />;
    default:
      return <StudentDashboard />;
  }
};

const mapStateToProps = (state) => ({ user: state.user });
export default connect(mapStateToProps)(Dashboard);
