import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { toast } from 'react-toastify';
import { Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import StudentInfoUpdateForm from './StudentInfoUpdateForm';
import { login, updateStudent } from '../features/users/userSlice';
import { API } from '../settings';

const PageWrapper = styled.div`
  min-height: 100vh;
  min-height: -webkit-fill-available;
  padding: 25px;
  margin: 0 15px;
`;

const SettingsHeader = styled.div`
  text-align: left;
  width: 100%;
`;

const BackLink = styled(Link)`
  color: #000000;
  display: inline-block;
  font-size: 14px;
  display: inline-block;
  margin-top: -10px;
  padding: 10px 50px 10px 0;
  &:hover {
    color: #000000;
  }
`;

const PageTitle = styled.h2`
  color: #000000;
  font-size: 25px;
  margin-top: 15px;
`;

const StyledRow = styled(Row)``;

class StudentInfoUpdate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formIsSubmitting: false,
      loading: false,
      user: {},
    };

    this.handleInfoUpdate = this.handleInfoUpdate.bind(this);
    this.updateStudentInfo = this.updateStudentInfo.bind(this);
  }

  componentDidMount() {
    const { user } = this.props;
    this.setState({ user: user });
  }

  updateStudentInfo(data) {
    const { updateStudent } = this.props;

    // Update Redux
    updateStudent(data);
  }

  handleInfoUpdate(values) {
    const { updateStudent, user } = this.props;
    const { firstName, lastName } = values;

    API.patch(`/students/${user.uuid}`, {
      firstName,
      lastName,
    })
      .then(
        function (response) {
          console.log('response');
          console.log(response);
          // Fire callback to update Redux store
          updateStudent(values);
          toast.success('Info updated');
          // setSubmitting(false);
          this.setState({ formIsSubmitting: false });
        }.bind(this)
      )
      .catch(
        function (error) {
          toast.error('There was an error');
          console.log(error);
          // setSubmitting(false);
          this.setState({ formIsSubmitting: false });
        }.bind(this)
      );
  }

  render() {
    const { user } = this.props;

    return (
      <PageWrapper>
        <StyledRow>
          <SettingsHeader>
            <BackLink to="/settings">
              <FontAwesomeIcon icon={faChevronLeft} />
            </BackLink>
          </SettingsHeader>
          <PageTitle>My Info</PageTitle>
          <StudentInfoUpdateForm
            user={user}
            handleInfoUpdate={this.handleInfoUpdate}
          />
        </StyledRow>
      </PageWrapper>
    );
  }
}

const mapStateToProps = (state) => ({ user: state.user });
const mapDispatchToProps = { login, updateStudent };

export default connect(mapStateToProps, mapDispatchToProps)(StudentInfoUpdate);
