import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { connect } from 'react-redux';

const SectionTitle = styled.h6`
  color: #000000;
  margin-bottom: 2rem;
`;

function ReviewBeforePosting(props) {
  const {
    values: {
      title,
      clusterId,
      subcategoryId,
      performedAt,
      employmentType,
      location,
      stateId,
      description,
      availablePositions
    },
    opportunityCategories,
    employmentTypes,
  } = props;

  let subcategoryName = '';
  const categoryName = (() => {
    if (Array.isArray(opportunityCategories)) {
      for (let i = 0; i < opportunityCategories.length; i++) {
        if (opportunityCategories[i].id == clusterId) {
          if (Array.isArray(opportunityCategories[i].subcategories)) {
            for (
              let s = 0;
              s < opportunityCategories[i].subcategories.length;
              s++
            ) {
              if (
                opportunityCategories[i].subcategories[s].id == subcategoryId
              ) {
                subcategoryName =
                  opportunityCategories[i].subcategories[s].name;
                return opportunityCategories[i].name;
              }
            }
          }
          return '';
        }
      }
    }
    return '';
  })();
  return (
    // TODO: show selected option from redux stores
    <>
      <SectionTitle>Please review before posting</SectionTitle>
      <Form>
        <Row>
          <Col xs={12}>
            <Form.Group controlId="title">
              <Form.Label>Opportunity Title</Form.Label>
              <FormControl value={title} />
            </Form.Group>
            <Form.Group controlId="available_positions">
              <Form.Label>Number of positions</Form.Label>
              <FormControl value={`${availablePositions} ${availablePositions === 1 ? 'position' : 'positions'} available`} readOnly />
            </Form.Group>
            <Form.Group controlId="category">
              <Form.Label>Opportunity Category</Form.Label>
              <FormControl value={categoryName} readOnly />
            </Form.Group>
            <Form.Group controlId="sub_category">
              <Form.Label>Opportunity Sub Category</Form.Label>
              <FormControl value={subcategoryName} readOnly />
            </Form.Group>
            <Form.Group controlId="performed_at">
              <Form.Label>
                Can this opportunity be performed remotely?
              </Form.Label>
              <FormControl
                value={performedAt === 'REMOTE' ? 'Yes' : 'No'}
                readOnly
              />
            </Form.Group>
            <Form.Group controlId="type_of_employment">
              <Form.Label>What type of employment is it?</Form.Label>
              <FormControl value={employmentType} readOnly />
            </Form.Group>
            <Form.Group controlId="opportunity_description">
              <Form.Label>Opportunity Description</Form.Label>
              <FormControl
                as="textarea"
                value={description}
                rows={8}
                readOnly
                style={{ resize: 'none' }}
              />
            </Form.Group>
            {/* <Form.Group controlId="">
                            <Form.Label>Related Skills</Form.Label>
                            <FormControl value="Paid Internship" readOnly />
                        </Form.Group>
                        <Form.Group controlId="">
                            <Form.Label>Interpersonal Skills</Form.Label>
                            <FormControl value="Paid Internship" readOnly />
                        </Form.Group> */}
          </Col>
        </Row>
      </Form>
    </>
  );
}

function mapStateToProps(state) {
  return {
    opportunityCategories: state.options.opportunityCategories.APIdata,
    employmentTypes: state.options.employmentTypes.APIdata,
  };
}

export default connect(mapStateToProps, null)(ReviewBeforePosting);

const FormControl = (props) => {
  return (
    <Form.Control
      style={{ border: 'none', backgroundColor: '#fff' }}
      {...props}
    />
  );
};
