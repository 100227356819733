import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import UIWrapper from './shared/ui-wrapper';
import { ACTIVITY_TYPES } from './shared/constants';
import { ActivityTab } from './activity-tab';
import OptionsTab from './options-tab';
import TabBar from './shared/tab-bar';
import { connect } from 'react-redux';
import { getChildActivity } from '../../redux/actions/parent';
import { useHistory, useParams } from 'react-router-dom';
import { API } from '../../settings';
import Loader from '../common/Loader';
import { clusterActivityData, formatToList } from '../../utils/cluster-by-date';
import back from '../../images/back.svg';

const Title = styled.div`
    text-align: center;
    font-size: 15px;
    margin-bottom: 20px;
    font-weight: 800;
    align-self: center;
    justify-self: center;
`;


function Child({ name, disableSeeAll, activity, ...props}) {

    const { id } = props;

    const [tab, setTab] = useState('Activity');

    return <>
        {!disableSeeAll && <TabBar optionA={'Activity'} optionB={'Options'} tab={tab} setTab={setTab}/>}
        {tab === 'Activity' && <ActivityTab disableSeeAll={disableSeeAll} activity={activity} name={name} />}
        {tab === 'Options' && <OptionsTab savedOptions={props?.saved[id]?.data} interestedIn={props?.interested[id]?.data} />}
    </>

}

export function StudentActivity({ id, name }) {

    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);

    const load = async () => {
        const res = await API.get(`/activity/child/${id}`);
        const { activity } = res.data;

        setData({ 
            activity: clusterActivityData(activity),
            saved: formatToList(activity, ACTIVITY_TYPES.SAVED_OPTIONS),
            interested: formatToList(activity, ACTIVITY_TYPES.INTERESTED),
            name });
        setLoading(false);
    }

    console.log(data);

    useEffect(() => {
        if (id) {
            load();
        }
    }, [id]);


    return loading ? <Loader /> : <Child disableSeeAll id={id} {...data} />

}


const TitleRow = styled.div`
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: repeat(3, 1fr);
    padding: 0px 25px;
`;

const Back = styled.img`
    height: 15px;
    cursor: pointer;
`

function ChildWithWrapper(props) {

    const { id } = useParams();
    const history = useHistory();

    const name = props?.activity[id]?.name ? props?.activity[id]?.name : '';
    const activity = props?.activity[id]?.data ? props?.activity[id]?.data : []

    const goBack = () => history.goBack()

    useEffect(() => {
        props.getChildActivity(id);
    }, [id]);

    return <UIWrapper loading={props.loading} style={{ flex: 1, paddingTop: 30 }}>
        <TitleRow>
            <Back alt='go back' src={back} onClick={goBack} />
            <Title>{name}</Title>
        </TitleRow>
        <Child {...props} activity={activity} name={name} id={id}/>
    </UIWrapper>

}


const mapStateToProps = (state) => ({
    ...state.parent
});

const mapDispatchToProps = { getChildActivity };

export default connect(
    mapStateToProps, 
    mapDispatchToProps
)(ChildWithWrapper);


