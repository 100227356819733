import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const Wrapper = styled.div`
  font-size: 1rem;
  font-weight: bold;
  width: 150px;
`;

const CircularProgressBar = (props) => {
  const { percent } = props;

  let pathColor = '#FFC34E';

  if (percent === 100) {
    pathColor = '#46D191';
  }

  if (percent < 20) {
    pathColor = '#FE7272';
  }

  const stylesObject = {
    textSize: '1.25rem',
    pathColor: pathColor,
    textColor: '#000000',
  };

  return (
    <Wrapper>
      <CircularProgressbar
        value={percent}
        text={`${percent}%`}
        styles={buildStyles(stylesObject)}
      />
    </Wrapper>
  );
};

export default CircularProgressBar;

CircularProgressBar.propTypes = {
  percent: PropTypes.number.isRequired,
};
