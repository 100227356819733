import React from 'react';
import { connect } from 'react-redux';
import { Col, Row, Spinner } from 'react-bootstrap';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { API } from '../../../settings';
import ApprovalStatuses from '../../common/Selects/ApprovalStatuses';
import { toast } from 'react-toastify';

const PageTitle = styled.h2`
  color: #000000;
  font-size: 25px;
  margin-bottom: 2px;
`;

const StyledRow = styled(Row)`
  padding: 25px;
`;

const PageWrapper = styled.div`
  position: relative;
`;

const MetaText = styled.p`
  margin: 0;
  font-size: 12px;
  color: #858585;
`;

const LoadingSpinner = styled(Spinner)`
  height: 30px;
  width: 30px;
  margin-top: 50px;
`;

const StatusMessage = styled.div`
  padding: 10px 15px;
  border-radius: 10px;
  font-weight: 600;
  width: 100%;
  white-space: nowrap;
  margin: 10px 0;

  &.APPROVED {
    background-color: #46d191;
  }

  &.REJECTED {
    background-color: #fe7272;
    color: #fff;
  }

  &.PENDING {
    background-color: #ffc34e;
  }

  &.EXPIRED {
    background-color: #fe7272;
    color: #fff;
  }
`;

const FilesStateSection = styled(Row)`
  margin-top: 0;
  margin-bottom: 20px;
`;

const FileItem = styled.a`
  background: #f7f7f7;
  display: flex;
  align-items: center;
  padding: 5px 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 7px;
  cursor: pointer;
  width: 100%;

  .file-icon {
    height: 30px;
  }

  & > * {
    margin-right: 5px;
  }
`;

const SpinnerWrapper = styled.div`
  width: 100px;
  height: 100px;
  margin: 0 auto;
`;

const StatesWrapper = styled.div`
  margin-top: 10px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 10px;
`;

class Documents extends React.Component {
  constructor(props) {
    super(props);
    const {
      user: { uuid, id: userId, employer },
    } = props;
    this.state = {
      uuid,
      userId,
      employer,
      formIsSubmitting: false,
      uploadLoading: false,
      files: [],
      loading: false,
    };
  }

  componentDidMount() {
    const {
      user: { uuid, id: userId, employer },
    } = this.props;

    this.setState({
      uuid,
      userId,
      employer,
    });
    this.getUploadedFiles();
  }

  getUploadedFiles = () => {
    this.setState({ loading: true });
    API.get(`/employers/${this.props.match.params.id}/uploads`)
      .then((res) =>
        this.setState({
          files: res.data.documentsGroupedByState,
          loading: false,
        })
      )
      .catch((error) => {
        console.log(error);
        this.setState({
          loading: false,
        });
      });
  };

  changeApprovalStatus = (approvalId, index, value) => {
    let { files } = this.state;
    files[index].approval.status = value;
    this.setState({ files });
    API.patch(
      `/employers/${this.props.match.params.id}/approvals/${approvalId}?status=${value}`
    ).then(() => {
      toast.success('Status updated');
    });
  };

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  render() {
    const { loading, files } = this.state;
    const tempRenderUploaded = () => {
      if (loading) {
        return (
          <span>
            <LoadingSpinner animation="border" variant="secondary" />
          </span>
        );
      } else if (this.state.data) {
        return (
          <span>
            Data uploaded. All set.{' '}
            <span aria-label="thumbs up" role="img">
              👍
            </span>
          </span>
        );
      } else {
        return (
          <span>
            Drag 'n drop an Excel file here, or click to select from your
            computer.
          </span>
        );
      }
    };

    return (
        <PageWrapper className={this.props.className}>
          <PageTitle>Documents</PageTitle>
          <Row>
            <Col xs={12}>
              <div>
                <Row>
                  <Col lg={6} xs={12}>
                    <div className="horizontal-flex align-center children-mg-r mg-t">
                      {/* <button onClick={this.toggleModal} className='ln-btn default'>Learn more</button> */}
                      {/*<UploadDocumentsModal/>*/}
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          {loading ? (
            <SpinnerWrapper>
              <LoadingSpinner animation="border" />
            </SpinnerWrapper>
          ) : (
            <StatesWrapper>
              {files.map((item, i) => {
                const { state, approval, documents } = item;
                return (
                  <FilesStateSection key={state.code}>
                    <Col xs={12}>
                      <h5>{state.name}</h5>
                    </Col>
                    <Col xs={12}>
                      <StatusMessage className={`${approval.status}`}>
                        Status:{' '}
                        <ApprovalStatuses
                          onChange={(e) =>
                            this.changeApprovalStatus(
                              approval.id,
                              i,
                              e.target.value
                            )
                          }
                          value={approval.status}
                          className="full-width"
                        />
                      </StatusMessage>
                    </Col>
                    <Col
                      xs={12}
                      className="horizontal-flex align-center children-mg-r mg-b"
                    >
                      <MetaText>
                        Last upload on{' '}
                        {moment(approval.createdAt).format('MMMM Do YYYY')}
                      </MetaText>
                      <MetaText>
                        Last update at{' '}
                        {moment(approval.updatedAt).format('MMMM Do YYYY')}
                      </MetaText>
                      <MetaText>
                        Expires on{' '}
                        {moment(approval.createdAt)
                          .add('1', 'year')
                          .format('MMMM Do YYYY')}
                      </MetaText>
                    </Col>
                    {documents.map((document, i) => (
                      <Col
                        xs={12}
                        className="horizontal-flex align-center children-mg-r"
                        key={document.id}
                      >
                        <FileItem
                          href={document.url}
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          <img
                            src="/file_type_icons/pdf.svg"
                            alt=""
                            className="file-icon"
                          />
                          <div
                            style={{
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              width: '100%',
                              overflow: 'hidden',
                            }}
                            title={document.filename}
                          >
                            {document.filename}
                          </div>
                        </FileItem>
                      </Col>
                    ))}
                  </FilesStateSection>
                );
              })}
            </StatesWrapper>
          )}
        </PageWrapper>
    );
  }
}

const mapStateToProps = (state) => ({ user: state.user });

export default connect(mapStateToProps)(withRouter(Documents));
