import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import styled from 'styled-components';

const StudentDashboardHeader = styled.h3`
  margin-top: 0;
`;

const StudentDashboardSubHeader = styled.div`
  margin-top: 0;
`;

const PageHeader = styled.h3`
  margin-top: 0;
`;

const PageWrapper = styled.div`
  height: 100vh;
  padding: 50px 25px 25px;
`;

const StudentTestingDashboard = (props) => {
  const { user } = props;

  console.log(user);

  return (
    <Container fluid className="p-0 h-100">
      <Row className="h-100 justify-content-lg-center">
        <Col lg={6} xs={12}>
          <PageWrapper>
            <PageHeader>Student Dashboard</PageHeader>
            <p></p>
            <p>
              <a href="/assessment">Assessment (Full)</a>
            </p>
            <p>
              <a href="/assessment-demo">Assessment (Demo)</a>
            </p>
            <p>
              <a href="/profile">Profile</a>
            </p>
          </PageWrapper>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state) => ({ user: state.user });

export default connect(mapStateToProps)(StudentTestingDashboard);
