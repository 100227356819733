import { STUDENT_INTERESTED_GET } from '../../constants/action-types';

const initialState = {
  loading: true,
  interestedOpportunities: [],
  count: undefined,
  page: 1,
  limit: 20,
};

function studentInterestedOpportunities(state = initialState, action) {
  switch (action.type) {
    case STUDENT_INTERESTED_GET:
      return { ...state, ...action.payload };
    default:
      break;
  }
  return state;
}

export default studentInterestedOpportunities;
