import React from 'react';
import styled from 'styled-components';
import { Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ClusterIcon from './ClusterIcon';

const ClustersWrapper = styled.div`
  width: 100%;
  margin-bottom: 1.25rem;
  -webkit-overflow-scrolling: touch;
`;

const ClusterScrollWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Cluster = styled.div`
  flex: 0 0 auto;
  width: 100px;
  height: 100px;
  margin-right: 0.75rem;
  color: #ffffff;
  background-color: #333333;
  border-radius: 12px;
  padding: 10px;
  font-size: 0.75rem;
  overflow-wrap: break-word;
  word-wrap: break-word;
`;

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100px;
  margin-bottom: 1.25rem;
`;

const LoadingSpinner = styled(Spinner)`
  height: 50px;
  width: 50px;
`;

const StudentProfileRankedClusters = (props) => {
  const { loading, rankedClusters } = props;
  const backLink = '/profile';

  const clustersList = (clusters) => {
    return clusters.map((cluster, i) => {
      const { key, name } = cluster;
      return (
        <Link
          to={{
            pathname: `/clusters/${cluster.id}`,
            query: { backLink },
          }}
          key={cluster.name}
        >
          <Cluster>
            <ClusterIcon clusterKey={key} name={name} />
          </Cluster>
        </Link>
      );
    });
  };

  if (loading) {
    return (
      <SpinnerWrapper>
        <LoadingSpinner animation="border" variant="secondary" />
      </SpinnerWrapper>
    );
  }

  return (
    <ClustersWrapper>
      <ClusterScrollWrapper>
        {clustersList(rankedClusters)}
      </ClusterScrollWrapper>
    </ClustersWrapper>
  );
};

export default StudentProfileRankedClusters;
