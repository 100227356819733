import { combineReducers } from 'redux';
import employerDashboard from './dashboard';
import employerOpportunities from './opportunities';

const employerReducer = combineReducers({
  dashboard: employerDashboard,
  opportunities: employerOpportunities,
});

export default employerReducer;
