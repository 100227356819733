import React from 'react';
import Greeting from './Greeting';
import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 20px;
`;

function StudentHome(props) {
  return (
    <Wrapper>
      <Greeting name={'Joshua'} />
    </Wrapper>
  );
}

export default StudentHome;
