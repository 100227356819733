import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import { Row, Col, Modal, Form as BForm } from 'react-bootstrap';
import FormTextInput from '../shared/forms/FormTextInput';
import FormSelectInput from '../shared/forms/FormSelectInput';

const validationSchema = Yup.object({
  name: Yup.string().required('Name is required'),
  address1: Yup.string().required('Address is required'),
  city: Yup.string().required('City is required'),
  stateCode: Yup.string().required('State is required'),
  zipcode: Yup.string().required('Zip Code is required'),
});

const Button = styled.button`
  width: 100%;
  height: 55px;
  border-radius: 12px;
  color: #ffffff;
  background-color: #1a1a1a;
  border: none;
  margin-top: 1.25rem;
  font-weight: bold;
`;

const AddSchool = (props) => {
  const { handleCloseAddSchool, showAddSchool, states, handleAddSchool } =
    props;

  return (
    <Modal show={showAddSchool} onHide={handleCloseAddSchool}>
      <Modal.Header closeButton>
        <Modal.Title>Add School</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          validationSchema={validationSchema}
          onSubmit={(values) => {
            handleAddSchool(values);
          }}
          initialValues={{
            name: '',
            address1: '',
            city: '',
            zipcode: '',
          }}
        >
          {({
            values,
            errors,
            dirty,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
          }) => (
            <Form noValidate id="addSchoolForm">
              <Row className="mb-1">
                <BForm.Group as={Col} controlId="formGridName">
                  <FormTextInput
                    controlId="validationFormik00"
                    label="Name"
                    type="text"
                    name="name"
                  />
                </BForm.Group>
              </Row>
              <Row className="mb-1">
                <BForm.Group as={Col} controlId="formGridAddress">
                  <FormTextInput
                    controlId="validationFormik01"
                    label="Address"
                    type="text"
                    name="address1"
                  />
                </BForm.Group>
              </Row>
              <Row className="mb-2">
                <BForm.Group as={Col} controlId="formGridCity">
                  <FormTextInput
                    controlId="validationFormik02"
                    label="City"
                    type="text"
                    name="city"
                  />
                </BForm.Group>
                <BForm.Group as={Col} controlId="formGridState">
                  <FormSelectInput
                    as={Col}
                    sm="4"
                    controlId="validationFormik04"
                    label="State"
                    type="text"
                    name="stateCode"
                  >
                    <option value="" label="Select a state">
                      Select a state{' '}
                    </option>
                    {states.map((state) => (
                      <option
                        value={state.code}
                        label={state.code}
                        key={state.code}
                      >
                        {state.name}
                      </option>
                    ))}
                  </FormSelectInput>
                </BForm.Group>
              </Row>
              <Row className="mb-1">
                <BForm.Group as={Col} controlId="formGridZipcode">
                  <FormTextInput
                    controlId="validationFormik05"
                    label="Zip Code"
                    type="text"
                    name="zipcode"
                  />
                </BForm.Group>
              </Row>
              <Button
                // disabled={!isValid}
                variant="success"
                as="input"
                size="lg"
                type="submit"
                value="Submit"
                form="addSchoolForm"
              />
            </Form>
          )}
        </Formik>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

export default AddSchool;
