import React from 'react';
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

const data = [
  {
    name: '9th Grade',
    percentOnboarded: 25,
  },
  {
    name: '10th Grade',
    percentOnboarded: 40,
  },
  {
    name: '11th Grade',
    percentOnboarded: 65,
  },
  {
    name: '12th Grade',
    percentOnboarded: 89,
  },
];

const colors = ['#C73D3D', '#FC5A5A', '#FC985A', '#FFC44F', '#FFC44F'];

const xAxisFormatter = (value) => `${value}th grade`;
const yAxisFormatter = (value) => `${value}%`;

const OnboardingStatus = (props) => {
  const { loading, onboardingByGradeStatus } = props;

  if (loading) {
    return null;
  }

  return (
    <div
      style={{
        width: '100%',
        maxWidth: '800px',
        boxShadow: '0 6px 10px 0 rgba(0, 0, 0, 0.02)',
        padding: '20px 0',
        height: '300px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        borderRadius: '20px',
        backgroundColor: '#ffffff',
      }}
    >
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={400}
          height={200}
          data={onboardingByGradeStatus}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          {/* <CartesianGrid strokeDasharray="3 3" /> */}
          <XAxis dataKey="grade" tickFormatter={xAxisFormatter} />
          <YAxis tickFormatter={yAxisFormatter} />
          {/* <Tooltip /> */}
          {/* <Legend /> */}
          <Bar dataKey="percentOnboarded" fill="#82ca9d">
            {onboardingByGradeStatus.map((entry, index) => {
              const { percentOnboarded } = entry;

              return (
                <Cell
                  key={`cell-${index}`}
                  fill={colors[Math.floor(percentOnboarded / 25)]}
                />
              );
            })}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default OnboardingStatus;
