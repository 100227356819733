import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  font-size: 1rem;
  font-weight: bold;
  width: 100%;
  background: #e4e4e4;
`;

const Progress = styled.div`
  background: #ffc34e;
  height: 7px;
`;

export default function LinearProgressBar(props) {
  const { progress } = props;
  console.log(progress);
  return (
    <Wrapper {...props}>
      <Progress style={{ width: `${progress ? progress : 0}%` }} />
    </Wrapper>
  );
}
