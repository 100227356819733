/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { logout } from '../features/users/userSlice';

const Logout = (props) => {
  const { logout, user } = props;
  const history = useHistory();

  useEffect(() => {
    if (user && user.isLoggedIn) {
      logout(user.id);
    }
  }, [])

  useEffect(() => {
    if (!user?.id) history.replace('/');
  }, [user])

  return <div />;
};

const mapStateToProps = (state) => ({ user: state.user });
const mapDispatchToProps = { logout };

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
