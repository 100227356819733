import React from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Toggle from 'react-toggle';
import 'react-toggle/style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { API } from '../settings';

const OPPORTUNITIES_BASED_ON_ASSESSMENT = 'OPPORTUNITIES_BASED_ON_ASSESSMENT';
const OPPORTUNITIES_BASED_ON_SAVED_OPTIONS =
  'OPPORTUNITIES_BASED_ON_SAVED_OPTIONS';
const ANNOUNCEMENTS_FROM_COUNSELOR = 'ANNOUNCEMENTS_FROM_COUNSELOR';
const OPPORTUNITIES_I_AM_INTERESTED_IN = 'OPPORTUNITIES_I_AM_INTERESTED_IN';

const BackLink = styled(Link)`
  color: #000000;
  display: inline-block;
  font-size: 14px;
  &:hover {
    color: #000000;
  }
`;

const PageTitle = styled.h2`
  color: #000000;
  font-size: 1.5rem;
  margin: 25px 0 0;
  padding: 0;
  line-height: 1.75rem;
`;

const PageSubtitle = styled.span`
  color: #000000;
  font-size: 0.75rem;
  margin-top: 0.5rem;
  line-height: 0.75rem;
  color: #797878;
`;

const SettingsHeader = styled.div`
  text-align: left;
  width: 100%;
  padding: 25px 25px 0;
`;

const PreferenceRows = styled.div`
  padding: 25px 0;
`;

const PreferenceRow = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 1.5rem;
`;

const Description = styled.div`
  width: 80%;
`;

const ToggleContainer = styled.div`
  text-align: right;
  width: 20%;
  padding-top: 2px;

  .react-toggle {
    .react-toggle-track {
      background-color: #ebebeb;
    }

    .react-toggle-thumb {
      border-color: #ebebeb;
    }

    &.react-toggle--checked {
      .react-toggle-track {
        background-color: #ffc34e;
      }
      .react-toggle-thumb {
        border-color: #ffc34e;
      }
    }

    &:hover {
      .react-toggle-track {
        background-color: #ebebeb;
      }

      &.react-toggle--checked {
        .react-toggle-track {
          background-color: #ffc34e;
        }
      }
    }
  }
`;

class StudentEmailPreferences extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      emailPreferences: [],
    };

    this.togglePreference = this.togglePreference.bind(this);
  }

  componentDidMount() {
    // const { uuid } = this.props.match.params;
    // API.get(`/students/${uuid}`).then((response) => {
    //   this.setState({
    //     firstName: response.data.firstName,
    //     lastName: response.data.lastName,
    //     noAnswers: response.data.noAnswers,
    //     yesAnswers: response.data.yesAnswers,
    //   });
    //   return response.data;
    // });
  }

  togglePreference(key) {
    console.log(`toggle preference: ${key}`);
  }

  render() {
    // const { firstName, lastName, yesAnswers, noAnswers } = this.state;
    // const { uuid } = this.props.match.params;

    return (
      <div>
        <SettingsHeader>
          <BackLink to="/settings">
            <FontAwesomeIcon icon={faChevronLeft} />
          </BackLink>
          <PageTitle>Email Notifications</PageTitle>
          <PageSubtitle>Send me an email whenever there are</PageSubtitle>
          <PreferenceRows>
            <PreferenceRow>
              <Description>
                New opportunities based on my assessment
              </Description>
              <ToggleContainer>
                <Toggle
                  defaultChecked={false}
                  icons={false}
                  onChange={() => {
                    this.togglePreference(OPPORTUNITIES_BASED_ON_ASSESSMENT);
                  }}
                />
              </ToggleContainer>
            </PreferenceRow>
            <PreferenceRow>
              <Description>
                New opportunities based on my saved options
              </Description>
              <ToggleContainer>
                <Toggle
                  defaultChecked={false}
                  icons={false}
                  onChange={() => {
                    this.togglePreference(OPPORTUNITIES_BASED_ON_SAVED_OPTIONS);
                  }}
                />
              </ToggleContainer>
            </PreferenceRow>
            <PreferenceRow>
              <Description>
                Announcements from our guidance counselor
              </Description>
              <ToggleContainer>
                <Toggle
                  defaultChecked={false}
                  icons={false}
                  onChange={() => {
                    this.togglePreference(ANNOUNCEMENTS_FROM_COUNSELOR);
                  }}
                />
              </ToggleContainer>
            </PreferenceRow>
            <PreferenceRow>
              <Description>
                News about opportunities I was interested in
              </Description>
              <ToggleContainer>
                <Toggle
                  defaultChecked={false}
                  icons={false}
                  onChange={() => {
                    this.togglePreference(OPPORTUNITIES_I_AM_INTERESTED_IN);
                  }}
                />
              </ToggleContainer>
            </PreferenceRow>
          </PreferenceRows>
        </SettingsHeader>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ user: state.user });

export default connect(mapStateToProps)(StudentEmailPreferences);
