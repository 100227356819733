import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { API } from '../../settings';
import Loader from '../common/Loader';
import { ArrowLeft20Regular as ArrowLeftIcon } from '@fluentui/react-icons';
import InfoBlock from './Employer/InfoBlock';
import Documents from './Employer/Documents';
import { connect } from 'react-redux';
import SchoolsSelect from '../common/Selects/Schools';
import { toInt } from '../common/helpers';
import { toast } from 'react-toastify';
import DeleteButton from '../DeleteButton';
import EmployerOpportunities from '../EmployerOpportunities';

const Restriction = styled(Form)`
  background-color: white;
  border-radius: 15px;
  padding: 20px;
  grid-area: restriction;
`

const Opportunities = styled(EmployerOpportunities)`
  grid-area: opportunities;
  background-color: white;
  border-radius: 15px;
  padding: 20px;

  & > *:first-child {
    padding: 20px;
    box-sizing: border-box;
    padding-bottom: 0px;

    & h3 {
      font-size: 23px;
    }

    & button {
      font-size: 14px;
    }
  }
`

const DocumentsWrapper = styled(Documents)`
  background-color: white;
  border-radius: 15px;
  padding: 20px;
  grid-area: documents;
`

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 2fr 3fr 3fr;
  grid-template-areas: 
    "sidebar documents restriction"
    "sidebar opportunities opportunities";
  flex: 1;
  gap: 20px;
`

const Sidebar = styled(InfoBlock)`
  display: flex;
  flex-direction: column;
  grid-area: sidebar;
  padding: 20px;
  box-sizing: border-box;
  height: 80vh;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
`

class EmployerOpportunity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uploads: {},
      employer: {},
      loading: false,
    };
    this.getEmployerInfo = this.getEmployerInfo.bind(this);
  }


  componentDidMount() {
    if (this.props.location.state) {
      this.setState({ data: this.props.location.state });
    } else {
    }
    this.getEmployerInfo();
  }

  async getEmployerInfo() {
    this.setState({ loading: true });
    const employer = await API.get(`/employers/${this.props.match.params.id}`);
    this.setState({ employer: employer.data, loading: false });
  }

  onRestrictionSelect = (e) => {
    let { employer } = this.state;
    if (e.target.value === 'none') {
      employer.Schools = [];
    } else {
      employer.Schools[0] = toInt(e.target.value);
    }
    this.setState({ employer });
    API.put(`/employers/${this.state.employer.id}`, {
      userTypeProfile: {
        ...employer,
        schoolIds: employer.Schools,
      },
    }).then((res) => {
      toast.success('Restriction updated');
    });
  };

  render() {
    const { loading, employer } = this.state;


    const HeaderRow = () => (
        <>
          <button
            onClick={this.props.history.goBack}
            className="ln-btn primary"
          >
            <ArrowLeftIcon className="ln-icon" />
            <span>Back</span>
          </button>
          <DeleteButton url={`/employers/${this.state.employer.id}`} schemaName='employer' />
        </>
    )

    const RestrictionWrapper = () => (
      <Restriction>
        <h4>Restriction</h4>
        <Form.Group controlId="restrictions">
          <SchoolsSelect
            value={
              employer.Schools && employer.Schools[0]
                ? employer.Schools[0].id
                : -1
            }
            onChange={this.onRestrictionSelect}
          />

        </Form.Group>
      </Restriction>
    )

    if (!loading) {
      return (
        <PageWrapper>
          <Header>
            <HeaderRow />
          </Header>
          <Grid>
            <Sidebar employer={employer} />
            <RestrictionWrapper />
            <DocumentsWrapper />
            <Opportunities 
              opportunityUrl='/super-admin/opportunities'
              disableAddOpportunity 
              employerId={this.props.match.params.id}/>
          </Grid>
        </PageWrapper>
      );
    } else if (loading) {
      return <Loader active />;
    } else {
      return <Loader active />;
    }
  }
}

export default connect((state) => ({
  user: state.user,
}))(withRouter(EmployerOpportunity));
