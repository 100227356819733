import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { ACTIVITY_TYPES } from './constants';
import { Row, SPACING } from './default-styles';

const List = styled.div`
    display: grid;
    grid-auto-template: rows;
    grid-template-rows: auto;
    gap: 10px;
    padding: 10px ${SPACING};
`;

const Title = styled.p`
    margin: 0px;
`;

const SeeAll = styled.div`
    font-size: 12px;
    cursor: pointer;
`;

const Card = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    background-color: white;
    box-shadow: 2px 2px 7px rgb(0 0 0 / 10%);
    cursor: pointer;
    padding: 5px 10px;
`;

const CardHeadline  = styled.h3`
    font-size: 14px;
    margin: 5px 0px;
`

const CardDetails = styled.div`
    font-size: 10px;
    opacity: 0.5
`;

const EmptyListWrapper = styled.div`
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background-color: rgba(0,0,0,0.03);
`;

const EmptyListText = styled.div`
    font-size: 12px;
    color: rgba(0,0,0,0.5);
`

function Item({ position, company, location, id }) {

    const history = useHistory();
    
    const onClickHandler = () => history.push(`/parent/job/${id}`);

    return <Card onClick={onClickHandler}>
        <CardHeadline>{ position }</CardHeadline>
        <CardDetails>{ company } {location ? '-' : ''} { location }</CardDetails>
    </Card>
}

function EmptyList() {

    return <EmptyListWrapper>
        <Row>
            <EmptyListText>
                No opportunities selected yet!
            </EmptyListText>
        </Row>
    </EmptyListWrapper>

}

export default function JobList({ type, title, id, jobs, maxLength = 1000, hideHeadline }) {

    const history = useHistory();

    const onClickSeeAll = () =>  history.push(
        `${type === ACTIVITY_TYPES.INTERESTED 
            ? '/parent/child/interested-in/' 
            : '/parent/child/saved/'}${id}`
    )

    return <List>
        {!hideHeadline && <Row style={{ margin: '10px 0px', justifyContent: 'space-between' }}>
            <Title>{title}</Title>
            {<SeeAll onClick={onClickSeeAll}>See all &gt;</SeeAll>}
        </Row>}
        { jobs.length > 0 ? jobs.slice(0, maxLength).map((job, i) => <Item {...job} key={i} />) : <EmptyList /> }
    </List>

}