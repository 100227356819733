/* eslint-disable react-hooks/exhaustive-deps */
import { ArrowLeft20Regular } from '@fluentui/react-icons';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { API } from '../../settings';
import Loader from '../common/Loader';
import { connect } from 'react-redux';
import Avatar from 'react-avatar';
import DeleteButton from '../DeleteButton';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const BackLink = styled(Link)`
  color: white;
  display: flex;
  font-size: 18px;
  font-weight: 600;
  height: 40px;
  background-color: #373737;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  padding: 0px 20px;

  &:hover {
    color: white;
  }

  & > *:nth-child(1) {
    margin-right: 5px;
  }
`;

const InfoLabel = styled.p`
  font-weight: 600;
  text-transform: uppercase;
  font-size: 14px;
  margin: 0px;
  color: gray;
`

const InfoValue = styled.p`
  font-size: 14px;
  font-weight: 600;
  margin: 0px;
  margin-bottom: 10px;
`

const ColumnName = styled(InfoValue)`
  margin-bottom: 20px;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-template-areas: 
    "sidebar general"
    "sidebar general"
    "sidebar general";
  flex: 1;
`

const Title = styled.h1`
  font-size: 23px;
  text-align: center;
  margin-top: 20px;
  word-break: break-all; 
`

const Subtitle = styled.h2`
  font-size: 14px;
  text-align: center;
  color: gray;
  margin-top: 10px;
`

const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  grid-area: sidebar;
  padding: 20px;
  box-sizing: border-box;
`

const Info = ({label, value}) => {

  if (!value) return <></>

  return <>
    <InfoLabel>{label}</InfoLabel>
    <InfoValue>{value}</InfoValue>    
  </>
}

const Counselor = ({
  user,
  ...props }
) => {

  const [counselor, setCounselor] = useState({});
  const [loading, setLoading] = useState(true)

  const getCounselorInfo = async () => {
    const result = await API.get(`/counselors/full/${props.match.params.id}`);
    setCounselor(result.data);
    setLoading(false);
  }

  useEffect(() => {
    getCounselorInfo();
  }, []);

  if (loading) {
    return <Loader active />;
  }

  return <Wrapper>
    <Header>
      <BackLink onClick={() => props.history.goBack()}>
        <ArrowLeft20Regular color='white' />
        Back
      </BackLink>
      <DeleteButton url={`/counselors/${counselor.id}`} schemaName='counselor'/>
    </Header>
    <Grid>
      <Sidebar className='sidebar-bg'>
        <ColumnName>Info</ColumnName>
        <Avatar
          className="profileAvatar self-center"
          round={true}
          size={100}
          name={`${counselor?.firstName}`}
          color="#797878"
        />
        <Title>{`${counselor?.firstName} ${counselor?.lastName}`}</Title>
        <Subtitle>Joined {new Date(counselor.createdAt).toDateString()}</Subtitle>
        <div className='flex-spacer' />
        <Info label='Email' value={counselor.email} />
        <Info label='School' value={counselor.school.city} />
        <Info label='City' value={counselor.school.city} />
      </Sidebar>
    </Grid>
  </Wrapper>
}


const SuperAdminCounselor = connect((state) => ({
  user: state.user,
}))(withRouter(Counselor))


export default SuperAdminCounselor;