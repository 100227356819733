import React, { useState } from 'react';
import { Form } from 'react-bootstrap';

function ApprovalStatuses(props) {
  const [statuses, setStatuses] = useState([
    { value: 'PENDING' },
    { value: 'APPROVED' },
    { value: 'REJECTED' },
    { value: 'EXPIRED', disabled: true },
  ]);
  return (
    <Form.Control {...props} as="select">
      <option disabled value={-1}>
        Select approval status
      </option>
      {statuses.map((type, i) => (
        <option disabled={type.disabled} value={type.value}>
          {type.value}
        </option>
      ))}
    </Form.Control>
  );
}

export default ApprovalStatuses;
