import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';
import dotenv from 'dotenv';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { getJobs } from '../redux/actions/counselor';
import StatusIndicator from './common/StatusIndicator';

dotenv.config();

const StudentsWrapper = styled.div`
  background: #ffffff;
  border-radius: 1.25rem;

  table,
  th,
  td {
    border: none;
  }

  thead {
    th {
      border: none;
    }
  }

  tbody:before {
    content: '@';
    display: block;
    line-height: 0.5rem;
    text-indent: -99999px;
  }

  td {
    font-size: 0.875rem;
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
  }

  td.firstName,
  td.lastName {
    font-size: 1rem;
    font-weight: bold;
    background: none;
  }

  td.grade,
  td.percentDone {
    span {
      background: #fbfbfb;
      display: inline-block;
      padding: 0.2rem 1rem;
      border-radius: 8px;
    }
  }
`;

const BootstrapTableWrapper = styled.div`
  .table td {
    overflow-wrap: break-word;
  }

  .table th {
    background: #fbfbfb;
    color: #858585;
    font-size: 0.6875rem;

    &:first-child {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    &:last-child {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }

  .react-bootstrap-table-pagination {
    margin-top: 2rem;
    justify-content: center;

    div {
      display: none;
    }

    div.react-bootstrap-table-pagination-list {
      display: flex;
      justify-content: center;

      ul {
        float: none;
      }
    }

    .page-link {
      border-color: #797878;
      color: #797878;
    }

    .page-item.active .page-link {
      color: #ffffff;
      background-color: #000000;
      border-color: #000000;
    }
  }
`;


function JobList(props) {

  useEffect(() => {
    props.getJobs(1);
  }, []);
   
  const jobNameFormatter = (cell, row) => {
    return <Link to={`/counselor/job/${row.id}`}>{cell}</Link>;
  };

  const companyFormatter = (cell, row) => {
    if (row?.employer?.url) return <a href={row.employer.url} target="__blank">{cell}</a>
    return <span>{cell}</span>;
  };

  const positionsFormatter = (cell, row) => {
    return <span>{cell} available</span>;
  };

  const studentsFormatter = (cell, row) => {
    const students = (cell.length === 1) ? 'student' : 'students'
    return <span>{cell.length} {students}</span>;
  };

  const columns = [
    {
      text: 'Opportunity Name',
      dataField: 'title',
      formatter: jobNameFormatter,
      classes: 'jobName',
    },
    {
      text: 'Company',
      dataField: 'employer.name',
      formatter: companyFormatter,
      classes: 'employerName',
    },
    {
      text: 'Available Positions',
      dataField: 'availablePositions',
      formatter: (cell, row) => {
        const available =  row.numberOfPositions - row.matched;
        return <span>{available} available</span>
      },
      classes: 'available',
    },
    {
      text: 'Students Interested In',
      dataField: 'interestedIn',
      formatter: studentsFormatter,
      classes: 'interestedIn',
    },
    {
      text: 'Sent to employer',
      dataField: 'approvedByCounselor',
      formatter: studentsFormatter,
      classes: 'approvedByCounselor',
    },
    {
      text: 'Status',
      dataField: 'approvedByCounselor',
      formatter: (cell, row) => {
        const unavailable = row.matched === row.numberOfPositions;
        return <StatusIndicator status={!row.isActive ? 'negative' : unavailable ? 'neutral' : 'positive'} />
      },
      classes: 'approvedByCounselor',
    },
  ];

  const paginationConfig = {
    sizePerPage: 100,
    hideSizePerPage: true,
  };

  return (
    <Container className="p-0 h-100 ">
      <Row>
        <Col>
          <StudentsWrapper>
            <BootstrapTableWrapper>
              <BootstrapTable
                columns={columns}
                data={props.jobs}
                keyField="title"
                pagination={paginationFactory(paginationConfig)}
              />
            </BootstrapTableWrapper>
          </StudentsWrapper>
        </Col>
      </Row>
    </Container>
  );

}

const mapStateToProps = (state) => ({
  ...state.counselor.jobs,
});

export default connect(mapStateToProps, { getJobs })(JobList);
