import {
  COUNSELOR_DASHBOARD_GET,
  COUNSELOR_JOBS_GET,
  COUNSELOR_STUDENTS_GET,
  COUNSELOR_SET_LOADING
} from '../constants/action-types';
import { API } from '../../settings';

export function getStudents(
  page,
  searchValue = '',
  sortBy = 'firstName',
  sort = 'desc',
  school,
) {
  return function (dispatch, getState) {

    const schoolId = school?.id ? 
      school?.id :
      getState().user.userTypeProfile.schoolId;

    console.log(getState().user);

    const {
      counselor: {
        students: { limit },
      },
    } = getState();

    dispatch({
      type: COUNSELOR_STUDENTS_GET,
      payload: {
        loading: true,
        page: page,
        sortBy: sortBy,
        sort: sort,
        searchValue: searchValue,
      },
    });
    API.get(
      `/students/all/?schoolId=${schoolId}&page=${page}&limit=${limit}&sortBy=${sortBy}&sort=${sort}&search=${searchValue}`
    ).then((res) => {
      dispatch({
        type: COUNSELOR_STUDENTS_GET,
        payload: { loading: false, ...res.data },
      });
    });
  };
}

export function setLoading(studentId) {
  return function (dispatch) {
    dispatch({
      type:COUNSELOR_SET_LOADING,
      payload: {
        studentId
      }
    })
  }
}

export function getJobs(
  page,
) {
  return function (dispatch) {

    dispatch({
      type:COUNSELOR_JOBS_GET,
      payload: {
        loading: true,
        page,
      },
    });

    API.get(
      `/counselors/jobs`
    ).then((res) => {
      dispatch({
        type: COUNSELOR_JOBS_GET,
        payload: { loading: false, jobs: res.data.jobs, students: res.data.students },
      });
    });
  };
}

export function setSearchValue(value = '') {
  return function (dispatch) {
    dispatch({
      type: COUNSELOR_STUDENTS_GET,
      payload: { searchValue: value },
    });
  };
}

export function counselorGetDashboard() {
  return async function (dispatch) {
    dispatch({
      type: COUNSELOR_DASHBOARD_GET,
      payload: { loading: true },
    });
    const { data } = await API.get(`/counselors/dashboard`);
    dispatch({
      type: COUNSELOR_DASHBOARD_GET,
      payload: { loading: false, ...data },
    });
  };
}
