/* eslint-disable react-hooks/exhaustive-deps */
import { ArrowLeft20Regular } from '@fluentui/react-icons';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { API } from '../../settings';
import Loader from '../common/Loader';
import { connect } from 'react-redux';
import Avatar from 'react-avatar';
import DeleteButton from '../DeleteButton';
import StudentList from '../StudentList';
import AddNewStudent from '../AddNewStudent';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
`

const SectionHeader = styled.h4`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  font-size: 18px;
`

const StudentsView = styled.div`
  background-color: white;
  grid-area: general;
  border-radius: 20px;
  padding: 20px;

  h3 {
    font-size: 20px;
    margin-bottom: 0px;
  }
`

const BackLink = styled(Link)`
  color: white;
  display: flex;
  font-size: 18px;
  font-weight: 600;
  height: 40px;
  background-color: #373737;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  padding: 0px 20px;

  &:hover {
    color: white;
  }

  & > *:nth-child(1) {
    margin-right: 5px;
  }
`;

const InfoLabel = styled.p`
  font-weight: 600;
  text-transform: uppercase;
  font-size: 14px;
  margin: 0px;
  color: gray;
`

const InfoValue = styled.p`
  font-size: 14px;
  font-weight: 600;
  margin: 0px;
  margin-bottom: 10px;
`

const ColumnName = styled(InfoValue)`
  margin-bottom: 20px;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-template-areas: 
    "sidebar general"
    "sidebar general"
    "sidebar general";
  flex: 1;
  gap: 20px;
`

const Title = styled.h1`
  font-size: 23px;
  text-align: center;
  margin-top: 20px;
  word-break: break-all; 
`

const Subtitle = styled.h2`
  font-size: 14px;
  text-align: center;
  color: gray;
  margin-top: 10px;
`

const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  grid-area: sidebar;
  padding: 20px;
  box-sizing: border-box;
  max-height: 80vh;
`

const Info = ({ label, value }) => {

  if (!value) return <></>

  return <>
    <InfoLabel>{label}</InfoLabel>
    <InfoValue>{value}</InfoValue>
  </>
}

const School = ({
  user,
  ...props }
) => {

  const [school, setschool] = useState({});
  const [loading, setLoading] = useState(true)

  const getSchoolInfo = async () => {
    const result = await API.get(`/schools/${props.match.params.id}`);
    setschool(result.data.school);
    setLoading(false);
  }

  useEffect(() => {
    getSchoolInfo();
  }, []);

  if (loading) {
    return <Loader active />;
  }

  return <Wrapper>
    <Header>
      <BackLink onClick={() => props.history.goBack()}>
        <ArrowLeft20Regular color='white' />
        Back
      </BackLink>
      {<DeleteButton url={`/schools/${school.id}`} schemaName='school' />}
    </Header>
    <Grid>
      <Sidebar className='sidebar-bg'>
        <ColumnName>Info</ColumnName>
        <Avatar
          className="profileAvatar self-center"
          round={true}
          size={100}
          name={`${school?.name}`}
          color="#797878"
        />
        <Title>{school?.name}</Title>
        <Subtitle>Joined {new Date(school.createdAt).toDateString()}</Subtitle>

        <div className='flex-spacer' />

        <Info label='City' value={school.city} />
        <Info label='Address Line 1' value={school.address1} />
        <Info label='Address Line 2' value={school.address2} />
        <Info label='Zip Code' value={school.zipcode} />

      </Sidebar>
      <StudentsView>
        <Header>
          <SectionHeader>Students</SectionHeader>
          <AddNewStudent schoolId={school.id} />
        </Header>
        {school?.id && <StudentList
          studentPageLinkBase='/super-admin/students/'
          schoolId={school.id} />}
      </StudentsView>
    </Grid>
  </Wrapper>
}


const SuperAdminSchool = connect((state) => ({
  user: state.user,
}))(withRouter(School))


export default SuperAdminSchool;