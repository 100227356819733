import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

const ClustersListWrapper = styled.div`
  width: 100%;
  padding-top: 0.25rem;
  padding-bottom: 2.25rem;
`;

const ClusterLink = styled(Link)`
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
`;

const Cluster = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1.25rem;
  width: 100%;
  margin-right: 0.75rem;
  background-color: #ffffff;
  border-radius: 12px;
  padding: 0.5rem 0.875rem;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.1);
  overflow-wrap: break-word;
  word-wrap: break-word;
`;

const ClusterLeft = styled.div``;

const ClusterName = styled.div`
  font-size: 1rem;
  line-height: 1.375rem;
  color: #000000;
`;

const SubcategoryDetails = styled.div`
  color: #afafaf;
  font-size: 0.75rem;
  line-height: 1.25rem;
`;

const ClusterRight = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  color: #d4d4d4;
`;

const AllClustersList = (props) => {
  const { clusters } = props;

  const clustersList = (clusters) => {
    return clusters.map((cluster, i) => {
      const { name, subcategories } = cluster;
      return (
        <ClusterLink to={`/clusters/${cluster.id}`} key={cluster.name}>
          <Cluster>
            <ClusterLeft>
              <ClusterName>{name}</ClusterName>
              <SubcategoryDetails>{`${subcategories.length} Sub-categories`}</SubcategoryDetails>
            </ClusterLeft>
            <ClusterRight>
              <FontAwesomeIcon icon={faChevronRight} />
            </ClusterRight>
          </Cluster>
        </ClusterLink>
      );
    });
  };

  return <ClustersListWrapper>{clustersList(clusters)}</ClustersListWrapper>;
};

export default AllClustersList;
