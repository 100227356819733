import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMedal } from '@fortawesome/free-solid-svg-icons';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import assessmentLogo from '../images/assessmentLogo.png';

const CtaLink = styled(Link)`
  &:hover {
    text-decoration: none;
  }
`;

const Cta = styled.div`
  width: 100%;
  min-height: 120px;
  margin-top: 2.25rem;
  background-color: #abf6f8;
  border-radius: 12px;
  padding: 16px 13px;
  display: flex;
`;

const CtaImage = styled.div`
  display: flex;
  flex-basis: 25%;
  padding: 10px;
  flex-direction: column;
`;
const CtaCopy = styled.div`
  flex-basis: 75%;
  padding: 0 10px;
`;

const CtaLogo = styled.img`
  margin-top: 0.25rem;
  width: 90%;
  align-self: center;
`;

const CtaHeader = styled.h6`
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: bolder;
  margin: 0;
`;

const CtaTextCopy = styled.p`
  font-size: 0.75rem;
  line-height: 0.94rem;
  margin: 0.32rem 0 0;
`;

const IconRow = styled.div`
  padding-top: 0.5rem;
  font-size: 0.75rem;
  line-height: 1.25rem;
  color: #57cccf;
`;
const IconCopy = styled.span`
  display: inline-block;
  margin-left: 0.25rem;
  margin-right: 1rem;
`;

const StudentAssessmentCta = (props) => {
  const { percentDone } = props;

  if ((!percentDone && percentDone !== 0) || percentDone > 98) {
    return null;
  } else {
    return (
      <CtaLink to="/assessment-intro-1">
        <Cta>
          <CtaImage>
            <CtaLogo src={assessmentLogo} alt="Logo" />
          </CtaImage>
          <CtaCopy>
            <CtaHeader>Your Assessment Survey</CtaHeader>
            <CtaTextCopy>
              Based on your answers our algorithm will suggest options tailored
              to your skills and interest.
            </CtaTextCopy>
            <IconRow>
              <FontAwesomeIcon icon={faMedal} />
              <IconCopy>96% Accurate</IconCopy>
              <FontAwesomeIcon icon={faClock} />
              <IconCopy>4 min</IconCopy>
            </IconRow>
          </CtaCopy>
        </Cta>
      </CtaLink>
    );
  }
};

export default StudentAssessmentCta;
