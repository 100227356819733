import React from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Spinner } from 'react-bootstrap';
import styled from 'styled-components';
import TinderCard from 'react-tinder-card';

import StudentAssessmentInterstitial from './StudentAssessmentInterstitial';
import ProgressBar from './ProgressBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import { API } from '../settings';
import { updatePercentDone } from '../features/users/userSlice';
import { FINISH_ASSESSMENT, RESTART_ASSESSMENT, START_ASSESSMENT, track } from '../utils/mixpanel';

const PageWrapper = styled.div`
  padding-top: 25px;
  height: 100vh;
  flex-flow: column nowrap;
  display: flex;
  align-items: center;
`;

const ButtonRow = styled.div`
  max-width: 280px;
  display: flex;
  margin-top: 50px;
  margin-bottom: auto;
  width: 100%;
  justify-content: space-between;
`;

const ProgressRow = styled.div`
  display: flex;
  margin-top: auto;
  width: 100%;
  max-width: 360px;
`;

const RoundButton = styled.a`
  display: flex;
  justify-content: center;
  width: 100px;
  height: 100px;
  background: #f2f3f5;
  border-radius: 100px;
  align-items: center;
`;

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const LoadingSpinner = styled(Spinner)`
  height: 50px;
  width: 50px;
`;

const SectionHeader = styled.div`
  padding: 15px 0 15px;
  text-align: center;
`;

const SectionTitle = styled.h2`
  font-family: Nunito;
  font-size: 22px;
  color: #000000;
  margin: 0;
  padding: 0;
`;

const SectionSubTitle = styled.span`
  display: inline-block;
  margin-top: 5px;
  font-size: 16px;
`;

// TODO: send this back as a flat array

const studentCompletedQuestionsWithSections = [
  {
    id: 1,
    key: 'SECTION_1',
    title: 'Section 1 Title',
    questions: [
      {
        id: 1,
        key: 'I_LIKE_TO_WORK_ON_CARS',
        copy: 'I like to work on cars',
        answer: 'Y',
      },
      {
        id: 2,
        key: 'I_LIKE_TO_DO_PUZZLES',
        copy: 'I like to do puzzles',
        answer: 'N',
      },
    ],
  },
  {
    id: 2,
    key: 'SECTION_2',
    title: 'Section 2 Title',
    questions: [
      {
        id: 3,
        key: 'I_AM_GOOD_AT_WORKING_INDEPENDENTLY',
        copy: 'I am good at working independently',
        answer: 'Y',
      },
    ],
  },
];

class StudentAssessment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      allSectionsQuestionsCount: 0,
      answersCount: 0,
      assessmentKey: '',
      cardRefs: [],
      childRefs: [],
      completedQuestions: [],
      completedQuestionsCount: 0,
      completedQuestionsWithSections: [],
      currentSectionIndex: 0,
      currentSectionTitle: '',
      currentQuestionSectionIndex: 0,
      initialRemainingQuestions: [],
      interstitialIsVisible: false,
      isFinalQuestion: false,
      loading: true,
      nextQuestionSectionIndex: 0,
      percentDone: 0,
      questions: [],
      questionsCount: 0,
      questionsWithSections: [],
      remainingQuestions: [],
      sectionsCount: 0,
      totalQuestionsCount: 0,
    };

    this.swipe = this.swipe.bind(this);
    this.swiped = this.swiped.bind(this);
    this.storeSwipe = this.storeSwipe.bind(this);
    this.outOfFrame = this.outOfFrame.bind(this);
    this.closeInterstitial = this.closeInterstitial.bind(this);
  }

  componentDidMount() {
    const { isDemo, user } = this.props;
    const { uuid } = user;


    let questionsUrl = `/students/${uuid}/assessment-questions`;

    if (isDemo) {
      questionsUrl = `/students/assessment-test-questions`;
    }

    // TODO: run network request here to get student's current answers state
    API.get(questionsUrl)
      .then((response) => {
        const { answersCount, key, questions, questionsCount } = response.data;

        const sectionsCount = questions.length;
        let totalQuestionsCount = 0;
        let currentSectionIndex = 0;
        let completedQuestionsCount = 0;
        const remainingQuestions = [];

        if (answersCount > 0) {     
          track(RESTART_ASSESSMENT);
        } else {
          track(START_ASSESSMENT)
        }

        console.log('running through questions with sections');
        console.log(studentCompletedQuestionsWithSections);

        questions.forEach((section, index) => {
          totalQuestionsCount += section.questions.length;

          if (studentCompletedQuestionsWithSections[index]) {
            completedQuestionsCount +=
              studentCompletedQuestionsWithSections[index].questions.length;
          }

          const studentAnswerKeys = [];

          // store keys of all student answers
          if (studentCompletedQuestionsWithSections[index]) {
            studentCompletedQuestionsWithSections[index].questions.forEach(
              (question) => {
                studentAnswerKeys.push(question.key);
              }
            );
          }

          questions[index].questions.forEach((question) => {
            if (!studentAnswerKeys.includes(question.key)) {
              remainingQuestions.push({
                sectionTitle: questions[index].title,
                sectionSubTitle: questions[index].subTitle,
                sectionInterstitialCopy: questions[index].interstitialCopy,
                sectionIndex: index,
                id: question.id,
                key: question.key,
                copy: question.copy,
              });
            }
          });
        });

        // Reverse -- because we're stacking a deck of cards
        remainingQuestions.reverse();

        const cardRefs = Array(remainingQuestions.length)
          .fill(0)
          .map((i) => React.createRef());

        console.log('COMPLETED QUESTIONS COUNT');
        console.log(answersCount);

        this.setState({
          answersCount: answersCount,
          assessmentKey: key,
          totalQuestionsCount,
          sectionsCount,
          currentSectionIndex,
          completedQuestionsWithSections: [],
          completedQuestionsCount,
          loading: false,
          initialRemainingQuestions: remainingQuestions,
          questionsCount: questionsCount,
          remainingQuestions,
          cardRefs,
        });
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });

        // TODO: handle error here
        console.log(error);
      });
  }

  swipe = (dir) => {
    const { remainingQuestions, initialRemainingQuestions, cardRefs } =
      this.state;

    if (remainingQuestions.length) {
      const toBeRemovedId =
        remainingQuestions[remainingQuestions.length - 1].id; // Find the card object to be removed

      const index = initialRemainingQuestions
        .map((question) => question.id)
        .indexOf(toBeRemovedId); // Index of the card to be removed from the deck

      // Swipe the card
      cardRefs[index].current.swipe(dir);
    }
  };

  swiped(direction, questionId, questionKey) {
    const { completedQuestionsCount } = this.state;
    this.storeSwipe(direction, questionId, questionKey);

    this.setState({
      completedQuestionsCount: completedQuestionsCount + 1,
    });
  }

  outOfFrame(questionId, sectionIndex) {
    const { remainingQuestions } = this.state;

    let interstitialIsVisible = false;
    let isFinalQuestion = false;
    let currentQuestionSectionIndex, nextQuestionSectionIndex;

    console.log('OUT OF FRAME');
    console.log(questionId);
    console.log(remainingQuestions);

    if (remainingQuestions.length) {
      const tmpRemainingQuestions = remainingQuestions.filter(
        (question) => question.id !== questionId
      );

      console.log(sectionIndex);
      const nextQuestion =
        tmpRemainingQuestions[tmpRemainingQuestions.length - 1];

      console.log(nextQuestion);

      if (nextQuestion && sectionIndex !== nextQuestion.sectionIndex) {
        console.log(`next section: ${nextQuestion.sectionInterstitialCopy}`);

        currentQuestionSectionIndex = sectionIndex;
        nextQuestionSectionIndex = nextQuestion.sectionIndex;
        interstitialIsVisible = true;
      }

      if (!nextQuestion) {
        isFinalQuestion = true;
        interstitialIsVisible = true;
      }

      this.setState({
        remainingQuestions: tmpRemainingQuestions,
        interstitialIsVisible,
        currentQuestionSectionIndex,
        nextQuestionSectionIndex,
        isFinalQuestion,
      });
    }
  }

  storeSwipe(direction, questionId, questionKey) {
    const { answersCount, assessmentKey } = this.state;
    const { updatePercentDone, user } = this.props;
    const storeAnswerUrl = `/students/answers`;
    let answer = 'Y';

    if (direction === 'left') {
      answer = 'N';
    }

    console.log(
      `Storing swipe for question: ${questionId} / ${questionKey} in direction: ${direction}. Answer: ${answer}.`
    );

    API.post(storeAnswerUrl, {
      assessmentKey,
      answer,
      questionKey,
      questionId,
      uuid: user.uuid,
    })
      .then((response) => {
        this.setState({ answersCount: answersCount + 1 });
        // Update percentDone in Redux
        // updatePercentDone(response.data);
      })
      .catch((error) => {
        // TODO: handle error
        console.log(error);
      });
  }

  closeInterstitial() {
    const { isFinalQuestion } = this.state;

    if (isFinalQuestion) {
      track(FINISH_ASSESSMENT);
      this.props.history.push('/dashboard');
    } else {
      this.setState({
        interstitialIsVisible: false,
      });
    }
  }

  render() {
    const {
      answersCount,
      cardRefs,
      completedQuestionsCount,
      currentQuestionSectionIndex,
      interstitialIsVisible,
      isFinalQuestion,
      loading,
      nextQuestionSectionIndex,
      remainingQuestions,
      sectionsCount,
      totalQuestionsCount,
      questionsCount,
    } = this.state;

    console.log(' -- RENDER --');
    console.log(answersCount);
    console.log(questionsCount);

    let sectionTitle = '';
    let sectionSubTitle = '';
    let currentSectionNumber = 1;

    if (remainingQuestions && remainingQuestions.length) {
      const currentQuestion = remainingQuestions[remainingQuestions.length - 1];
      console.log('CURRENT QUESTION');
      console.log(currentQuestion);
      sectionTitle = currentQuestion.sectionTitle;
      sectionSubTitle = currentQuestion.sectionSubTitle;
      currentSectionNumber = currentQuestion.sectionIndex + 1;
    }

    console.log('CURRENT STATE');
    console.log(this.state);

    const displayLoader = () => {
      return (
        <SpinnerWrapper>
          <LoadingSpinner animation="border" variant="secondary" />
        </SpinnerWrapper>
      );
    };

    const closeInterstitial = this.closeInterstitial;

    const displayCards = () => {
      return (
        <PageWrapper>
          <StudentAssessmentInterstitial
            closeInterstitial={closeInterstitial}
            currentQuestionSectionIndex={currentQuestionSectionIndex}
            isFinalQuestion={isFinalQuestion}
            isVisible={interstitialIsVisible}
            nextQuestionSectionIndex={nextQuestionSectionIndex}
            sectionsCount={sectionsCount}
          />
          <p>
            <strong>
              Step {currentSectionNumber}/{sectionsCount}
            </strong>
          </p>
          <SectionHeader>
            <SectionTitle>{sectionTitle}</SectionTitle>
            <SectionSubTitle>{sectionSubTitle}</SectionSubTitle>
          </SectionHeader>
          <div className="cardContainer">
            {remainingQuestions.map((question, index) => (
              <TinderCard
                ref={cardRefs[index]}
                className="swipe"
                key={question.id}
                onSwipe={(dir) => this.swiped(dir, question.id, question.key)}
                onCardLeftScreen={() =>
                  this.outOfFrame(question.id, question.sectionIndex)
                }
                preventSwipe={['up', 'down']}
              >
                <div className="tinderCard">
                  <div className="tinderCardInner">
                    <h3>{question.copy}</h3>
                  </div>
                </div>
              </TinderCard>
            ))}
          </div>
          <ButtonRow>
            <RoundButton onClick={() => this.swipe('left')}>
              <FontAwesomeIcon icon={faTimes} size="2x" />
            </RoundButton>
            <RoundButton onClick={() => this.swipe('right')}>
              <FontAwesomeIcon icon={faCheck} size="2x" />
            </RoundButton>
          </ButtonRow>
          <ProgressRow>
            {/* total questions: {this.state.totalQuestionsCount}
            <br />
            questions completed: {this.state.completedQuestionsCount}
            <br />
            percent complete:{' '}
            {Math.round(
              (this.state.completedQuestionsCount /
                this.state.totalQuestionsCount) *
                100
            )}
            %
            <br /> */}
            <ProgressBar value={answersCount} max={questionsCount} />
          </ProgressRow>
        </PageWrapper>
      );
    };

    const showLoadingOrCards = () => {
      if (loading) {
        return displayLoader();
      }

      return displayCards();
    };

    return (
      <Container fluid className="p-0 h-100">
        <Row className="h-100 justify-content-lg-center">
          <Col lg={6} xs={12}>
            {showLoadingOrCards()}
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({ user: state.user });
const mapDispatchToProps = { updatePercentDone };

export default connect(mapStateToProps, mapDispatchToProps)(StudentAssessment);
