import React from 'react';
import Logo from '../../../images/logo.png';
import styled from 'styled-components';

const HeaderWrapper = styled.div`
    display: flex;
    align-items: center;
    height: 80px;
    padding: 0px 20px;
`;

const LogoWrapper = styled.img`
    height: 50px;
`;

export default function HomeHeader() {

    return <HeaderWrapper>
        <LogoWrapper src={Logo} />
    </HeaderWrapper>

}