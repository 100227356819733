import React from 'react';
import { Modal } from 'react-bootstrap';
import { Dismiss20Regular as DismissIcon } from '@fluentui/react-icons';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const OpportunityModal = styled(Modal)``;

const OpportunityModalLiner = styled.div`
  padding: 1.5rem;
`;

const OpportunityModalHeader = styled(Modal.Header)`
  h3 {
    font-size: 1.5rem;
  }
`;

const HeaderLeft = styled.div``;

const EmployerName = styled.div`
  font-size: 1rem;
  color: #afafaf;
  font-weight: bold;
`;

const OpportunityModalBody = styled(Modal.Body)`
  h3 {
    font-size: 1rem;
  }
`;

const OpportunityModalFooter = styled(Modal.Footer)``;

const StudentOpportunityModal = (props) => {
  const { modalShow, toggleModal, modalData } = props;

  // TODO: move to a helper util
  const displayLocation = () => {
    if (modalData.performedAt === 'REMOTE') {
      return `Remote`;
    } else {
      return `${modalData.location}, ${modalData.stateName}`;
    }
  };

  if (modalShow) {
    return (
      <OpportunityModal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalShow}
        onHide={() => {
          toggleModal();
        }}
      >
        <OpportunityModalLiner>
          <OpportunityModalHeader>
            <HeaderLeft>
              <h3>{modalData.title}</h3>
              <EmployerName>
                {modalData.employerName} &middot; {displayLocation()}
              </EmployerName>
            </HeaderLeft>
            <button
              onClick={() => {
                toggleModal();
              }}
              className="ln-btn ln-icon-btn default"
            >
              <DismissIcon className="ln-icon" />
            </button>
          </OpportunityModalHeader>
          <OpportunityModalBody style={{ maxHeight: '70vh', overflow: 'auto' }}>
            <h3>About the Opportunity</h3>
            <p>{modalData.description}</p>
          </OpportunityModalBody>
          <OpportunityModalFooter
            style={{
              justifyContent: 'space-between',
            }}
          ></OpportunityModalFooter>
        </OpportunityModalLiner>
      </OpportunityModal>
    );
  }

  return null;
};

export default StudentOpportunityModal;

StudentOpportunityModal.propTypes = {
  modalShow: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  modalData: PropTypes.object.isRequired,
};
