import React, { cloneElement, Component, useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { Dismiss20Regular as DismissIcon } from '@fluentui/react-icons';

const PopUpWrapper = styled.div``;

class MobileSlidePopUpNoFooter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      className: false,
    };
  }

  toggle = (e, eventType) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    if (this.props[eventType]) {
      this.props[eventType]();
    }
    this.setState((state) => ({ className: !state.className }));
    setTimeout(
      () => this.setState((state) => ({ active: !state.active })),
      this.state.active ? 300 : 0
    );
  };

  render() {
    const { initiator, children } = this.props;
    const { active, className } = this.state;
    const initiatorElement = cloneElement(initiator, {
      onClick: (e) => {
        e.stopPropagation();
        e.preventDefault();
        this.toggle(e, 'onPopUpOpen');
      },
    });
    return (
      <PopUpWrapper>
        {initiatorElement}
        {active ? (
          <PopupContent
            onClose={(e) => this.toggle(e, 'onClose')}
            onOKClick={(e) => this.toggle(e, 'onOK')}
            open={className}
            children={children}
            {...this.props}
          />
        ) : null}
      </PopUpWrapper>
    );
  }
}

export default MobileSlidePopUpNoFooter;

const translate = keyframes`
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0);
  }
`;

const PopupWrapper = styled.div`
  background: #fff;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  z-index: 1001;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  max-height: 80vh;
  padding: 20px;
  transition: ease-out 0.3s;
  display: flex;
  flex-direction: column;

  @media (min-width: 576px) {
    max-width: 576px;
    left: calc((100vw - 576px) / 2);
  }

  &.active {
    transform: translateY(0);
  }

  &.hidden {
    transform: translateY(100%);
  }
`;

const Dimmer = styled.div`
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.7);

  &.active {
    display: block;
    z -index: 1000;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 10px;
  margin-bottom: 15px;
`;

const Title = styled.h5`
  font-size: 24px;
  margin: 0;
  padding-right: 10px;
`;

const Body = styled.div`
  display: flex;
  flex-grow: 1;
  overflow-y: auto;
  font-size: 16px;
`;

function PopupContent(props) {
  const { header, title, children, closeButton, open, onClose, style } = props;
  const [active, toggle] = useState(false);
  useEffect(() => {
    setTimeout(() => toggle(!active), 100);
  }, [open]);

  return (
    <div {...props}>
      <Dimmer className={active ? 'active' : 'hidden'} onClick={onClose} />
      <PopupWrapper style={style} className={active ? 'active' : 'hidden'}>
        {header ? (
          header
        ) : header === false ? null : (
          <Header>
            <Title>{title}</Title>
            {closeButton ? (
              closeButton
            ) : closeButton === false ? null : (
              <button onClick={onClose} className="ln-btn ln-icon-btn default">
                <DismissIcon className="ln-icon" />
              </button>
            )}
          </Header>
        )}
        <Body>{children ? children : null}</Body>
      </PopupWrapper>
    </div>
  );
}
