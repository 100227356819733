export function isNumber(n) {
  return /^-?[\d.]+(?:e-?\d+)?$/.test(n);
}

export function toInt(value) {
  if (isNumber(value) && value % 1 === 0) {
    return parseInt(value);
  }
  return value;
}
