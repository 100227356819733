import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

const SubcategoriesListWrapper = styled.div`
  width: 100%;
  padding-top: 0.25rem;
  padding-bottom: 2.25rem;
`;

const Subcategory = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1.25rem;
  width: 100%;
  margin-right: 0.75rem;
  background-color: #ffffff;
  border-radius: 12px;
  padding: 0.875rem;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.1);

  overflow-wrap: break-word;
  word-wrap: break-word;
`;

const SubcategoryLeft = styled.div``;

const SubcategoryName = styled.div`
  font-size: 1rem;
  line-height: 1.375rem;
  color: #000000;
`;

const SubcategoryRight = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  color: #d4d4d4;
`;

const AllSubcategoriesList = (props) => {
  const { subcategories, toggleSubcategoryPanel } = props;

  const subcategoriesList = (subcategories) => {
    return subcategories.map((subcategory, i) => {
      const { id, name } = subcategory;
      return (
        <Subcategory key={name} onClick={() => toggleSubcategoryPanel(id)}>
          <SubcategoryLeft>
            <SubcategoryName>{name}</SubcategoryName>
            {/* <SubcategoryDetails></SubcategoryDetails> */}
          </SubcategoryLeft>
          <SubcategoryRight>
            <FontAwesomeIcon icon={faChevronRight} />
          </SubcategoryRight>
        </Subcategory>
      );
    });
  };

  return (
    <SubcategoriesListWrapper>
      {subcategoriesList(subcategories)}
    </SubcategoriesListWrapper>
  );
};

export default AllSubcategoriesList;
