import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Cloud = styled.div`
  padding: 0.5rem 0 2rem;
  margin-left: -0.5rem;
  text-align: left;
`;

const Skill = styled.div`
  display: inline-block;
  padding: 0.75rem;
  background-color: #fbfbfb;
  margin: 0.5rem;
  border-radius: 0.875rem;
  font-size: 0.875rem;
  line-height: 0.875rem;
`;

const NoReults = styled.div`
  margin-left: 0.5rem; /* reset negative margin from Cloud */
`;

const SkillsCloud = (props) => {
  const { skills } = props;

  const displaySkillsCloud = (skills) => {
    if (skills && skills.length > 0) {
      return skills.map((skill, i) => {
        return <Skill key={`${skill}-${i}`}>{skill}</Skill>;
      });
    }

    return (
      <NoReults>
        <em>no results yet</em>
      </NoReults>
    );
  };

  return <Cloud>{displaySkillsCloud(skills)}</Cloud>;
};

export default SkillsCloud;

SkillsCloud.propTypes = {
  skills: PropTypes.array.isRequired,
};
