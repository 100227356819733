import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';
import {
  Receipt24Regular as ReceiptIcon,
  Phone24Regular as PhoneIcon,
  Prohibited24Regular as Prohibited,
} from '@fluentui/react-icons';
import { CircleProgressCard } from './ParentStatus';

const StatsContainer = styled(Container)`
  margin-top: 1rem;
`;

const Title = styled.h6`
  font-size: 0.875rem;
  text-transform: uppercase;
`;

const Wrapper = styled(Row)`
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.02);
  border-radius: 20px;
  background-color: #ffffff;
`;

const SectionCol = styled(Col)`
  padding: 20px;
`;

export default function StudentsStatus({
  totalCount,
  consent,
  joined,
  notJoined,
  loading,
}) {
  return (
    <>
      <Title>Student Status</Title>
      <StatsContainer fluid>
        <Wrapper className="mg-b">
          <SectionCol lg={4}>
            <CircleProgressCard
              loading={loading}
              title="Got Consent"
              percent={consent ? consent.percent : ''}
              color={'#3dd598'}
              icon={<ReceiptIcon />}
            />
          </SectionCol>
          <SectionCol lg={4}>
            <CircleProgressCard
              loading={loading}
              title="Joined Platform"
              percent={joined ? joined.percent : ''}
              color={'#ffc34e'}
              icon={<PhoneIcon />}
            />
          </SectionCol>
          <SectionCol lg={4}>
            <CircleProgressCard
              loading={loading}
              title="Didn't Join"
              percent={notJoined ? notJoined.percent : ''}
              color={'#fc5a5a'}
              icon={<Prohibited />}
            />
          </SectionCol>
        </Wrapper>
      </StatsContainer>
    </>
  );
}
