import React, { Component } from 'react';
import styled from 'styled-components';
import { API } from '../../../settings';
import { Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import OpportunityListItem from '../OpportunityListItem';

const OpportunitiesListWrapper = styled.div``;

const LoadingSpinner = styled(Spinner)`
  height: 50px;
  width: 50px;
  margin: 0 auto;
`;

class StudentSubcategoryOpportunities extends Component {
  state = {
    loading: true,
    opportunities: [],
    count: null,
    limit: 10,
  };

  componentDidMount() {
    this.getOpportunities();
  }

  // TODO: Implement load more on scroll functionality
  getOpportunities = () => {
    const { subcategoryId } = this.props;
    API.get(`/opportunities/subcategories/${subcategoryId}`)
      .then((res) => {
        this.setState({ ...res.data.opportunities, loading: false });
      })
      .finally(() => this.setState({ loading: false }));
  };

  bookmarkOpportunity = (index, opportunityId, isBookmarked) => {
    const {
      user: { uuid },
      subcategoryId,
    } = this.props;
    let { opportunities } = this.state;
    opportunities[index].isBookmarked = isBookmarked;
    this.setState({ opportunities });
  };

  render() {
    const { loading, opportunities } = this.state;

    if (loading) {
      return (
        <center>
          <LoadingSpinner animation="border" />
        </center>
      );
    } else {
      return (
        <OpportunitiesListWrapper>
          {opportunities.map((opportunity, i) => {
            const {
              id,
              title,
              Employer,
              performedAt,
              location,
              State,
              isBookmarked,
            } = opportunity;
            return (
              <OpportunityListItem
                key={id}
                to={`/student/opportunity/${id}`}
                linkState={opportunity}
                title={title}
                // imgSrc='https://upload.wikimedia.org/wikipedia/commons/thumb/5/51/Facebook_f_logo_%282019%29.svg/900px-Facebook_f_logo_%282019%29.svg.png'
                // imgAlt='Facebook logo'
                companyName={Employer ? Employer.name : ''}
                location={
                  performedAt === 'REMOTE'
                    ? 'Remote'
                    : `${location}, ${State.code}`
                }
                isBookmarked={isBookmarked}
                id={id}
                onBookmark={(id, isBookmarked) =>
                  this.bookmarkOpportunity(i, id, isBookmarked)
                }
              />
            );
          })}
        </OpportunitiesListWrapper>
      );
    }
  }
}

export default connect((state) => ({
  user: state.user,
}))(StudentSubcategoryOpportunities);
