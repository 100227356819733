import {
    SET_PARENT_LOADING,
    CHILD_ACTIVITY_GET,
    CHILDREN_GET
} from '../constants/action-types';
import { API } from '../../settings';
  
export function getChildActivity(childId) {
  return function (dispatch) {
    dispatch({
      type: SET_PARENT_LOADING,
    });

    return API.get(`/parent/activity/${childId}`).then((res) => {
      dispatch({
        type: CHILD_ACTIVITY_GET,
        payload: { data: res.data.activity, childId, name: res.data.name },
      })
  });
  };
}

export function getChildren() {
  return function (dispatch) {
    dispatch({
      type:  SET_PARENT_LOADING,
    });
    return API.get(`/parent/children`).then((res) => {
      dispatch({
        type: CHILDREN_GET,
        payload: { loading: false, data: res.data },
      })
  });
  };
}
  