import React, { useEffect } from 'react';
import UIWrapper from './shared/ui-wrapper';
import JobList from './shared/job-list';
import MainHeader from './shared/main-header';
import { connect } from 'react-redux';
import { getChildActivity } from '../../redux/actions/parent';
import { useLocation, useParams } from 'react-router-dom';
import { ACTIVITY_TYPES } from './shared/constants';

function JobsTab( { title, type, ...props }) {

    const location = useLocation();
    const { id } = useParams();

    console.log('tab');

    useEffect(() => {
        props.getChildActivity(id)
    }, [id, location]);

    const source = type === ACTIVITY_TYPES.SAVED_OPTIONS ? 'saved' : 'interested';
    const jobs = props[source][id]?.data ? props[source][id]?.data : [];

    return <UIWrapper loading={props.loading}>
        <MainHeader title={title} detail={jobs.length} />
        <JobList jobs={jobs} hideHeadline />
    </UIWrapper>
};


const mapStateToProps = (state) => ({
    ...state.parent
});

const mapDispatchToProps = { getChildActivity }

export default connect(
    mapStateToProps, 
    mapDispatchToProps
)(JobsTab);

