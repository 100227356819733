import React from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import CounselorProfileForm from './CounselorProfileForm';
import { toast } from 'react-toastify';
import { API } from '../../../../settings';
import { updateUser } from '../../../../features/users/userSlice';

const PageWrapper = styled.div`
  min-height: 100vh;
  min-height: -webkit-fill-available;
  position: relative;
`;

class CounselorProfile extends React.Component {
  constructor(props) {
    super(props);
    // const { user: {
    //   uuid,
    //   firstName,
    //   lastName,
    //   email,
    // } } = { ...props }
    this.state = {
      uuid: '',
      firstName: '',
      lastName: '',
      email: '',
      formIsSubmitting: false,
      loading: false,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFlashClick = this.handleFlashClick.bind(this);
  }

  componentDidMount() {
    const {
      user: { uuid, firstName, lastName, email },
    } = { ...this.props };

    this.setState({
      uuid,
      firstName,
      lastName,
      email,
    });
  }

  handleFlashClick() {
    this.setState({ flashMessage: '' });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  updateUserProfile({ uuid, firstName, lastName, email }) {
    this.setState({ loading: true });
    return API.put(`/users/${uuid}/profile`, {
      firstName,
      lastName,
      email,
    });
  }

  async handleSubmit(values, { resetForm, setSubmitting, setFieldError }) {
    const {
      user: { uuid },
    } = this.props;
    const { firstName, lastName, email } = values;
    setSubmitting(true);

    try {
      const response = await this.updateUserProfile({
        uuid,
        firstName,
        lastName,
        email,
      });
      if (response) {
        this.props.updateUser({ firstName, lastName, email });
        toast.success(`Profile updated`);
      }
      setSubmitting(false);
      resetForm();
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        setFieldError('firstName', error.response.data.error);
        setFieldError('lastName', error.response.data.error);
        setFieldError('email', error.response.data.error);
      }

      setSubmitting(false);
    }

    this.setState({ formIsSubmitting: false });
  }

  render() {
    const { formIsSubmitting, initialState } = this.state;

    return (
      <PageWrapper>
        <Row>
          <Col xs={12}>
            <CounselorProfileForm
              handleSubmit={this.handleSubmit}
              handleInputChange={this.handleInputChange}
              formIsSubmitting={formIsSubmitting}
              {...this.state}
            />
          </Col>
        </Row>
      </PageWrapper>
    );
  }
}

const mapStateToProps = (state) => ({ user: state.user });

export default connect(mapStateToProps, { updateUser })(CounselorProfile);
