import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const ParentNameWrapper = styled.div`
  margin-top: 1.5rem;
`;

const NameTitle = styled.h6`
  font-size: 0.75rem;
  color: #858585;
  margin: 0;
  padding: 0;
  line-height: 0.75rem;
  text-transform: uppercase;
`;

const Name = styled.div`
  margin-top: 0.125rem;
  font-size: 0.875rem;
  font-weight: bold;
  color: #171725;
  overflow-wrap: break-word;
`;

const StudentSidebarParentName = (props) => {
  const { parentFirstName, parentLastName } = props;

  if (!parentFirstName || !parentLastName) {
    return null;
  }

  return (
    <ParentNameWrapper>
      <NameTitle>Parent</NameTitle>
      <Name>
        {parentFirstName} {parentLastName}
      </Name>
    </ParentNameWrapper>
  );
};

export default StudentSidebarParentName;

StudentSidebarParentName.propTypes = {
  parentFirstName: PropTypes.string,
  parentLastName: PropTypes.string,
};
