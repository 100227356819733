import React from 'react';
import { Container, Row, Col, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import AuthService from '../services/authService';

const SettingsHeader = styled.div`
  text-align: left;
  width: 100%;
`;

const BackLink = styled(Link)`
  color: #000000;
  display: inline-block;
  font-size: 14px;
  &:hover {
    color: #000000;
  }
`;

const PageTitle = styled.h2`
  color: #000000;
  font-size: 25px;
  margin-top: 25px;
`;

const StyledRow = styled(Row)`
  padding: 25px;
`;

const PasswordForm = styled(Form)`
  margin-top: 25px;
  margin-bottom: auto;
`;

const Label = styled(Form.Label)`
  font-size: 14px;
  color: #1a1a1a;
`;

const FormControl = styled(Form.Control)`
  border: 1px solid #ebebeb;
  height: 2.75rem;
  ::placeholder {
    color: #afafaf;
    opacity: 1;
    font-weight: 300;
  }
`;

const Button = styled.button`
  width: 100%;
  height: 55px;
  border-radius: 12px;
  color: #ffffff;
  background-color: #1a1a1a;
  border: none;
  margin-top: auto;
`;

const FormFields = styled.div`
  margin-bottom: auto;
`;

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = { email: '', password: '', message: '', loading: false };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    const { login } = this.props;

    this.setState({
      message: '',
      loading: true,
    });

    AuthService.login(this.state.email, this.state.password).then(
      (userData) => {
        console.log('LOGGED IN');
        this.setState({ loading: false });
        login(userData);
        // return <Redirect to="/admin/dashboard" />;
      },
      (error) => {
        console.log('ERROR');
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        console.log(resMessage);

        this.setState({
          loading: false,
          message: resMessage,
        });
      }
    );
  }

  render() {
    return (
      <Container fluid className="p-0 h-100">
        <StyledRow className="justify-content-lg-center">
          <Col lg={6} xs={12}>
            <SettingsHeader>
              <BackLink to="/settings">
                <FontAwesomeIcon icon={faChevronLeft} />
              </BackLink>
            </SettingsHeader>
            <PageTitle>Change password</PageTitle>
            <FormFields>
              <PasswordForm onSubmit={this.handleSubmit}>
                <Form.Group controlId="currentPassword">
                  <Label>Password</Label>
                  <FormControl
                    type="password"
                    name="password"
                    placeholder="Current password"
                    onChange={this.handleInputChange}
                  />
                </Form.Group>
                <Form.Group controlId="newPassword">
                  <Label>New password</Label>
                  <FormControl
                    type="password"
                    name="newPassword"
                    placeholder="New password"
                    onChange={this.handleInputChange}
                  />
                </Form.Group>
                <Form.Group controlId="confirmNewPassword">
                  <Label>Confirm new password</Label>
                  <FormControl
                    type="password"
                    name="confirmNewPassword"
                    placeholder="Confirm new password"
                    onChange={this.handleInputChange}
                  />
                </Form.Group>
              </PasswordForm>
            </FormFields>{' '}
          </Col>
        </StyledRow>
        <StyledRow className="justify-content-lg-center fixed-bottom">
          <Col lg={6} xs={12}>
            <Button variant="primary" type="submit">
              Update password
            </Button>
          </Col>
        </StyledRow>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({ user: state.user });

export default connect(mapStateToProps)(ChangePassword);
