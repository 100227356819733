import React from 'react';
import { Form } from 'react-bootstrap';

function AvailablePositions(props) {
  
  return (
    <Form.Control {...props} as="select">
      <option disabled value={-1}>
        How many positions are available?
      </option>
      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((category, i) => (
        <option value={`${category}`}>{category}</option>
      ))}
    </Form.Control>
  );
}

export default AvailablePositions;
