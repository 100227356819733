import React from 'react';
import { Container, Row, Col, Modal } from 'react-bootstrap';
import styled from 'styled-components';
import dotenv from 'dotenv';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { API } from '../../settings';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { compareSchoolNames } from '../../utils/arrayOps';
import AddCounselor from './AddCounselor';

dotenv.config();

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const PageHeader = styled.h3`
  margin-top: 6px;
  margin-bottom: 15px;
`;

const PlusIcon = styled(FontAwesomeIcon)`
  font-size: 0.875rem;
  font-weight: bold;
  margin-right: 0.5rem;
  color: #858585;
`;

const AddCounselorButton = styled.button`
  background: #333333;
  height: auto;
  border-radius: 10px;
  font-size: 0.875rem;
  color: #fafafa;
  font-weight: bold;
  border: none;
  padding: 0.5rem 0.625rem;
`;

const StudentsWrapper = styled.div`
  background: #ffffff;
  padding: 1.5rem;
  border-radius: 1.25rem;
  table,
  th,
  td {
    border: none;
  }
  thead {
    th {
      border: none;
    }
  }
  tbody:before {
    content: '@';
    display: block;
    line-height: 0.5rem;
    text-indent: -99999px;
  }
  td {
    font-size: 0.875rem;
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
  }
`;

const FilterWrapper = styled.div`
  position: relative;
`;

const Filter = styled.input`
  margin-bottom: 1.25rem;
  width: 100%;

  font-size: 0.875rem;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  padding-left: 2.25rem;
  border-radius: 0.625rem;
  border: 1px solid #e2e2ea;
  font-family: 'Nunito';
  &::placeholder {
    color: #929292;
    opacity: 1;
  }
`;

const SearchIcon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 14px;
  left: 0.625rem;
  color: #92929d;
`;

const BootstrapTableWrapper = styled.div`
  .table td {
    overflow-wrap: break-word;
  }
  .table th {
    background: #fbfbfb;
    color: #858585;
    font-size: 0.6875rem;
    &:first-child {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }
    &:last-child {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }
  .react-bootstrap-table-pagination {
    margin-top: 2rem;
    justify-content: center;
    div {
      display: none;
    }
    div.react-bootstrap-table-pagination-list {
      display: flex;
      justify-content: center;
      ul {
        float: none;
      }
    }
    .page-link {
      border-color: #797878;
      color: #797878;
    }
    .page-item.active .page-link {
      color: #ffffff;
      background-color: #000000;
      border-color: #000000;
    }
  }
`;

export default class Counselors extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: '',
      filteredCounselors: [],
      loading: false,
      counselors: [],
      schools: [],
      showAddCounselor: false,
    };

    this.getCounselors = this.getCounselors.bind(this);
    this.getSchools = this.getSchools.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleShowAddCounselor = this.handleShowAddCounselor.bind(this);
    this.handleCloseAddCounselor = this.handleCloseAddCounselor.bind(this);
    this.handleAddCounselor = this.handleAddCounselor.bind(this);
  }

  componentDidMount() {
    this.getCounselors();
    this.getSchools();
  }

  getCounselors = () => {
    API.get(`/counselors/all`)
      .then((response) => {
        this.setState({
          filteredCounselors: response.data.counselors,
          counselors: response.data.counselors,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getSchools = () => {
    API.get(`/location/schools`)
      .then((response) => {
        console.log(response);
        this.setState({
          schools: response.data.schools,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleFilterChange = (event) => {
    const { counselors } = this.state;
    const filter = event.target.value;

    const filteredByName = counselors.filter((school) =>
      school.name.toLowerCase().includes(filter.toLowerCase())
    );

    const filteredCounselors = [...filteredByName].sort(compareSchoolNames);

    this.setState({ filter, filteredCounselors });
  };

  handleShowAddCounselor = () => {
    this.setState({ showAddCounselor: true });
  };

  handleCloseAddCounselor = () => {
    this.setState({ showAddCounselor: false });
  };

  handleAddCounselor = (values) => {
    const { firstName, lastName, email, schoolId } = values;

    API.post(`/counselors/`, {
      firstName,
      lastName,
      email,
      schoolId: schoolId,
    })
      .then((response) => {
        this.handleCloseAddCounselor();
        this.getCounselors();
        toast.success(`${firstName} ${lastName} added`);
      })
      .catch((error) => {
        console.log(error);
        toast.error(`There was an error adding ${firstName} ${lastName}`);
      });
  };

  render() {
    const { filter, filteredCounselors, showAddCounselor, schools } =
      this.state;

    const firstNameFormatter = (cell, row) => {
      return <Link to={`/super-admin/counselors/${row.id}`}>{cell}</Link>;
    };

    const columns = [
      {
        text: 'First Name',
        dataField: 'firstName',
        sort: true,
        formatter: firstNameFormatter,
        classes: 'firstName',
      },
      {
        text: 'Last Name',
        dataField: 'lastName',
        sort: true,
        classes: 'lastName',
      },
      {
        text: 'Email',
        dataField: 'email',
        sort: true,
        classes: 'email',
      },
      {
        text: 'School',
        dataField: 'schoolName',
        sort: true,
        classes: 'schoolName',
      },
    ];

    const paginationConfig = {
      sizePerPage: 100,
      hideSizePerPage: true,
    };

    return (
      <Container fluid className="p-0 h-100 ">
        <Row>
          <Col xs={12}>
            <HeaderWrapper>
              <PageHeader>Counselors</PageHeader>
              <AddCounselorButton onClick={() => this.handleShowAddCounselor()}>
                <PlusIcon icon={faPlus} size="1x"></PlusIcon>Add Counselor
              </AddCounselorButton>
              <AddCounselor
                handleCloseAddCounselor={this.handleCloseAddCounselor}
                showAddCounselor={showAddCounselor}
                handleAddCounselor={this.handleAddCounselor}
                schools={schools}
              />
            </HeaderWrapper>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <StudentsWrapper>
              <FilterWrapper>
                <Filter
                  type="text"
                  value={filter}
                  placeholder="Search by Name..."
                  onChange={this.handleFilterChange}
                />
                <SearchIcon icon={faSearch} size="1x" />
              </FilterWrapper>
              <BootstrapTableWrapper>
                <BootstrapTable
                  columns={columns}
                  data={filteredCounselors}
                  keyField="id"
                  pagination={paginationFactory(paginationConfig)}
                />
              </BootstrapTableWrapper>
            </StudentsWrapper>
          </Col>
        </Row>
      </Container>
    );
  }
}
