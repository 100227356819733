import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Switch, Route, useRouteMatch, useHistory, useLocation } from 'react-router-dom';

import AssessmentInstructions from '../components/parent/instructions/Assessment';
import OpportunityInstructions from '../components/parent/instructions/Opportunity';
import ChildrenList from '../components/parent/children-list';
import Child from '../components/student-activity';
import JobsTab from '../components/student-activity/jobs-tab';
import Job from '../components/student-activity/job';
import { ACTIVITY_TYPES } from '../components/parent/shared/constants';



function ParentRoutes({user, students, ...p} ) {
    let { path } = useRouteMatch();
    const history = useHistory();

    if (user?.userType !== 'PARENT') history.replace('/');

    
    return (
        <Switch>
            <Route
                path={`${path}/instructions/assessment`}
                render={(props) => <AssessmentInstructions {...props} />}
            />
            <Route
                path={`${path}/instructions/opportunity`}
                render={(props) => <OpportunityInstructions {...props} />}
            />
            <Route
                path={`${path}/children`}
                render={(props) => <ChildrenList {...props} />}
            />
            <Route
                exact
                path={`${path}/child/:id`}
                render={(props) => <Child {...props} />}
            />
            <Route
                exact
                path={`${path}/child/interested-in/:id`}
                render={(props) => <JobsTab {...props} type={ACTIVITY_TYPES.INTERESTED} title='Interested In' />}
            />
            <Route
                exact
                path={`${path}/child/saved/:id`}
                render={(props) => <JobsTab  {...props} type={ACTIVITY_TYPES.SAVED_OPTIONS} title='Saved Options' />}
            />
            <Route
                exact
                path={`${path}/job/:id`}
                render={(props) => <Job {...props} />}
            />
        </Switch>
    );
}

const mapStateToProps = (state) => ({ user: state.user, students: state.parent.children });
export default connect(mapStateToProps)(ParentRoutes);
