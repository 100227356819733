import React from 'react';
import { Col, Form as BootstrapForm, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { Form, Formik, useField } from 'formik';
import * as Yup from 'yup';
import EmployerProfileSubmitButton from './EmployerProfileSubmitButton';
import Avatar from 'react-avatar';
import ConfirmLn from '../../common/ConfirmLn';
import { Delete24Regular as DeleteIcon } from '@fluentui/react-icons';

const ProfileAvatar = styled(Avatar)`
  font-family: Nunito;
`;

const StyledLabel = styled.label`
  margin-top: 1rem;
  font-size: 14px;
`;

const StyledInput = styled.input`
  &.error {
    border: 1px solid red;
  }
`;

const StyledErrorMessage = styled.div`
  font-size: 14px;
  color: red;
  width: 400px;
  margin-top: 0.25rem;
`;

const StyledRow = styled(Row)`
  // padding: 25px;
`;

const ProfileForm = styled(Form)`
  margin-bottom: auto;
  height: 100%;
`;

const ButtonWrapper = styled.div`
  padding-top: 15px;
`;

const ButtonStyled = styled.button`
  color: #ffffff;
  background-color: #999999;
  border: none;
  margin-top: auto;
`;

const MyTextInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <>
      <StyledLabel htmlFor={props.id || props.name} className="form-label">
        {label}
      </StyledLabel>
      <StyledInput
        className={`text-input form-control ${
          meta.touched && meta.error ? 'error' : null
        }`}
        {...field}
        {...props}
      />
      {meta.touched && meta.error ? (
        <StyledErrorMessage className="error">{meta.error}</StyledErrorMessage>
      ) : null}
    </>
  );
};

const validationSchema = Yup.object({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  email: Yup.string().required('Required').email('Email is not valid'),
});

const UserProfileForm = (props) => {
  const { firstName, lastName, email } = props;
  const { handleSubmit, handleInputChange } = props;

  return (
    <Formik
      enableReinitialize
      initialValues={{ firstName, lastName, email }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(props) => (
        <div>
          <StyledRow className="justify-content-lg-center">
            <Col lg={12} xs={12}>
              {/* <ProfileForm> */}
              <Row>
                <Col xs={12}>
                  <div className="horizontal-flex align-center children-mg-r">
                    <ProfileAvatar
                      className="profileAvatar"
                      round={true}
                      size={50}
                      name={`${firstName} ${lastName}`}
                      color="#797878"
                    />
                    {/* <ButtonStyled label="Change Picture" value="Change Picture">Change Picture</ButtonStyled> */}
                    {/* <button for="choose_profile_picture" className='ln-btn'><span>Change Picture</span></button> */}
                    {/* <input type="file" id="choose_profile_picture" style={{ display: "none" }} /> */}
                    {/* <label for="choose_profile_picture" className='ln-btn default' style={{ margin: 0 }}>Select file</label> */}
                    <ConfirmLn
                      renderBtn={
                        <button className="ln-btn ln-icon-btn negative">
                          <DeleteIcon className="ln-icon" />
                        </button>
                      }
                      danger
                      position="right"
                      title="Are you sure you want to delete the profile picture?"
                      onOk={() => console.log()}
                      okText="Yes, Delete"
                      noText="No, Cancel"
                    />
                    {/* <Button>
                      <FontAwesomeIcon icon={faTrash} />
                    </Button> */}
                  </div>
                </Col>
              </Row>
              <BootstrapForm.Group controlId="firstName">
                <MyTextInput
                  label="First Name"
                  name="firstName"
                  placeholder=""
                  onChange={handleInputChange}
                  value={firstName}
                />
              </BootstrapForm.Group>
              <BootstrapForm.Group controlId="lastName">
                <MyTextInput
                  label="Last Name"
                  name="lastName"
                  placeholder=""
                  onChange={handleInputChange}
                  value={lastName}
                />
              </BootstrapForm.Group>
              <BootstrapForm.Group controlId="email">
                <MyTextInput
                  label="Contact Email Address"
                  name="email"
                  placeholder=""
                  onChange={handleInputChange}
                  value={email}
                />
              </BootstrapForm.Group>
              <BootstrapForm.Group>
                <ButtonWrapper>
                  <EmployerProfileSubmitButton />
                </ButtonWrapper>
              </BootstrapForm.Group>
              {/* </ProfileForm> */}
            </Col>
          </StyledRow>
        </div>
      )}
    </Formik>
  );
};

export default UserProfileForm;
