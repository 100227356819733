import React, { useState } from 'react';

import { Modal } from 'react-bootstrap';
import {
  ArrowUpload20Regular as UploadIcon,
  Dismiss20Regular as DismissIcon,
  Send24Regular as SendIcon,
} from '@fluentui/react-icons';
import Loader from '../common/Loader';
import Dropzone from 'react-dropzone';
import FileItem from '../common/FileItem';

import { API } from '../../settings';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

const sectionStyle = {
  width: '100%',
  height: '200px',
  overflow: 'auto',
  textAlign: 'center',
  margin: 0,
  borderRadius: '15px',
  padding: '10px',
  border: 'dashed 1px #c6c6c6',
};


function UploadDocumentsModal({ onFinish }) {

  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [files, setFiles] = useState([]);
  const { uuid } = useParams();
  const studentId = uuid;

  const toggleModal = () => setModal((m) => !m);

  const uploadDocuments = (_files) => setFiles((f) => f.concat(_files));
  
  const removeFile = (index) => setFiles((_files) => _files.splice(index, 1));

  const sendFiles = async () => {
    setLoading(true);
    let documents = [];
    
    for (let i = 0; i < files.length; i++) {
      documents.push({
        filename: files[i].name,
        filetype: files[i].type,
        body: await toBase64(files[i]),
      });
    };

    try {
      await API.post(`/students/uploads/${studentId}`, { documents });
      setFiles([]);
      setModal(false);
      toast.success('Files uploaded');
    } catch (err) {
      toast.error(err.response);
    } finally {
      setLoading(false);
      onFinish()
    }
  
  }

  const tempRenderUploaded = () => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
          height: '100%',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            height: '100%',
            justifyContent: 'center',
          }}
        >
          <div style={{ textAlign: 'left' }}>
            <h5 className="no-select">
              Drag your files here, or{' '}
              <text style={{ color: '#ffc34e' }}>browse</text>
            </h5>
          </div>
        </div>
      </div>
    );
  };

  const filesLength = files.length;
        
  return (
      <>
        <button style={{ padding: 5 , height: 30 }} onClick={toggleModal} className="ln-btn primary">
          <span style={{ fontSize: 14 }}>Upload</span>
        </button>
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={modal}
          onHide={toggleModal}
        >
          <Loader active={loading} dimmer={true} />
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              Upload new documents
            </Modal.Title>
            <div className="horizontal-flex align-center children-mg-l">
              {/* <button className='ln-btn default'><DownloadIcon className='ln-icon' /><span>Download Documents</span></button> */}
              <button
                onClick={toggleModal}
                className="ln-btn ln-icon-btn default"
              >
                <DismissIcon className="ln-icon" />
              </button>
            </div>
          </Modal.Header>
          <Modal.Body style={{ maxHeight: '70vh', overflow: 'auto' }}>
            <div style={{ position: 'relative' }}>
              {loading ? (
                <div
                  style={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '100%',
                      width: '100%',
                    }}
                  >
                    <Loader active />
                  </div>
                </div>
              ) : null}
              <Dropzone
                onDrop={(acceptedFiles) => uploadDocuments(acceptedFiles)}
                style={{ height: '200px', overflow: 'auto' }}
              >
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <div style={sectionStyle}>{tempRenderUploaded()}</div>
                    </div>
                  </section>
                )}
              </Dropzone>
              {filesLength ? (
                <div
                  style={{
                    height: '200px',
                    overflowY: 'auto',
                    width: '100%',
                    padding: '10px',
                    marginTop: '10px',
                  }}
                >
                  {files.map((file, i) => (
                    <FileItem
                      key={i}
                      onRemove={() => removeFile(i)}
                      name={file.name}
                    />
                  ))}
                </div>
              ) : null}
            </div>
          </Modal.Body>
          <Modal.Footer
            style={{
              justifyContent: 'center',
            }}
          >
            {filesLength ? (
              <button
                onClick={sendFiles}
                disabled={loading}
                className="ln-btn primary"
              >
                <SendIcon className="ln-icon" />
                <span>Upload</span>
              </button>
            ) : null}
          </Modal.Footer>
        </Modal>
      </>
  );
  
}

export default UploadDocumentsModal;

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
});

