import React from 'react';
import styled from 'styled-components';
import check from '../../../images/check.svg';
import { INNER_PADDING, SPACING } from '../shared/default-styles';

const ActivityDescription = styled.p`
    margin-top: 10px;
    padding-left: ${SPACING};
`;

const InviteAcceptedWrapper = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 15px;
    align-items: center;
    ${INNER_PADDING};

    & img {
        margin-right: 25px;
    }
`;

export default function InviteAccepted({ name }) {
    return <InviteAcceptedWrapper>
        <img src={check} alt='check' />
        <ActivityDescription>{name} joined LaunchNow App.</ActivityDescription>
    </InviteAcceptedWrapper>
}
