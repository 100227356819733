import React from 'react';
import styled from 'styled-components';
import { Link, NavLink, useRouteMatch, withRouter } from 'react-router-dom';
import NavListItem from '../../Settings/NavListItem';
import { SignOut20Regular as SignOutIcon } from '@fluentui/react-icons';

const LeftNav = styled.div`
  flex: 0 0 220px;
  height: 100%;
`;

const NavList = styled.ul`
  list-style-type: none;
  display: block;
  margin: 0;
  padding: 0;
`;

const SettingsNavBar = (props) => {
  const { url } = useRouteMatch('/counselor/settings');
  const links = [
    { text: 'My Profile', url: '', exact: true },
    { text: 'Password', url: '/password' },
  ];

  return (
    <LeftNav>
      <NavList>
        {links.map((item, i) => (
          <NavListItem {...item} url={url + item.url} />
        ))}
        <NavListItem
          rightIcon={<SignOutIcon className="ln-icon" />}
          text="Logout"
          url="/logout"
        />
        {/* <NavListItem text="My Profile" url={`${url}`} />
        <NavListItem text="My Business" url={`${url}/my-business`} />
        <NavListItem text="Password" url={`${url}/password`} />
        <NavListItem text="Documents" url={`${url}/documents`} />
        <NavListItem text="Logout" url={`${url}/logout`} /> */}
      </NavList>
    </LeftNav>
  );
};

export default withRouter(SettingsNavBar);
