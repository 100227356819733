import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import SkillsCloud from '../shared/assessment/SkillsCloud';

const SkillsWrapper = styled.div``;

const SkillsCloudWrapper = styled.div`
  max-height: 100%;
  overflow-y: scroll;
  height: 290px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Title = styled.h6`
  font-size: 1rem;
`;

const StudentSkills = (props) => {
  const { skills } = props;

  return (
    <SkillsWrapper>
      <Title>Skills</Title>
      <SkillsCloudWrapper>
        <SkillsCloud skills={skills} />
      </SkillsCloudWrapper>
    </SkillsWrapper>
  );
};

export default StudentSkills;

StudentSkills.propTypes = {
  skills: PropTypes.array.isRequired,
};
