import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRocket, faPlayCircle } from '@fortawesome/free-solid-svg-icons';
import ProgressBar from './ProgressBar';

const AssessmentTrackerContainer = styled.div`
  display: flex;
  padding: 10px 0;
  margin-top: 12px;
`;

const PercentageContainer = styled.div`
  width: 50%;
  margin-right: 5px;
  border-radius: 0.75rem;
  padding: 0.25rem 0.75rem;
  display: flex;
  align-items: center;
  background-color: #ffffff;
`;

const RocketBox = styled.div`
  background-color: rgb(249, 249, 249);
  color: rgb(212, 212, 212);
  width: 2rem;
  height: 2rem;
  font-size: 1.25rem;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 0.25rem;
`;

const PercentViz = styled.div`
  width: 80%;
  display: flex;
  align-items: center;
`;

const PercentContentsWrapper = styled.div`
  width: 100%;
`;

const PercentCopy = styled.div`
  font-size: 0.75rem;
  text-align: left;
  margin-top: 4px;
  padding-top: 0rem;
  padding-left: 0.75rem;
  width: 100%;
`;
const PercentBarContainer = styled.div`
  padding-left: 0.75rem;
  width: 100%;
`;

const ButtonContainerLink = styled(Link)`
  width: 50%;
  margin-left: 5px;
  padding: 0.25rem 0.75rem;
  border-radius: 12px;
  display: flex;
  align-items: center;
  background: #000000;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

const MiniButtonContainer = styled.div`
  background-color: #ffffff;
  color: #000000;
  width: 2rem;
  height: 2rem;
  font-size: 1.25rem;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 0.25rem;
`;

const ButtonCopy = styled.div`
  color: #ffffff;
  text-align: left;
`;

const Bigger = styled.div`
  font-size: 0.8rem;
  line-height: 0.7rem;
  text-align: left;
  padding-top: 0.1rem;
  padding-left: 0.5rem;
`;

const Smaller = styled.div`
  font-size: 0.65rem;
  line-height: 0.75rem;
  text-align: left;
  padding-top: 0.2rem;
  padding-left: 0.5rem;
`;

const StudentProfileAssessmentTracker = (props) => {
  const { percentDone, user } = props;

  return (
    <AssessmentTrackerContainer>
      <PercentageContainer>
        <RocketBox>
          <FontAwesomeIcon icon={faRocket} />
        </RocketBox>
        <PercentViz>
          <PercentContentsWrapper>
            <PercentCopy>{percentDone}%</PercentCopy>
            <PercentBarContainer>
              <ProgressBar value={percentDone} max={100} />
            </PercentBarContainer>
          </PercentContentsWrapper>
        </PercentViz>
      </PercentageContainer>
      <ButtonContainerLink to="/assessment-intro-1">
        <ButtonContainer>
          <MiniButtonContainer>
            <FontAwesomeIcon icon={faPlayCircle} />
          </MiniButtonContainer>
          <ButtonCopy>
            <Bigger>Complete</Bigger>
            <Smaller>your assessment</Smaller>
          </ButtonCopy>
        </ButtonContainer>
      </ButtonContainerLink>
    </AssessmentTrackerContainer>
  );
};

export default StudentProfileAssessmentTracker;
