import React from 'react';
import { Container, Row, Col, Table } from 'react-bootstrap';
import styled from 'styled-components';
import dotenv from 'dotenv';
import { API } from '../settings';

dotenv.config();

const PageHeader = styled.h3`
  margin-top: 6px;
`;

const SectionTitle = styled.h5`
  padding: 0;
  marging: 0;
`;

export default class Assessment extends React.Component {
  constructor(props) {
    super(props);

    this.state = { loading: false, questions: [] };
  }

  componentDidMount() {
    const assessmentKey = 'ASSESSMENT_1';

    API.get(`/assessments/${assessmentKey}`).then((response) => {
      const { assessment } = response.data;

      this.setState({
        questions: assessment.questions,
      });

      return response.data;
    });
  }

  render() {
    const questionSections = () => {
      const sections = this.state.questions.map((section, index) => {
        return (
          <div>
            <SectionTitle>Section {index + 1}</SectionTitle>
            <p>
              Section Title: {section.title}
              <br />
              Section Subtitle: {section.subTitle}
            </p>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Copy</th>
                  <th>Key</th>
                </tr>
              </thead>
              <tbody>{displayQuestions(section.questions)}</tbody>
            </Table>
          </div>
        );
      });

      return sections;
    };

    const displayQuestions = (questions) => {
      const questionsToDisplay = questions.map((question) => {
        return (
          <tr>
            <td>{question.copy}</td>
            <td>
              <code>{question.key}</code>
            </td>
          </tr>
        );
      });

      return questionsToDisplay;
    };

    return (
      <Container className="p-0 h-100 ">
        <Row>
          <Col xs={12}>
            <PageHeader>Assessment Questions</PageHeader>
            {questionSections()}
          </Col>
        </Row>
      </Container>
    );
  }
}
