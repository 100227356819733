import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import StudentNavItem from './StudentNavItem';

const NavBarWrapper = styled.div`
  width: 100%;
  position: absolute;
  z-index: 3;
  left: 0;
  bottom: 1rem;
  display: flex;
  justify-content: center;
  overflow: hidden;
`;

const NavBar = styled.div`
  background-color: #ffc34e;
  width: 100%;
  margin: 0 1rem;
  border-radius: 23px;
  color: #ffffff;
  font-size: 1.5rem;
  line-height: 1.5rem;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
`;

const NavBarLink = styled(NavLink)`
  flex-basis: 25%;
  display: flex;
  padding: 0 2.5rem;
  color: rgba(255, 255, 255, 0.4);

  &:active {
    color: rgba(255, 255, 255, 0.4);
  }

  &:hover {
    color: rgba(255, 255, 255, 0.4);
  }

  &.selected {
    color: rgba(255, 255, 255, 1);
  }

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
`;

// const NavBarLinkLeft = styled(NavBarLink)``;
// const NavBarLinkCenter = styled(NavBarLink)``;
// const NavBarLinkRight = styled(NavBarLink)`
//   justify-content: flex-end;
// `;

const StudentNav = () => {
  return (
    <NavBarWrapper>
      <NavBar>
        <StudentNavItem to="/dashboard" />
        <StudentNavItem to="/saved" />
        <StudentNavItem to="/interestedIn" />
        <StudentNavItem to="/profile" />
      </NavBar>
    </NavBarWrapper>
  );
};

export default StudentNav;
