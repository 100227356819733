import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const EmailTitle = styled.h6`
  font-size: 0.75rem;
  color: #858585;
  margin: 0;
  padding: 0;
  line-height: 0.75rem;
  text-transform: uppercase;
`;

const Email = styled.div`
  margin-top: 0.125rem;
  font-size: 0.875rem;
  font-weight: bold;
  color: #171725;
  overflow-wrap: break-word;
`;

const StudentSidebarEmail = (props) => {
  const { email } = props;

  return (
    <>
      <EmailTitle>Email</EmailTitle>
      <Email>{email}</Email>
    </>
  );
};

export default StudentSidebarEmail;

StudentSidebarEmail.propTypes = {
  email: PropTypes.string.isRequired,
};
