module.exports = {
  // OPTIONS
  OPTIONS_GET_OPPORTUNITY_LOCATIONS: 'OPTIONS_GET_OPPORTUNITY_LOCATIONS',
  OPTIONS_GET_OPPORTUNITY_CATEGORIES: 'OPTIONS_GET_OPPORTUNITY_CATEGORIES',
  OPTIONS_GET_OPPORTUNITY_SUBCATEGORIES:
    'OPTIONS_GET_OPPORTUNITY_SUBCATEGORIES',
  OPTIONS_GET_STATES: 'OPTIONS_GET_STATES',
  OPTIONS_GET_EMPLOYMENT_TYPES: 'OPTIONS_GET_EMPLOYMENT_TYPES',

  //  COUNSELOR
  COUNSELOR_DASHBOARD_GET: 'COUNSELOR_DASHBOARD_GET',
  COUNSELOR_STUDENTS_GET: 'COUNSELOR_STUDENTS_GET',
  COUNSELOR_JOBS_GET: 'COUNSELOR_JOBS_GET',
  COUNSELOR_SET_LOADING: 'COUNSELOR_LOADING_SET',

  //  EMPLOYER
  EMPLOYER_DASHBOARD_GET: 'EMPLOYER_DASHBOARD_GET',
  EMPLOYER_OPPORTUNITIES_GET: 'EMPLOYER_OPPORTUNITIES_GET',

  //  STUDENT
  STUDENT_DASHBOARD_GET: 'STUDENT_DASHBOARD_GET',
  STUDENT_SAVED_GET: 'STUDENT_SAVED_GET',
  STUDENT_INTERESTED_GET: 'STUDENT_INTERESTED_GET',
  STUDENT_PROFILE_GET: 'STUDENT_PROFILE_GET',

  SET_PARENT_LOADING: 'SET_PARENT_LOADING',
  CHILD_ACTIVITY_GET: 'CHILD_ACTIVITY_GET',
  CHILDREN_GET: 'CHILDREN_GET'
};
