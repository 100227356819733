import React from 'react';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { Container } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { API } from '../settings';
import FormPasswordField from './shared/forms/FormPasswordField';

const PasswordUpdateWrapper = styled.div`
  padding-top: 25px;
`;

const FormWrapper = styled.div`
  padding-top: 1.25rem;
`;

const SettingsHeader = styled.div`
  text-align: left;
  width: 100%;
`;

const BackLink = styled(Link)`
  color: #000000;
  display: inline-block;
  font-size: 14px;
  width: 100px;
  &:hover {
    color: #000000;
  }
`;

const PageTitle = styled.h2`
  color: #000000;
  font-size: 25px;
  margin-top: 25px;
`;

const Button = styled.button`
  width: 100%;
  height: 55px;
  border-radius: 12px;
  color: #ffffff;
  background-color: #1a1a1a;
  border: none;
  margin-top: 1.25rem;
  font-weight: bold;
`;

const handlePasswordUpdate = async (user, values) => {
  const { currentPassword, newPassword, confirmNewPassword } = values;

  try {
    // fetch data from a url endpoint
    // const response = await API.get('/some_url_endpoint');

    const response = await API.post(`/students/${user.uuid}/update-password`, {
      currentPassword,
      newPassword,
      confirmNewPassword,
    });

    const responseData = response.data;
    const message = responseData.message;

    if (message) {
      if (message.toLowerCase().includes('password updated')) {
        toast.success('Password updated.');
      }
    }

    return true;
  } catch (error) {
    let errorMessage = 'Something went wrong.';

    console.log(error.response);

    if (error.response && error.response.data && error.response.data.error) {
      errorMessage = error.response.data.error;
    }

    toast.error(errorMessage);

    return false;
  }

  // axios
  //   .post(`/students/${user.uuid}/update-password`, {
  //     currentPassword,
  //     newPassword,
  //     confirmNewPassword,
  //   })
  //   .then((response) => {
  //     let responseData = response.data;
  //     const message = responseData.message;

  //     if (message) {
  //       if (message.toLowerCase().includes('password updated')) {
  //         toast.success('Password updated.');
  //       }
  //     }
  //   })
  //   .catch((error) => {
  //     let errorMessage = 'Something went wrong.';

  //     console.log(error.response);

  //     if (error.response && error.response.data && error.response.data.error) {
  //       errorMessage = error.response.data.error;
  //     }

  //     toast.error(errorMessage);
  //   });
};

const validationSchema = yup.object({
  currentPassword: yup.string().required('Required'),
  newPassword: yup
    .string('Enter your password')
    .min(8, 'Password should be of minimum 8 characters length')
    .required('Password is required'),
  confirmNewPassword: yup.string().when('newPassword', {
    is: (val) => (val && val.length > 0 ? true : false),
    then: yup
      .string()
      .oneOf([yup.ref('newPassword')], 'Both passwords need to be the same'),
  }),
});

const PasswordUpdate = (props) => {
  const { user } = props;

  return (
    <Container fluid>
      <PasswordUpdateWrapper>
        <SettingsHeader>
          <BackLink to="/settings">
            <FontAwesomeIcon icon={faChevronLeft} />
          </BackLink>
        </SettingsHeader>
        <PageTitle>Change password</PageTitle>
        <FormWrapper>
          <Formik
            validationSchema={validationSchema}
            onSubmit={async (
              values,
              { resetForm, setSubmitting, setFieldError }
            ) => {
              setSubmitting(true);

              const passwordUpdated = await handlePasswordUpdate(user, values);

              setSubmitting(false);

              if (passwordUpdated) {
                resetForm();
              }
            }}
            initialValues={{
              currentPassword: '',
              newPassword: '',
              confirmNewPassword: '',
            }}
          >
            {({
              handleSubmit,
              handleChange,
              values,
              errors,
              isValid,
              isSubmitting,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <FormPasswordField
                  controlId="validationFormik01"
                  label="Current Password"
                  type="password"
                  name="currentPassword"
                />
                <FormPasswordField
                  controlId="validationFormik02"
                  label="New Password"
                  type="password"
                  name="newPassword"
                />
                <FormPasswordField
                  controlId="validationFormik03"
                  label="Confirm New Password"
                  type="password"
                  name="confirmNewPassword"
                />
                <Button
                  disabled={!isValid}
                  variant="success"
                  as="input"
                  size="lg"
                  type="submit"
                  value="Submit"
                />
              </Form>
            )}
          </Formik>
        </FormWrapper>
      </PasswordUpdateWrapper>
    </Container>
  );
};

const mapStateToProps = (state) => ({ user: state.user });
export default connect(mapStateToProps)(PasswordUpdate);
