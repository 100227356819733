import React from 'react';
import { Spinner } from 'react-bootstrap';
import styled from 'styled-components';

const Wrapper = styled.div`
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export default function Loading() {

    return <Wrapper>
        <Spinner animation="border" size={50} />
    </Wrapper>

};