import React from 'react';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import styled from 'styled-components';
import FormTextInput from './shared/forms/FormTextInput';

const FormWrapper = styled.div`
  padding-top: 1.25rem;
  width: 100%;
`;

const Button = styled.button`
  width: 100%;
  height: 55px;
  border-radius: 12px;
  color: #ffffff;
  background-color: #1a1a1a;
  border: none;
  margin-top: 1.25rem;
  font-weight: bold;
`;

const validationSchema = yup.object({
  firstName: yup.string().required('Required'),
  lastName: yup.string().required('Required'),
});

const StudentInfoUpdateForm = (props) => {
  const { handleInfoUpdate, user } = props;
  const { firstName, lastName } = user;

  return (
    <FormWrapper>
      <Formik
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          handleInfoUpdate(values);
        }}
        initialValues={{
          firstName,
          lastName,
        }}
      >
        {({ handleSubmit, isValid, isSubmitting }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <FormTextInput
              controlId="validationFormik01"
              label="First Name"
              type="text"
              name="firstName"
            />
            <FormTextInput
              controlId="validationFormik02"
              label="Last Name"
              type="text"
              name="lastName"
            />
            <Button
              disabled={!isValid || isSubmitting}
              variant="success"
              as="input"
              size="lg"
              type="submit"
              value="Submit"
            />
          </Form>
        )}
      </Formik>
    </FormWrapper>
  );
};

export default StudentInfoUpdateForm;
