import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { API } from '../../../settings';

function Schools(props) {
  const [schools, setSchools] = useState([]);
  useEffect(() => {
    API.get(`/schools/all`).then((res) => setSchools(res.data.schools));
  }, []);
  console.log(props);
  return (
    <Form.Control {...props} as="select">
      <option disabled value={-1}>
        Select school
      </option>
      <option value={'none'}>None</option>
      {schools.map((state, i) => (
        <option value={state.id}>{state.name}</option>
      ))}
    </Form.Control>
  );
}

export default Schools;
