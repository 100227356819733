import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Avatar from 'react-avatar';
import RatingLn from '../common/RatingLn';

const ProfileHeader = styled.div`
  text-align: left;
  position: relative;
  padding: 20px 11px;
  display: flex;
  align-items: center;
`;

const ProfileAvatar = styled(Avatar)`
  font-family: Nunito;
  margin-right: 20px;
`;

const ProfileName = styled.h4`
  margin-top: 10px;
  margin-bottom: 5px;
`;

const ProfileEmail = styled.span`
  margin-top: 5px;
  width: 100%;
  display: block;
  color: #9d9d9d;
`;

const SettingsProfileHeader = (props) => {
  const { user } = props;

  return (
    <ProfileHeader>
      <ProfileAvatar
        className="profileAvatar"
        round={true}
        size={100}
        name={`${user.firstName} ${user.lastName}`}
        color="#797878"
      />
      <div>
        <ProfileName>
          {user.firstName} {user.lastName}
        </ProfileName>
        <ProfileEmail>{user.email}</ProfileEmail>
        {/* <RatingLn /> */}
      </div>
    </ProfileHeader>
  );
};

const mapStateToProps = (state) => ({ user: state.user });
export default connect(mapStateToProps)(SettingsProfileHeader);
